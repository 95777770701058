
const CurrenyFormat = ({ Currency = null }) => {

    return (
        <>
            {new Intl.NumberFormat("en-US", {
                // maximumFractionDigits: 2,
                // minimumFractionDigits: 0,
            }).format(Currency)}

        </>
    );
};

export default CurrenyFormat;