import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentAPIs } from "../../../service/api-service";
import { IStoreValue } from "utils/types";
import {
    setalertMessagetype,
    setalertMessage,
    setLoginModalOpen,
    setLogInLoading,
} from "redux/redux-slice";
import {
    Box,
    CircularProgress,
    Link,
    Typography,
    Backdrop,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/images/buildacrew/Icon_Subs_Indent-Arrow.png";

const Dashboard = ({ setActiveStep, reloadTabUpdate }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AuthToken = useSelector(
        (state: IStoreValue) => state.equipmentReducer.accessToken
    );
    const AuthUserId = useSelector(
        (state: IStoreValue) => state.equipmentReducer.Authuser
    );
    const [loading, setLoading] = useState(false);
    const [dashboardData, setDashboardData] = useState([]);

    const handleListEquipment = (event) => {
        event.stopPropagation();
        window.location.href = "/listequipment";
    };

    // const handleGps = () => {
    //     navigate("/myGps");
    //     setActiveStep(4);
    //     reloadTabUpdate();
    // }

    // const handleRentalYard = () => {
    //     navigate("/myRentalYard");
    //     setActiveStep(1);
    //     reloadTabUpdate();
    // }

    // const handleSalesYard = () => {
    //     navigate("/mySalesYard");
    //     setActiveStep(6);
    //     reloadTabUpdate();
    // }
    // const handleCrew = () => {
    //     navigate("/buildACrew");
    //     setActiveStep(7);
    //     reloadTabUpdate();
    // }

    const handleNavigation = (activeUrl: string, currentSteps: number) => {
        if (!AuthUserId) {
            dispatch(setLoginModalOpen(true));
            dispatch(setLogInLoading(false));
            return;
        }
        navigate(activeUrl);
        setActiveStep(currentSteps);
        reloadTabUpdate();
    };

    useEffect(() => {
        const fetchData = async (userId: number) => {
            try {
                setLoading(true);
                const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
                const response = await EquipmentAPIs.getDashboardDetails(
                    { user_id: userId.toString() },
                    headers
                );
                if (response["type"] === "RXSUCCESS") {
                    setDashboardData([response.data]);
                } else {
                    throw new Error(response["message"]);
                }
            } catch (error) {
                dispatch(setalertMessage(error["message"]));
                dispatch(setalertMessagetype("error"));
            } finally {
                setLoading(false);
            }
        };
        if (AuthUserId) {
            fetchData(AuthUserId);
        }
    }, [AuthUserId, AuthToken]);

    return (
        <>
            {loading && (
                <div>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        {/* <CircularProgress color="inherit" /> */}
                        <Typography variant="h5">Loading Your Dashboard...</Typography>
                    </Backdrop>
                </div>
            )}
            {dashboardData.length > 0 && (
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "1fr",
                            sm: "1fr",
                            md: "repeat(2, 1fr)",
                            lg: "repeat(2, 1fr)",
                            xl: "repeat(2, 1fr)",
                        },
                        gap: "1rem",
                    }}
                >
                    <Box
                        sx={{
                            background: "#47463E",
                            cursor: "pointer",
                            padding: "1rem",
                            borderRadius: "10px",
                            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.5)'
                        }}
                        onClick={() => handleNavigation("/myRentalYard", 1)}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: "#faa61a !important",
                                fontFamily: "GothamBold !important",
                                mb: 1,
                            }}
                        >
                            My Rental Yard
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <Box
                                sx={{
                                    width: "60%",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "0.2rem",
                                    flexDirection: "column",
                                }}
                            >
                                {dashboardData[0]?.["rentalYard"]?.map((element) => (
                                    <>
                                        {element.label.includes("ending") ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1rem",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#ff2a11 !important",
                                                        fontWeight: '600',
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.label}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "#ff2a11 !important",
                                                        fontWeight: "800",
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.value}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1rem",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#fff !important",
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.label}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "#fff !important",
                                                        fontWeight: "800",
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.value}
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    width: "40%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    onClick={handleListEquipment}
                                    sx={{
                                        background: "#179346",
                                        fontFamily: "Montserrat !important",
                                        padding: "2.5px 10px !important",
                                        borderRadius: "5px",
                                        fontWeight: "800",
                                        color: "#fff",
                                        fontSize: {
                                            xs: "12px",
                                            sm: "14px",
                                            md: "14px",
                                            lg: "16px",
                                            xl: "16px",
                                        },
                                        "&:hover": {
                                            background: "#077731",
                                        },
                                    }}
                                >
                                    List Equipment
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        onClick={() => handleNavigation("/myGps", 4)}
                        sx={{
                            background: "#47463E",
                            cursor: "pointer",
                            padding: "1rem",
                            borderRadius: "10px",
                            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.5)'
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: "#faa61a !important",
                                fontFamily: "GothamBold !important",
                                mb: 1,
                            }}
                        >
                            GPS
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <Box
                                sx={{
                                    width: "60%",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    gap: "0.2rem",
                                    flexDirection: "column",
                                }}
                            >
                                {dashboardData[0]?.["gpsData"]?.map((element) => (
                                    <>
                                        {element.label.includes("signed") ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1rem",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        paddingLeft: '5px'
                                                    }}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={arrowIcon}
                                                        alt="arrow-icon"
                                                        sx={{
                                                            width: {
                                                                xs: '11px',
                                                                sm: '12px',
                                                                md: '14px',
                                                                lg: '14px',
                                                                xl: '14px'
                                                            }, height: "auto"
                                                        }}
                                                    ></Box>
                                                    <Typography
                                                        sx={{
                                                            color: "#fff !important",
                                                            fontFamily: "Montserrat !important",
                                                            fontSize: {
                                                                xs: "13px",
                                                                sm: "13px",
                                                                md: "14px",
                                                                lg: "16px",
                                                                xl: "16px",
                                                            },
                                                        }}
                                                    >
                                                        {element.label}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        color: "#fff !important",
                                                        fontWeight: "800",
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.value}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "1rem",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#fff !important",
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.label}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "#fff !important",
                                                        fontWeight: "800",
                                                        fontFamily: "Montserrat !important",
                                                        fontSize: {
                                                            xs: "13px",
                                                            sm: "13px",
                                                            md: "14px",
                                                            lg: "16px",
                                                            xl: "16px",
                                                        },
                                                    }}
                                                >
                                                    {element.value}
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                ))}
                            </Box>
                            <Box
                                sx={{
                                    width: "40%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    sx={{
                                        background: "#179346",
                                        fontFamily: "Montserrat !important",
                                        padding: "2.5px 10px !important",
                                        borderRadius: "5px",
                                        fontWeight: "800",
                                        color: "#fff",
                                        fontSize: {
                                            xs: "12px",
                                            sm: "14px",
                                            md: "14px",
                                            lg: "16px",
                                            xl: "16px",
                                        },
                                        "&:hover": {
                                            background: "#077731",
                                        },
                                    }}
                                >
                                    Order GPS
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        onClick={() => handleNavigation("/buildACrew", 7)}
                        sx={{
                            background: "#47463E",
                            cursor: "pointer",
                            padding: "1rem",
                            borderRadius: "10px",
                            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.5)'
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: "#faa61a !important",
                                fontFamily: "GothamBold !important",
                                mb: 1,
                            }}
                        >
                            Build a Crew
                        </Typography>
                        <Box
                            sx={{
                                width: "60%",
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "0.2rem",
                                flexDirection: "column",
                            }}
                        >
                            {dashboardData[0]?.["buildCrewData"]?.map((element) => (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "1rem",
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: "#fff !important",
                                            fontFamily: "Montserrat !important",
                                            fontSize: {
                                                xs: "13px",
                                                sm: "13px",
                                                md: "14px",
                                                lg: "16px",
                                                xl: "16px",
                                            },
                                        }}
                                    >
                                        {element.label}
                                    </Typography>
                                    {element.value === '' ?
                                        (<Typography
                                            sx={{
                                                color: "#fff !important",
                                                fontWeight: "800",
                                                fontFamily: "Montserrat !important",
                                                fontSize: {
                                                    xs: "13px",
                                                    sm: "13px",
                                                    md: "14px",
                                                    lg: "16px",
                                                    xl: "16px",
                                                },
                                            }}
                                        >
                                            {"N/A"}
                                        </Typography>) : (
                                            <Typography
                                                sx={{
                                                    color: "#fff !important",
                                                    fontWeight: "800",
                                                    fontFamily: "Montserrat !important",
                                                    fontSize: {
                                                        xs: "13px",
                                                        sm: "13px",
                                                        md: "14px",
                                                        lg: "16px",
                                                        xl: "16px",
                                                    },
                                                }}
                                            >
                                                {element.value}
                                            </Typography>
                                        )}
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "column",
                                lg: "column",
                                xl: "column",
                                gap: "1rem",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                cursor: "pointer",
                                height: "unset !important",
                                padding: "unset !important",
                                background: "none !important",
                                color: "#faa61a !important",
                                textDecoration: "underline",
                                textDecorationColor: "#faa61a",
                                fontFamily: 'GothamBold !important'
                            }}
                            onClick={() => handleNavigation("/mySalesYard", 6)}
                        >
                            My Sales Yard
                        </Box>
                        <Box
                            sx={{
                                color: "#faa61a !important",
                                textDecoration: "underline",
                                textDecorationColor: "#faa61a",
                                fontFamily: 'GothamBold !important',
                                cursor: 'pointer'
                            }}
                            // href="/myServiceDirectory"
                            onClick={() => handleNavigation("/myServiceDirectory", 7)}
                        >
                            My Services
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Dashboard;
