import React, { useCallback, useEffect, useState, useRef } from 'react'
import { EquipmentAPIs } from "../../../service/api-service";
import { Box, CircularProgress, Link, Typography, Backdrop, Button, Modal, IconButton, TextField, Checkbox, FormGroup, styled, FormControl } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { setalertMessagetype, setalertMessage, setSessionExpire, setLoginModalOpen, setLogInLoading } from 'redux/redux-slice';
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import gemArrow from 'assets/images/gem-arrow.png'
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';
import ChatWindow from '../../ChatWindow/ChatWindow'
import OrderDetail from '../../OrderDetail/VieworderDetail'
import './../newstyles.scss'
import placeholderImg from 'assets/images/placeholder.png'

import Orderdetailimg from 'assets/images/details-page/booking2.png'
import UserInfo from './../../UserInfo/Userinfo'
import Img from './../../../assets/images/dummy-profile-pic.jpeg';

import ExtendPop from '../../../pages/equipment-detail/ExtendRental';
import CurrentFormat from "../../common/CurrenyFormat";
import { useParams } from 'react-router-dom';

import momentTimeZone from "moment-timezone";

import { convertTimeZoneShortName } from 'utils/commonFxns';

let reason = ''
const NewCssTextField = styled(TextField)({

  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important"
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});
const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const loginstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
}
let c_time = new Date();
let pickup_time = []
const pickup_imgs = []

const Irented = ({ ChatClick }) => {
  const dispatch = useDispatch()
  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
  const AuthUserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser)
  const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)
  const NotificationsList = useSelector((state: IStoreValue) => state.equipmentReducer.NotificationList)

  // by GD 18 march 2024 for redirection from email
  const { uId, orderId } = useParams(); // Access route parameters
  const LoginPopup = () => {
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };
  // GD end
  const [loading, setloading] = React.useState(false);
  const [EquipResult, setEquipResult] = React.useState([]);
  const [AllEquips, setAllEquips] = React.useState([]);
  const [paginationPage, setpaginationPage] = React.useState(10);
  const [TotalResult, setTotalResult] = React.useState(0);
  const [Page, setPage] = React.useState(0);
  const [CurrentActiveEquip, setCurrentActiveEquip] = React.useState(null);
  const [ActiveEquip, setActiveEquip] = React.useState(null);
  const [RenterLocation, setRenterLocation] = React.useState({
    lat: null,
    lng: null
  });
  const [OwnerInfo, setOwnerInfo] = React.useState(false);
  const [ChatRenterToOwner, setChatRenterToOwner] = React.useState(false);
  const [ChatData, setChatData] = React.useState({});

  // PickUp Dropoff section start
  const [PickupDropoffpopup, setPickupDropoffpopup] = React.useState(false);
  const [FrontImage, setFrontImage] = useState(null);
  const [BackImage, setBackImage] = useState(null);
  const [LeftImage, setLeftImage] = useState(null);
  const [RightImage, setRightImage] = useState(null);

  const [FrontImageUploadPath, setFrontImageUploadPath] = useState(null);
  const [BackImageUploadPath, setBackImageUploadPath] = useState(null);
  const [LeftImageUploadPath, setLeftImageUploadPath] = useState(null);
  const [RightImageUploadPath, setRightImageUploadPath] = useState(null);

  const [file, setFile] = useState([]);
  const [HourMilesImages, setHourMilesImages] = useState([]);

  const [Description, setDescription] = useState('');
  const [AdditionalInfo, setAdditionalInfo] = useState('');
  const [NotAvailable, setNotAvailable] = useState(false);
  const [Hours, setHours] = useState(false);
  const [Miles, setMiles] = useState(false);
  const [CurrentReading, setCurrentReading] = useState(0);

  const [PickDropType, setPickDropType] = useState(null);
  const [MoreImgs, setMoreImgs] = useState([]);
  const [HourMilesImgs, setHourMilesImgs] = useState([]);
  const [more_file_res, setmore_file_res] = useState([]);
  const [hour_miles_res, sethour_miles_res] = useState([]);
  const [FinalPopup, setFinalPopup] = useState(false);

  const [OrderDetailpopup, setOrderDetailpopup] = React.useState(false);
  const [OrderDetailPopData, setOrderDetailPopData] = useState({
    "thumbnail": "",
    "id": "",
    "make": "",
    "model": "",
    "start_date": "",
    "start_time": "",
    "end_date": "",
    "end_time": "",
    "profile_pic": "",
    "first_name": "",
    "order_status": "",
    "order_from": "",
    "rental_price": 0,
    "minimum_delivery_fees": 0,
    "security_deposit_refund_txt": "",
    "security_deposit": 0,
    "grand_total_txt": "",
    "grand_total": 0,
    "protection_fee": 0,
    "transport_fee": 0,
    "admin_fee": 0,
    "side": "renter",
    "timeZone": "UTC"
  });

  const [NewMsg, setNewMsg] = React.useState(null);
  const today = moment().format("YYYY-MM-DD");

  // Extend Rental section start
  const [ExtendRentalPopup, setExtendRentalPopup] = React.useState(false);
  const [ExtendOrderObj, setExtendOrderObj] = React.useState(null);
  const [ExtendRentalBtnStatus, setExtendRentalBtnStatus] = React.useState(false);

  // Extend Rental section end

  const HandleNotAvailable = (event) => {
    setNotAvailable(event.target.checked)
  }
  const HandleHourCheck = (event) => {
    setHours(event.target.checked)
    if (event.target.checked) {
      setMiles(false)
    }
  }

  const HandleMilesCheck = (event) => {
    setMiles(event.target.checked)
    if (event.target.checked) {
      setHours(false)
    }
  }

  const HandleReading = (event) => {
    let val = event.target.value;
    val = val.replace(/\D/g, "");
    setCurrentReading(val);
  }

  // Get All Rented Equip
  const RentedEquip = async (user_id, new_page) => {
    try {

      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.GetRentedEquip(
        {
          user_id: user_id.toString(),
          page: new_page
        },
        headers
      );

      if (EquipData['type'] == "RXSUCCESS") {

        setPage(new_page)
        setTotalResult(EquipData['total'])
        /******************** */
        let _LastArray = [...EquipData['new_data']];
        var active_arr = [];
        var other_arr = [];
        var canceled_arr = [];
        var complated_arr = [];
        _LastArray.map((item, index) => {
          item.order_status != null &&
            item.order_status == "payment_complete"
            ? active_arr.push(item)
            : item.order_status != null &&
              item.order_status == "order_canceled"
              || item.order_status == "order_cancelled"
              ? canceled_arr.push(item)
              : item.order_status != null &&
                item.order_status == "completed"
                ? complated_arr.push(item)
                : other_arr.push(item);
        });

        active_arr = active_arr.sort((item, item2) => {
          return (
            moment(item2.start_date + ' ' + item2.start_time, "YYYY-MM-DD hh:mm:ss").valueOf() -
            moment(item.start_date + ' ' + item.start_time, "YYYY-MM-DD hh:mm:ss").valueOf()
          );
        });

        other_arr = other_arr.sort((item, item2) => {
          return (
            moment(item.start_date, "YYYY-MM-DD").valueOf() -
            moment(item2.start_date, "YYYY-MM-DD").valueOf()
          );
        });

        complated_arr = complated_arr.sort((item, item2) => {
          return (
            moment(item2.start_date, "YYYY-MM-DD").valueOf() -
            moment(item.start_date, "YYYY-MM-DD").valueOf()
          );
        });

        let final_arr = [...active_arr, ...other_arr, ...complated_arr, ...canceled_arr];

        setAllEquips(final_arr)
        if (new_page == 0) {
          setEquipResult(final_arr.slice(0, paginationPage))

          let OrderPlacedId = localStorage.getItem("OrderPlacedId");
          //console.log('OrderPlacedId', OrderPlacedId);
          if (OrderPlacedId != null && OrderPlacedId) {
            //document.getElementById("active-" + OrderPlacedId).click();

            final_arr.map((items) => {
              if (items.order_id == OrderPlacedId) {
                setCurrentActiveEquip(OrderPlacedId)
                setActiveEquip(items)
              }
            })
            localStorage.removeItem("OrderPlacedId");

            // console.log('comming on if cndition');
          } else {
            // by GD 18 march 2024 for redirection from email
            if (uId && orderId && (uId == user_id)) {
              console.log("in gd condition of if");
              
              final_arr.map((item) => {
                if (item.order_id == orderId) {
                  console.log("condition matched");
                  setCurrentActiveEquip(item.order_id);
                  setActiveEquip(item);
                }
              })
            }else{
              setCurrentActiveEquip(final_arr[0]['order_id']);
              setActiveEquip(final_arr[0]);
              // For Staging
              checkEquipAvailability(final_arr[0], final_arr[0]['id']);
            }
            // GD end and next 4 line commented
            // setCurrentActiveEquip(final_arr[0]['order_id'])
            // setActiveEquip(final_arr[0])
            // // For Staging
            // checkEquipAvailability(final_arr[0], final_arr[0]['id'])
            //console.log('comming on else cndition');
          }
        } else {
          let old_EquipData = EquipResult;
          let new_EquipData = old_EquipData.concat(final_arr);

          setEquipResult(new_EquipData)
        }
        setloading(false);
      }
      else if (EquipData["status"] == false && EquipData["slug"] == "Session Expired") {
        dispatch(setSessionExpire(true));
        setloading(false);
        return false
      }
      else {
        dispatch(setalertMessage(EquipData['message']));
        dispatch(setalertMessagetype('error'));
        setloading(false);
        return false
      }

    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  // Handle pagination click
  const HandlePagination = () => {
    setloading(true);
    let new_page = Number(Page) + 1;
    //RentedEquip(AuthUserID, new_page)

    // New code    
    let total = TotalResult;
    let pagerlen = Number(EquipResult.length);
    let new_len = Number(pagerlen) + Number(paginationPage);
    setPage(new_len)
    if (new_len > total) {
      setEquipResult(AllEquips.slice(0, total))
    } else {
      setEquipResult(AllEquips.slice(0, new_len))
    }
    setloading(false);
  }

  // Handle project location
  const HandleProjectLocation = (obj) => {

    if (obj.address == '') {
      dispatch(setalertMessage('Equipment to be Picked Up.'));
      dispatch(setalertMessagetype('info'));
      return false;
    } else {
      let lat = RenterLocation['lat'];
      let lng = RenterLocation['lng'];
      let _lat = obj['lat'];
      let _lng = obj['lng'];

      let apple_map = 'http://maps.apple.com/?saddr=' + lat + "," + lng + '&daddr=' + _lat + "," + _lng;
      let google_map = 'https://www.google.com/maps/dir/?api=1&origin=' + lat + "," + lng + '&destination=' + _lat + ',' + _lng;

      window.open(
        google_map,
        '_blank'
      );
    }
  }

  // Handle Chat window
  const HandleChatOwnerToRenter = () => {
    let senderID = AuthUserID;
    let EquipMake = ActiveEquip["make"] ? ActiveEquip["make"] : "Not Available"
    //let EquipModel = ActiveEquip["model"] ? ActiveEquip["model"] : "Not Available"

    let ChatObj = {
      'equipId': ActiveEquip["id"],
      'senderID': senderID,
      'reciverID': ActiveEquip["user_id"],
      'reciverName': ActiveEquip["first_name"] + ' ' + (ActiveEquip["last_name"] ?? ""),
      'EquipMake': EquipMake,
      // 'EquipModel': EquipModel
    };
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    // Redirect the user to the notifications page for chat interaction
    window.location.href = "/notifications";
    setChatData(ChatObj)

    ChatClick(ChatObj)
    //setChatRenterToOwner(true)
  }

  // Upload File
  const SingleFileUpload = async (fileObj, from) => {
    const formData = new FormData();
    formData.append('image', fileObj);
    formData.append('user_id', AuthUserID.toString());
    const headers = {
      "Authorization": `${AuthToken}`,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      request_from: "Web"
    };
    const upload_file = await EquipmentAPIs.UploadImage(
      formData,
      headers
    );

    if (upload_file['type'] == "RXSUCCESS") {
      var uploaded_path = upload_file['data']['imageUrl'];
      var fileLoaded = URL.createObjectURL(fileObj);

      // pickup_imgs.push(uploaded_path);
      if (from == 'Front') {
        pickup_time.push(c_time)
        setFrontImage(fileLoaded)
        setFrontImageUploadPath(uploaded_path);
      } else if (from == 'Back') {
        pickup_time.push(c_time)
        setBackImage(fileLoaded)
        setBackImageUploadPath(uploaded_path);
      } else if (from == 'Right') {
        pickup_time.push(c_time)
        setRightImage(fileLoaded)
        setLeftImageUploadPath(uploaded_path);
      } else if (from == 'Left') {
        pickup_time.push(c_time)
        setLeftImage(fileLoaded)
        setRightImageUploadPath(uploaded_path);
      }

      setloading(false);
    }
    else if (upload_file["status"] == false && upload_file["slug"] == "Session Expired") {
      setloading(false)
      dispatch(setSessionExpire(true));
      return false
    }
    else {
      dispatch(setalertMessage(upload_file["message"]));
      dispatch(setalertMessagetype("error"));
      return false;
    }
  }

  // Handle files
  const HandleFrontImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    setloading(true)

    if (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png') {
      // var fileLoaded = URL.createObjectURL(e.target.files[0]);
      // setFrontImage(fileLoaded)
      setloading(false);
      SingleFileUpload(e.target.files[0], "Front");
    } else {
      dispatch(setalertMessage('Invalid selected file type.'));
      dispatch(setalertMessagetype('error'));
      setloading(false);
      return false;
    }
  };

  const HandleBackImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    setloading(true)
    if (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png') {
      // var fileLoaded = URL.createObjectURL(e.target.files[0]);
      // setBackImage(fileLoaded)
      setloading(false);
      SingleFileUpload(e.target.files[0], "Back");
    } else {
      dispatch(setalertMessage('Invalid selected file type.'));
      dispatch(setalertMessagetype('error'));
      setloading(false);
      return false;
    }
  };

  const HandleLeftImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    setloading(true)
    if (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png') {
      // var fileLoaded = URL.createObjectURL(e.target.files[0]);
      // setLeftImage(fileLoaded)
      setloading(false);
      SingleFileUpload(e.target.files[0], "Left");
    } else {
      dispatch(setalertMessage('Invalid selected file type.'));
      dispatch(setalertMessagetype('error'));
      setloading(false);
      return false;
    }
  };

  const HandleRightImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    setloading(true)
    if (e.target.files[0].type == 'image/jpg' || e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png') {
      // var fileLoaded = URL.createObjectURL(e.target.files[0]);
      // setRightImage(fileLoaded)
      setloading(false);
      SingleFileUpload(e.target.files[0], "Right");
    } else {
      dispatch(setalertMessage('Invalid selected file type.'));
      dispatch(setalertMessagetype('error'));
      setloading(false);
      return false;
    }
  };

  // Handle More images
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    var result = Object.entries(fileList);
    if (result.length > 8) {

      dispatch(setalertMessage('you can upload only 8 images.'));
      dispatch(setalertMessagetype('error'));
      return false
    } else {
      setMoreImgs(result);

      let upload_files = [];
      let oldimgs = file;

      if ((oldimgs.length + result.length) > 8) {
        dispatch(setalertMessage('you can upload only ' + (8 - oldimgs.length) + ' images.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      if (oldimgs.length > 0) {
        oldimgs.map((item) => {
          upload_files.push(item)
        })
      }

      for (let index = 0; index < result.length; index++) {
        if (e.target.files[index].type == 'image/jpg' || e.target.files[index].type == 'image/jpeg' || e.target.files[index].type == 'image/png') {
          var fileLoaded = URL.createObjectURL(e.target.files[index]);
          upload_files.push(fileLoaded);
        }
      }
      setFile(upload_files);

    }
  };

  // Remove Images
  const removeImage = (fileName, i) => {
    var myArray = [...file];
    const index = myArray.indexOf(fileName);
    if (index > -1) {
      myArray.splice(index, 1);
    }
    setFile(myArray)
    setMoreImgs(myArray)
  }
  // Handle hours/miles images
  const HandleHourMileImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const upload_filelist = e.target.files;
    var results = Object.entries(upload_filelist);
    if (results.length > 8) {

      dispatch(setalertMessage('you can upload only 8 images.'));
      dispatch(setalertMessagetype('error'));
      return false
    } else {
      setHourMilesImgs(results)
      let hour_upload_files = [];
      let oldimgs = HourMilesImages;

      if ((oldimgs.length + results.length) > 8) {

        dispatch(setalertMessage('you can upload only ' + (8 - oldimgs.length) + ' images.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      if (oldimgs.length > 0) {
        oldimgs.map((item) => {
          hour_upload_files.push(item)
        })
      }

      for (let index = 0; index < results.length; index++) {
        if (e.target.files[index].type == 'image/jpg' || e.target.files[index].type == 'image/jpeg' || e.target.files[index].type == 'image/png') {
          var fileLoaded = URL.createObjectURL(e.target.files[index]);
          hour_upload_files.push(fileLoaded);
        }
      }
      setHourMilesImages(hour_upload_files);

    }
  };
  // Remove Hour/Mile Images
  const RemoveHourMileImage = (fileName, i) => {
    var myArray = [...HourMilesImages];
    const indexs = myArray.indexOf(fileName);
    if (indexs > -1) {
      myArray.splice(indexs, 1);
    }
    setHourMilesImages(myArray)
    setHourMilesImgs(myArray)

  }

  // Handle Multiple files upload
  const UploadmMultiplefiles = async (img_arr, type) => {
    if (img_arr.length > 0) {

      let upload_files_path = [];

      let promises = img_arr.map(async (imgObg, index) => {
        let file_obj = imgObg[1];
        if (file_obj) {
          try {

            const formData = new FormData();
            formData.append('image', file_obj);
            formData.append('user_id', AuthUserID.toString());
            let access_token = AuthToken;
            const headers = {
              "Authorization": `${access_token}`,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              request_from: "Web"
            };
            const upload_Equip_file = await EquipmentAPIs.UploadImage(
              formData,
              headers
            );
            if (upload_Equip_file['type'] == "RXSUCCESS") {
              var uploaded_path = upload_Equip_file['data']['imageUrl'];
              upload_files_path.push(uploaded_path);
              pickup_imgs.push(uploaded_path);
              return;
            } else if (upload_Equip_file["status"] == false && upload_Equip_file["slug"] == "Session Expired") {
              dispatch(setalertMessage(upload_Equip_file['slug']));
              dispatch(setalertMessagetype('error'));

              return false
            } else {
              dispatch(setalertMessage(upload_Equip_file['message']));
              dispatch(setalertMessagetype('error'));
              return false
            }
          } catch (err) {
            dispatch(setalertMessage(err));
            dispatch(setalertMessagetype('error'));

            return false
          }
        }
      });
      Promise.all(promises).then(() => {
        if (type == 'More') {
          setmore_file_res(upload_files_path)
        } else {
          sethour_miles_res(upload_files_path)
        }
      });
    }
  }

  // Submit 
  const Submit = async () => {
    setloading(true)
    try {

      if (FrontImageUploadPath == null || BackImageUploadPath == null || LeftImageUploadPath == null || RightImageUploadPath == null) {
        let msg = 'images';
        if (FrontImageUploadPath == null) {
          msg = 'front side'
        } else if (BackImageUploadPath == null) {
          msg = 'back side'
        } else if (LeftImageUploadPath == null) {
          msg = 'left side'
        } else if (RightImageUploadPath == null) {
          msg = 'right side'
        }

        dispatch(setalertMessage('Please add ' + msg + ' image to continue.'));
        dispatch(setalertMessagetype('error'));
        setloading(false)
        return false;
      } else if (!Hours && !Miles && !NotAvailable) {
        dispatch(setalertMessage('Please select miles/hours'));
        dispatch(setalertMessagetype('error'));
        setloading(false)
        return false;
      }


      //Set pickup images
      let more_img = MoreImgs.length;
      let hour_miles_img = HourMilesImgs.length;

      /************/
      if (more_img > 0 || hour_miles_img > 0) {
        UploadmMultiplefiles(MoreImgs, 'More').then(
          (onResolved) => {

            if (hour_miles_img > 0) {

              UploadmMultiplefiles(HourMilesImgs, 'Hour').then(
                (onResolved) => {
                  setPickupDropoffpopup(false)
                  setFinalPopup(true)
                  setloading(false)
                },
                (onRejected) => {
                  setloading(false)
                  // Some task on failure
                }
              )
            } else {
              setPickupDropoffpopup(false)
              setFinalPopup(true)
              setloading(false)
            }
          },
          (onRejected) => {
            setloading(false)
            // Some task on failure
          }
        )

      } else {
        setPickupDropoffpopup(false)
        setFinalPopup(true)
        setloading(false)
      }
      /************/
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  // Final Submit 
  const FinalSubmit = async () => {
    setloading(true)
    try {

      const headers = {
        "Authorization": `${AuthToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        request_from: "Web"
      };

      /********************* */
      let Front = FrontImageUploadPath;
      let Back = BackImageUploadPath;
      let Right = LeftImageUploadPath;
      let Left = RightImageUploadPath;
      let _imgs = [Front, Back, Right, Left];
      let final_imgs = _imgs.concat(more_file_res);
      /********************* */

      //let final_imgs = pickup_imgs.concat(more_file_res);
      let field_description = Description;
      let field_additional_info = AdditionalInfo;
      let body = {
        //       body.append("status", this.state.status);   
        user_id: AuthUserID.toString(),
        order_id: ActiveEquip['order_id'].toString(),
        owner_id: ActiveEquip['user_id'].toString(),
        images: final_imgs.toString(),
        description: field_description,
        hrs_miles_available: NotAvailable ? "1" : "0",
        hours: Hours ? "1" : "0",
        miles: Miles ? "1" : "0",
        hour_miles_images: hour_miles_res.toString(),
        reading: CurrentReading,
        renter_additional_info: field_additional_info,
        //pickup_timestamp: new Date(),
      }
      // if (this.state.title == "Return") {
      //   body.append("return_timestamp", this.state._timeStemp + "");
      // }   
      // pickup_time.push(c_time)
      let Result = '';
      if (PickDropType == "Pickup") {
        body['pickup_timestamp'] = pickup_time.toString();
        Result = await EquipmentAPIs.PickUp(
          body,
          headers
        );
      } else {
        body['return_timestamp'] = pickup_time.toString();
        Result = await EquipmentAPIs.DropOff(
          body,
          headers
        );
      }

      if (Result['type'] == "RXSUCCESS") {
        dispatch(setalertMessage(Result['message']));
        dispatch(setalertMessagetype('success'));
        Restval()
        setFinalPopup(false)
        setloading(false)
      } else {
        setloading(false)
        setFinalPopup(false)
        dispatch(setalertMessage(Result['message']));
        dispatch(setalertMessagetype('error'));
      }
    } catch (err) {
      setloading(false)
      console.log(err);
    }
  };

  // All values Reset
  const Restval = () => {
    setFrontImageUploadPath(null);
    setBackImageUploadPath(null);
    setLeftImageUploadPath(null);
    setRightImageUploadPath(null);

    setFrontImage(null);
    setBackImage(null);
    setLeftImage(null);
    setRightImage(null);
    setFile([]);
    setHourMilesImages([]);
    setDescription('');
    setAdditionalInfo('');
    setNotAvailable(false);
    setHours(false);
    setMiles(false);
    setCurrentReading(0);
    setMoreImgs([]);
    setHourMilesImgs([]);
    setmore_file_res([]);
    sethour_miles_res([]);
    setFinalPopup(false);
    pickup_time = [];
  }

  const HandleOrderDetail = async (orderObj) => {
    setloading(true)
    setOrderDetailpopup(true);

    let fname = orderObj['first_name'] ? orderObj['first_name'] : 'NA';
    let lname = orderObj['last_name'] ? orderObj['last_name'] : 'NA';

    let devilry = orderObj['delivery_charges'] * 2;
    let minimun = orderObj['minimum_delivery_fees'];
    let totalMi = devilry + minimun;
    let _changeInTotal = (totalMi / 100) * 10;

    let returnDepo =
      orderObj['returnd_deposit'] != undefined ||
        orderObj['returnd_deposit'] != null
        ? orderObj['returnd_deposit']
        : null;

    //let rent_price = orderObj['price'];
    let rent_price = orderObj['price'] != undefined ||
      orderObj['price'] != null
      ? orderObj['price']
      : 0;

    let security_depo = returnDepo == orderObj['security_deposit']
      ? 0
      : returnDepo == null
        ? orderObj['security_deposit']
        : orderObj['security_deposit'] - returnDepo

    let transport_fees = orderObj['delivery_charges'] * 2 + orderObj['minimum_delivery_fees'];

    let refunded_protection =
      orderObj['refunded_protection'] != undefined ||
        orderObj['refunded_protection'] != ""
        ? orderObj['refunded_protection']
        : "";

    let protection_fees = orderObj['protection_fees_refunded'] == "1"
      ? orderObj['equipment_protection_fees'] -
      (refunded_protection == ""
        ? orderObj['equipment_protection_fees']
        : refunded_protection)
      : orderObj['equipment_protection_fees'];

    let grand_total = orderObj['protection_fees_refunded'] != "1"
      ? orderObj['admin_commission'] != null
        ? orderObj['total_price'] +
        orderObj['equipment_protection_fees'] +
        _changeInTotal +
        orderObj['admin_commission']
        // -
        // (orderObj['order_status'] == "completed"
        //   ? orderObj['security_deposit']
        //   : 0)
        : orderObj['total_price'] +
        orderObj['equipment_protection_fees']
      : orderObj['admin_commission'] != null
        ? orderObj['total_price'] -
        (orderObj['order_status'] == "order_canceled"
          ? orderObj['security_deposit']
          : 0) +
        _changeInTotal +
        orderObj['admin_commission'] +
        (orderObj['protection_fees_refunded'] == "1"
          ? orderObj['equipment_protection_fees'] -
          (refunded_protection == ""
            ? orderObj['equipment_protection_fees']
            : refunded_protection)
          : 0)
        : orderObj['total_price'];

    /*****************/
    //added by pp 0n 25 apr 2024 due to timezone changes
    let timeZone = orderObj["timezone"]; 
    if(!timeZone) {
      const headers = {
        "Authorization": `${AuthToken}`,
        request_from: "Web"
      };
      const timeZoneData = await EquipmentAPIs.getEquipTimeZone({equip_id:orderObj["id"]} ,headers);
      if(timeZoneData.type === "RXSUCCESS") {
        timeZone = timeZoneData.data;
      }
    };
    // end by pp
    setOrderDetailPopData({
      "thumbnail": orderObj['thumbnail'],
      "id": "Order ID #" + orderObj['order_id'],
      "make": orderObj['make'] ? orderObj['make'] : 'NA',
      "model": orderObj['model'] ? orderObj['model'] : '',
      "start_date": orderObj['start_date'],
      "start_time": orderObj['start_time'],
      "end_date": orderObj['end_date'],
      "end_time": orderObj['end_time'],
      "profile_pic": orderObj['profile_pic'] ? orderObj['profile_pic'] : Img,
      "first_name": fname + ' ' + lname,
      "order_status": orderObj['order_status'],
      "order_from": orderObj['delivery_address'] == "" ? "Renter" : "Owner",
      "rental_price": rent_price,
      "minimum_delivery_fees": minimun,
      "security_deposit_refund_txt": orderObj['returnd_deposit'] != undefined ||
        orderObj['returnd_deposit'] != null
        ? "(Refunded" +
        "-$" +
        new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(orderObj['returnd_deposit'])
        +
        ")"
        : "",
      "security_deposit": security_depo,
      "grand_total_txt": orderObj['order_status'] == "order_canceled" ? "(Refunded)" : "",
      "grand_total": grand_total,
      "protection_fee": protection_fees,//orderObj['equipment_protection_fees'],
      "transport_fee": transport_fees,
      "admin_fee": 0,
      "side": "renter",
      "timeZone": timeZone ? convertTimeZoneShortName(momentTimeZone().tz(timeZone).zoneAbbr()) : "UTC" 
    });

    // Check extended orders available or not
    // check if key exists
    const hasKey = 'order_extend' in orderObj;
    setExtendOrderObj([]);
    if (hasKey) {
      let extendedOrder = orderObj['order_extend'].length;
      if (extendedOrder > 0) {
        setExtendOrderObj(orderObj['order_extend']);
      }
    }
    // Extend order section end

    setloading(false)
  }

  // Handle Rent Again link
  const HandleRentAgain = () => {

    var make = ActiveEquip['make'] ? ActiveEquip['make'] : 'NA';
    make = make.replace("/", "'");
    make = make.replace(/ /g, "-");

    var city = ActiveEquip['city'] ? ActiveEquip['city'] : 'NA';
    city = city.replace("/", "'");
    city = city.replace(/ /g, "-");

    var state = ActiveEquip['state'] ? ActiveEquip['state'] : 'NA';
    state = state.replace("/", "'");
    state = state.replace(/ /g, "-");

    var owner_id = ActiveEquip['user_id'];
    var postal_code = ActiveEquip['postal_code'] != 'undefined' ? ActiveEquip['postal_code'] : 0;
    postal_code = postal_code != '' ? postal_code : 0;

    let link = '/detail/' + make + '/' + city + '/' + state + '/' + postal_code + '/' + owner_id + '/' + ActiveEquip['id'];
    link = link.replace("#", "");
    window.location.href = link+'/rent';
  }

  const HandleDescription = (event) => {
    let val = event.target.value
    let len = val.length;

    if (len > 300) {
      let remove_char = len - 300;
      val = val.substring(0, val.length - remove_char);
      setDescription(val);
    } else {
      setDescription(val);
    }
  }

  const HandleAdditionalInfo = (event) => {
    let val = event.target.value
    let len = val.length;

    if (len > 300) {
      let remove_char = len - 300;
      val = val.substring(0, val.length - remove_char);
      setAdditionalInfo(val);
    } else {
      setAdditionalInfo(val);
    }
  }

  // Extend Rental section start
  const HandleRentExtend = () => {
    if (ActiveEquip["order_status"] == "returned") {
      dispatch(setalertMessage('This order is no longer to extend.'));
      dispatch(setalertMessagetype('error'));
      return false;
    } else {
      setExtendRentalPopup(true)
    }
  }

  // Cancel Order

  const [OrderCancelPopup, setOrderCancelPopup] = React.useState(false);
  const [ExtendOrderCancelId, setExtendOrderCancelId] = React.useState(null);

  const CancelMainOrder = async () => {
    try {
      setloading(true);
      // let reason = ExtendOrderCancelIdreason;

      if (reason == '' || reason == null) {
        dispatch(setalertMessage('Please add reason for cancellation.'));
        dispatch(setalertMessagetype('error'));
        setloading(false);
        return false
      }

      let data = {
        order_id: ExtendOrderCancelId.toString(),
        user_id: AuthProfileData['user_id'].toString(),
        reason: reason,
      };

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrder(
        data,
        headers
      );

      if (Result['type'] == "RXSUCCESS") {
        reason = '';
        dispatch(setalertMessage('Order Cancel Successfully.'));
        dispatch(setalertMessagetype('success'));
        setloading(false);
        RentedEquip(AuthProfileData['user_id'], Page)
        setOrderCancelPopup(false);
      }
      else {
        dispatch(setalertMessage(Result['message']));
        dispatch(setalertMessagetype('error'));
        setloading(false);
        return false
      }

    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  // get dates between two dates
  const getDates = (startDate, stopDate) => {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stop_Date = moment(stopDate);
    while (currentDate <= stop_Date) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }

  //Check Equip Availability
  const checkEquipAvailability = async (equipment, equip_id) => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const ResultData = await EquipmentAPIs.GetOrderedDates(
        {
          equip_id: equip_id.toString()
        },
        headers
      );

      if (ResultData['type'] == "RXSUCCESS") {
        let APIResult = ResultData['data']
        var bookedDates = [];
        for (var i = 0; i < APIResult.length; i++) {
          bookedDates = [
            ...bookedDates,
            ...getDates(APIResult[i].start_date, APIResult[i].end_date),
          ];
        }

        // block dates
        let blockDates = equipment.block_dates;

        let blockDates_Arr = blockDates.split(",");
        blockDates_Arr.shift()
        let new_blockdates = [...blockDates_Arr, ...bookedDates];
        let finalBookBlockDates = new_blockdates;
        // setBlockdate(new_blockdates);

        let enddate = equipment.end_date
        let _date = []
        // Set max deate
        new_blockdates.map((itm, ind) => {
          return itm > enddate && _date.push(itm)
        });
        //setMaxDate(_date);


        /*********************** */
        // Check extend orders
        let extended_Orders = equipment.order_extend.length;
        if (extended_Orders > 0) {
          let last_obj = extended_Orders - 1;
          equipment.end_date = equipment.order_extend[last_obj]['end_date'];
          equipment.end_time = equipment.order_extend[last_obj]['end_time'];
        }

        let today = moment().format("YYYY-MM-DD");
        // setExtendRentalBtnStatus(true);

        /************ */ // Check Extend or Rent again button status        
        
        let orderEndDate = equipment.end_date;
        let nextDay = moment(orderEndDate).add(1, "days").format("YYYY-MM-DD");
        let orderStartTime = equipment.end_time;

        let finalBookBlockDatesNext = finalBookBlockDates.filter((item) => {
          return item == today;
        });

        console.log('orderEndDate', orderEndDate);

        if (finalBookBlockDates.length > 0) {

          finalBookBlockDates = finalBookBlockDates.filter((item) => {
            return item == nextDay;
          });

          //console.log("finalBookBlockDates",finalBookBlockDates[0],"today",today,"nextDay",nextDay, "orderStartTime" ,orderStartTime);
          if (finalBookBlockDates[0] == today) {
            console.log('comming....a');
            setExtendRentalBtnStatus(false);
          } else if (finalBookBlockDates[0] == nextDay && orderStartTime == "23:30:00") {            
            setExtendRentalBtnStatus(false);
            //console.log("nextDay", nextDay);
            console.log('comming....b');
          } else if (orderEndDate < today && finalBookBlockDatesNext[0] == today) {
            console.log('comming....c');
            setExtendRentalBtnStatus(false);
            //console.log("nextDay", nextDay);
          } 
          

          else {
            console.log('comming....d');
            setExtendRentalBtnStatus(true);
          }
        } else {
          setExtendRentalBtnStatus(true);
        }


        /*********************** */
        // setAPICallStatus(true);
        setloading(false);
        setActiveEquip(equipment);
        setCurrentActiveEquip(equipment.order_id);
        return false;
      }
      else {
        dispatch(setalertMessage(ResultData['message']));
        dispatch(setalertMessagetype('error'));
        setloading(false);
        return false
      }

    } catch (err) {
      console.log(err);
      setloading(false);
    }
  }

  useEffect(() => {
    if (NotificationsList != null && NotificationsList.length > 0) {
      let msg = [...NotificationsList]
      let msg_Obj = msg[0];
      let newmessage = {
        'senderID': msg_Obj['sender_id'],
        'reciverID': msg_Obj['recever_id'],
        'equipId': msg_Obj['equip_id'],
        'reciverName': ChatData['reciverName'],
        'EquipMake': ChatData['EquipMake'],
      };
      setNewMsg(newmessage)
    }
  }, [NotificationsList]);

  useEffect(() => {
    console.log("uID in useEffe: ", uId, orderId);
    window.scrollTo(0, 0);
    if (AuthProfileData && AuthProfileData != null && (AuthProfileData['user_id'])) {
      // console.log("in if effect: " , AuthProfileData);
      
      setRenterLocation({ lat: AuthProfileData['lat'], lng: AuthProfileData['lng'] })
      RentedEquip(AuthProfileData['user_id'], Page)
    }
  }, [AuthProfileData]);

  //added by pp 0n 25 apr 2024 due to timezone changes
    const [timeZone, setTimeZone] = useState("")
    useEffect(() => {
      async function fetchTimeZone() {
        const headers = {
          "Authorization": `${AuthToken}`,
          request_from: "Web"
        };
        let timeZone = ActiveEquip["timezone"];
        if(!timeZone) {
          const timeZoneData = await EquipmentAPIs.getEquipTimeZone({equip_id:ActiveEquip["id"]} ,headers);
          if(timeZoneData.type === "RXSUCCESS") {
            timeZone = timeZoneData.data;
          }
        }
        setTimeZone(convertTimeZoneShortName(momentTimeZone().tz(timeZone??"UTC").zoneAbbr()))
      }
      if(ActiveEquip) {
        fetchTimeZone()
      }
    },[ActiveEquip,AuthToken])
  // end by pp

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <Box className="rentyrd-heading">
            <Typography>Equipment Rented Loading...</Typography>
          </Box> */}
        </div>
      )}

      <Box className="rentyrd-heading">
        <Typography>Equipment Rented</Typography>
      </Box>

      <Box className="rentyrd-grid">
        <Box className="rentyrd-grid-item rentyrd-left">
          {TotalResult > 0 ? (
            <>
              {EquipResult.map((equipment, indx) => (
                <>
                  <Box
                    className={
                      CurrentActiveEquip == equipment.order_id
                        ? "rentyrd-card rental-box-active"
                        : "rentyrd-card"
                    }
                    id={"active-" + equipment["order_id"]}
                    // For Live
                    // onClick={() => {
                    //   setloading(true);
                    //   setTimeout(() => {
                    //     setActiveEquip(equipment);
                    //     setloading(false);
                    //   }, 500);
                    //   setCurrentActiveEquip(equipment.order_id);
                    // }}

                    // For Staging
                    onClick={() => {
                      console.log("equipment id in clcik: " , equipment.id , " : " , equipment.order_id);
                      
                      checkEquipAvailability(equipment, equipment.id)
                    }}
                  >
                    <Box className="rentyrd-card-grid">
                      <Box className="rentyrd-card-item-left">
                        <Box className="rentyrd-card-img">
                          <img className='eqppmnt-img' src={equipment["thumbnail"]} alt="" />

                          {(equipment.order_status != null &&
                            equipment.order_status == "returned") ||
                            equipment.order_status == "pickup" ||
                            equipment.order_status == "payment_complete" ? (
                            <Typography>ACTIVE</Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                      <Box className="rentyrd-card-item-right">
                        <Box className="rentyrd-right">
                          <Typography
                            className="vehicle-model"
                            title={equipment.make}
                          >
                            {equipment.make ?
                              equipment.make.length > 20
                                ? equipment.make.substring(0, 20) + "..."
                                : equipment.make
                              : "NA"}
                          </Typography>
                          <Typography
                            className="vehicle-type"
                            title={equipment.model}
                          >
                            {equipment.model ?
                              equipment.model.length > 22
                                ? equipment.model.substring(0, 22) + "..."
                                : equipment.model
                              : "NA"}
                          </Typography>
                          <Box className="rentyrd-address">
                            <Typography
                              title={equipment.city ? equipment.city : ""}
                            >
                              {/* {equipment.city ? equipment.city : "NA"} */}
                              {equipment.city
                                ? equipment.city.length > 20
                                  ? equipment.city.substring(0, 20) + "... "
                                  : equipment.city
                                : "NA"}
                              {equipment.state
                                ? equipment.state.length > 20
                                  ? ', ' + equipment.state.substring(0, 20) + "..."
                                  : ', ' + equipment.state
                                : ""}
                            </Typography>
                            {equipment.owner_pickup_return == "1" ? (
                              <Typography>Delivery Available</Typography>
                            ) : (
                              <Typography></Typography>
                            )}
                          </Box>
                          <Box className="rentyrd-rates">
                            <Box className="rentyrd-rates-item">
                              <Typography>
                              $<CurrentFormat Currency={equipment.price_month} />
                                <span className="rentyrd-rates-span">/m</span>
                              </Typography>
                            </Box>
                            <Box className="rentyrd-rates-item">
                              <Typography>
                              $<CurrentFormat Currency={equipment.price_per_week} />

                                <span className="rentyrd-rates-span">/w</span>
                              </Typography>
                            </Box>
                            <Box className="rentyrd-rates-item">
                              <Typography>
                              $<CurrentFormat Currency={equipment.price_day} />

                                <span className="rentyrd-rates-span">/d</span>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography className="card-uid">
                      EID{equipment.id}
                      {/* --{equipment.start_date}--{equipment.start_time}--{equipment.order_status} */}
                    </Typography>
                  </Box>

                  {EquipResult.length != indx + 1 && (
                    <hr className="gray-line" />
                  )}
                </>
              ))}

              {TotalResult > paginationPage && EquipResult.length < TotalResult && (
                <Box className="pagination-section">
                  <Box
                    className="btn-load-more"
                    sx={{ color: "#faa61a !important" }}
                    onClick={HandlePagination}
                  >
                    {"See More"}
                    <img
                      src={gemArrow}
                      alt="GoEquipMe"
                      className="gem-arrow arrow-load-more"
                    />
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <>
              <Typography className="empty-rent">
                <span>You do not have any equipment rented.</span>
                <span>
                  To rent now&nbsp;
                  <Link className="rentyrd-menu-link" href="/">
                    click here
                  </Link>
                </span>
              </Typography>
            </>
          )}
        </Box>

        {ActiveEquip != null && (
          <Box className="rentyrd-grid-item rentyrd-right">
            <Box className="rentyrd-left-float">
              <Box className="equipment-details-heading">
                <Typography>Booking Details </Typography>
              </Box>

              <Box className="rentyrd-card">
                <Box className="rentyrd-card-grid">
                  <Box className="rentyrd-card-item-left">
                    <Box className="rentyrd-card-img">
                      <img className='eqppmnt-img' src={ActiveEquip["thumbnail"]} alt="" />
                    </Box>
                  </Box>
                  <Box className="rentyrd-card-item-right">
                    <Box className="rentyrd-right">
                      <Typography className="vehicle-model"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}>
                        {ActiveEquip["make"]
                          ? ActiveEquip["make"]
                          : "Not Available"}
                      </Typography>
                      <Typography className="vehicle-type"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}>
                        {ActiveEquip["model"]
                          ? ActiveEquip["model"]
                          : "Not Available"}
                      </Typography>
                      <Box className="rentyrd-address">
                        <Typography title={ActiveEquip["city"] ? ActiveEquip["city"] : "NA"} sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                        }}>
                          {ActiveEquip["city"] ? ActiveEquip["city"] : "NA"}
                          {ActiveEquip["state"] ? ", " + ActiveEquip["state"] : "NA"}
                        </Typography>
                        {ActiveEquip["owner_pickup_return"] == "1" ? (
                          <Typography>Delivery Available</Typography>
                        ) : (
                          <Typography></Typography>
                        )}
                      </Box>
                      <Box className="rentyrd-rates">
                        <Box className="rentyrd-rates-item">
                          <Typography>
                          $<CurrentFormat Currency={ActiveEquip["price_month"]} />

                            <span className="rentyrd-rates-span">/m</span>
                          </Typography>
                        </Box>
                        <Box className="rentyrd-rates-item">
                          <Typography>
                          $<CurrentFormat Currency={ActiveEquip["price_per_week"]} />

                            <span className="rentyrd-rates-span">/w</span>
                          </Typography>
                        </Box>
                        <Box className="rentyrd-rates-item">
                          <Typography>
                          $<CurrentFormat Currency={ActiveEquip["price_day"]} />
                            <span className="rentyrd-rates-span">/d</span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>


              <Box className="edit-listing" sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem" }}>
                {/* For Live */}

                {/* {(ActiveEquip["order_status"] == "payment_complete" ||
                  ActiveEquip["order_status"] == "dispute_settled" ||
                  ActiveEquip["order_status"] == "security_settled" ||
                  ActiveEquip["order_status"] == "order_canceled") && (
                    <Button className="edit-listing-btn" onClick={HandleRentAgain}>
                      Rent Again
                    </Button>
                  )} */}

                {/* For Staging */}
                {/* button commented by GD to prevent extend rental fxnality to go live */}
                {(ActiveEquip["order_status"] == "payment_complete" || ActiveEquip["order_status"] == "pickup" || ActiveEquip["order_status"] == "returned") && ExtendRentalBtnStatus ? (

                  <Button className="edit-listing-btn extend-rent-now" onClick={HandleRentExtend}>
                    Extend Rental
                  </Button>
                ) : (
                  <Button className="edit-listing-btn" onClick={HandleRentAgain}>
                    Rent Again
                  </Button>
                )}

              </Box>


              {/* Start */}

              <Box className="rentyrd-order">
                <Link
                  className="rentyrd-order-id"
                  onClick={() => HandleOrderDetail(ActiveEquip)}
                  style={{ cursor: "pointer" }}
                >
                  Order ID #{ActiveEquip["order_id"]}
                </Link>
                {/* <Link className="rentyrd-order-id">Order ID #{orderdata['order_id']}</Link> */}


                <Box className="rentyrd-dates">
                  <Box>
                    <Typography className="rentyrd-dates-heading">
                      RENTAL DATES
                    </Typography>
                    <Box className="rentyrd-dates-item rental-dates">

                      <Typography>
                        <span>Start:</span>
                        <span>
                          {moment(ActiveEquip["start_date"], [
                            "YYYY-MM-DD",
                          ]).format("MM/DD/YYYY")}{" "}
                          {moment(ActiveEquip["start_time"], [
                            "HH:mm:ii",
                          ]).format("hh:mm A")}
                          &nbsp;({timeZone})
                        </span>
                      </Typography>
                      <Typography>
                        <span>End:</span>
                        {ActiveEquip["order_extend"] && ActiveEquip["order_extend"].length > 0 && ActiveEquip["order_extend"] != null ?
                          (
                            <span>
                              {moment(ActiveEquip['order_extend'][ActiveEquip["order_extend"].length - 1]['end_date'], [
                                "YYYY-MM-DD",
                              ]).format("MM/DD/YYYY")}{" "}
                              {moment(ActiveEquip['order_extend'][ActiveEquip["order_extend"].length - 1]['end_time'], ["HH:mm:ii"]).format(
                                "hh:mm A"
                              )}
                              &nbsp;({timeZone})
                            </span>
                          ) : (
                            <span>
                              {moment(ActiveEquip["end_date"], [
                                "YYYY-MM-DD",
                              ]).format("MM/DD/YYYY")}{" "}
                              {moment(ActiveEquip["end_time"], ["HH:mm:ii"]).format(
                                "hh:mm A"
                              )}
                              &nbsp;({timeZone})
                            </span>
                          )}

                        {/* <span>
                          {moment(ActiveEquip["end_date"], [
                            "YYYY-MM-DD",
                          ]).format("MM/DD/YYYY")}{" "}
                          {moment(ActiveEquip["end_time"], ["HH:mm:ii"]).format(
                            "hh:mm a"
                          )}
                        </span> */}

                      </Typography>
                    </Box>
                  </Box>

                  <Box className="rentyrd-order-status">
                    {ActiveEquip["order_status"] ==
                      "payment_complete" ? (
                      <Typography className="od-status-green">Order Placed</Typography>
                    ) : ActiveEquip["order_status"] == "pickup" ? (
                      <Typography className="od-status-green">Picked Up</Typography>
                    ) : ActiveEquip["order_status"] == "returned" ? (
                      <Typography className="od-status-green">Returned</Typography>
                    ) : ActiveEquip["order_status"] == "completed" ? (
                      <Typography className="od-status-green">Completed</Typography>
                    ) : ActiveEquip["order_status"] ==
                      "security_settled" ? (
                      <Typography className="od-status-red">Security Settled</Typography>
                    ) : ActiveEquip["order_status"] == "disputed" ? (
                      <Typography className="od-status-red">disputed</Typography>
                    ) : ActiveEquip["order_status"] ==
                      "dispute_settled" ? (
                      <Typography className="od-status-red">Dispute Sttled</Typography>
                    ) :
                      ActiveEquip["order_status"] ==
                        "order_canceled" ||
                        ActiveEquip["order_status"] == "order_cancelled" ?
                        (
                          <Typography className="od-status-red">Order cancelled</Typography>
                        ) :
                        (
                          <Typography className="od-status-yellow">Order Returned</Typography>
                        )}
                  </Box>



                </Box>

                <Box className="rentyrd-renter-detail">
                  <Box className="renter-detail">
                    <Box className="ro-img">
                      <img src={ActiveEquip["profile_pic"] ? ActiveEquip["profile_pic"] : Img} alt="" />
                    </Box>
                    <Box className="ro-name">
                      <Typography>
                        <span style={{ color: "#fff" }}>Owner:</span>
                        <span
                          style={{ cursor: "pointer", color: "#faa61a", textDecoration: "underline" }}
                          onClick={() => {
                            setOwnerInfo(true);
                          }}
                        >
                          {ActiveEquip["first_name"].charAt(0).toUpperCase() + ActiveEquip["first_name"].slice(1)}</span>
                      </Typography>
                      <Link
                        style={{ cursor: "pointer" }}
                        className="message-renter"
                        onClick={HandleChatOwnerToRenter}
                      >
                        Message Owner
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="rentyrd-order-links">
                  <Link
                    href="javascript:void(0)"
                    onClick={() => {
                      HandleProjectLocation(ActiveEquip);
                    }}
                  >
                    {ActiveEquip.owner_pickup_return == "1" ? "Project Location" : "Get Direction"}
                  </Link>
                  {/* {EquipData['renter_images'] != null && ( */}
                  <Link
                    href="javascript:void(0)"
                    onClick={() => {
                      setPickDropType("Pickup");
                      setPickupDropoffpopup(true);
                      Restval();
                    }}
                  >
                    Pickup Photos
                  </Link>
                  {/* )} */}

                  {/* {EquipData['renter_images'] != null && ( */}
                  <Link
                    href="javascript:void(0)"
                    onClick={() => {
                      setPickDropType("Dropoff");
                      setPickupDropoffpopup(true);
                      Restval();
                    }}
                  >
                    Dropoff Photos
                  </Link>
                  {/* )} */}
                </Box>

                <Box>
                  {(ActiveEquip["order_status"] == "payment_complete" || ActiveEquip["order_status"] == "pickup") && (
                    <Typography sx={{ color: "#c21414", cursor: "pointer", textDecorationLine: 'underline', textDecorationColor: '#c21414', padding: "2px 8px", borderRadius: "5px", fontFamily: 'GothamBold !important' }}
                      onClick={() => {
                        reason = '';
                        setExtendOrderCancelId(ActiveEquip["order_id"])
                        setOrderCancelPopup(true)
                      }}
                    >
                      X&nbsp;Cancel Order
                    </Typography>


                  )}
                </Box>



              </Box>





              {/* Stop */}

              <Box style={{ maxHeight: "500px", overflowY: "scroll", paddingRight: "5px", display: "none" }}>
                <Box className="rentyrd-order">
                  <Link
                    className="rentyrd-order-id"
                    onClick={() => HandleOrderDetail(ActiveEquip)}
                    style={{ cursor: "pointer" }}
                  >
                    Order ID #{ActiveEquip["order_id"]}
                  </Link>
                  <Typography className="rentyrd-dates-heading">
                    RENTAL DATES
                  </Typography>

                  <Box className="rentyrd-dates">
                    <Box className="rentyrd-dates-item rental-dates">
                      <Typography>
                        <span>Start:</span>
                        <span>
                          {moment(ActiveEquip["start_date"], [
                            "YYYY-MM-DD",
                          ]).format("MM/DD/YYYY")}{" "}
                          {moment(ActiveEquip["start_time"], [
                            "HH:mm:ii",
                          ]).format("hh:mm A")}
                          &nbsp;({timeZone})
                        </span>
                      </Typography>
                      <Typography>
                        <span>End:</span>
                        <span>
                          {moment(ActiveEquip["end_date"], [
                            "YYYY-MM-DD",
                          ]).format("MM/DD/YYYY")}{" "}
                          {moment(ActiveEquip["end_time"], ["HH:mm:ii"]).format(
                            "hh:mm A"
                          )}
                          &nbsp;({timeZone})
                        </span>
                      </Typography>
                    </Box>

                    <Box className="rentyrd-dates-item renter-detail">
                      <Box className="ro-img">
                        <img src={ActiveEquip["profile_pic"] ? ActiveEquip["profile_pic"] : Img} alt="" />
                      </Box>
                      <Box className="ro-name">
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOwnerInfo(true);
                          }}
                        >
                          <span>Owner:</span>
                          <span className='gem-ro-name' style={{ color: "#faa61a !important" }}>
                            {ActiveEquip["first_name"].charAt(0).toUpperCase() + ActiveEquip["first_name"].slice(1)}</span>
                        </Typography>
                        <Link
                          style={{ cursor: "pointer" }}
                          className="message-renter"
                          onClick={HandleChatOwnerToRenter}
                        >
                          Message Owner
                        </Link>
                      </Box>
                    </Box>
                  </Box>

                  <Typography className="rentyrd-dates-heading">
                    ORDER STATUS
                  </Typography>
                  <Box
                    style={{
                      color: "#faa61a",
                      fontSize: "clamp(20px, 3vw, 35px)",
                      fontFamily: "GothamBold",
                    }}
                  >
                    {ActiveEquip["order_status"] == "payment_complete" ? (
                      <Typography>Order Placed</Typography>
                    ) : ActiveEquip["order_status"] == "pickup" ? (
                      <Typography>Picked Up</Typography>
                    ) : ActiveEquip["order_status"] == "returned" ? (
                      <Typography>Returned</Typography>
                    ) : ActiveEquip["order_status"] == "completed" ? (
                      <Typography>Completed</Typography>
                    ) : ActiveEquip["order_status"] == "security_settled" ? (
                      <Typography>Security Settled</Typography>
                    ) : ActiveEquip["order_status"] == "disputed" ? (
                      <Typography>disputed</Typography>
                    ) : ActiveEquip["order_status"] == "dispute_settled" ? (
                      <Typography>Dispute Sttled</Typography>
                    ) : (
                      <Typography>Order Returned</Typography>
                    )}
                  </Box>

                  <Box className="rentyrd-order-links">
                    <Link
                      href="javascript:void(0)"
                      onClick={() => {
                        HandleProjectLocation(ActiveEquip);
                      }}
                    >
                      Project Location
                    </Link>

                    <Link
                      href="javascript:void(0)"
                      onClick={() => {
                        setPickDropType("Pickup");
                        setPickupDropoffpopup(true);
                        Restval();
                      }}
                    >
                      Pickup Photos
                    </Link>

                    <Link
                      href="javascript:void(0)"
                      onClick={() => {
                        setPickDropType("Dropoff");
                        setPickupDropoffpopup(true);
                        Restval();
                      }}
                    >
                      Dropoff Photos
                    </Link>
                  </Box>
                </Box>
              </Box>






            </Box>
          </Box>
        )}
      </Box>

      {/* Owner Info Popup */}
      <Modal
        keepMounted
        open={OwnerInfo}
        onClose={() => setOwnerInfo(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton className="cross-btn" onClick={() => setOwnerInfo(false)}>
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner pupup-scroll">
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Owner Info
                </Typography>
                {ActiveEquip != null && OwnerInfo && (
                  <UserInfo userData={ActiveEquip} />
                  // <Box className="rentyrd-dates">
                  //   <Box className="rentyrd-dates-item rental-dates">
                  //     <Typography>
                  //       <span>
                  //         Name: {ActiveEquip["first_name"]}{" "}
                  //         {ActiveEquip["last_name"]}
                  //       </span>
                  //     </Typography>
                  //     <Typography>
                  //       <span>Email: {ActiveEquip["email"]}</span>
                  //     </Typography>
                  //     <Typography>
                  //       <span>Contact No.: {ActiveEquip["phone_no"]}</span>
                  //     </Typography>
                  //   </Box>
                  // </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Handle chat message renter to owner */}
      <Modal
        keepMounted
        open={ChatRenterToOwner}
        onClose={() => setChatRenterToOwner(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <span onClick={() => setChatRenterToOwner(false)}>
            <CloseIcon className="modal-popup" />
          </span>
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp chat-popup"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn edit-eqp-inner chat-popup-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Box className="chatpopup">
                  {ChatRenterToOwner && (
                    <ChatWindow ChatData={ChatData} NewMsg={NewMsg} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Equipment Pickup and Dropoff images */}
      <Modal
        keepMounted
        open={PickupDropoffpopup}
        onClose={() => setPickupDropoffpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setPickupDropoffpopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  {PickDropType} Photos
                </Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  <Box className="drp-off-img">
                    <Box className="drp-off-grid">
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Front
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={FrontImage ? FrontImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={HandleFrontImage}
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Back
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={BackImage ? BackImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={HandleBackImage}
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Right
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={RightImage ? RightImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={HandleRightImage}
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box className="drp-off-item">
                        <Typography className="drp-off-item-heading">
                          Left
                        </Typography>
                        <Box className="drp-off-item-inner">
                          <img
                            className="drp-off-img"
                            src={LeftImage ? LeftImage : placeholderImg}
                            alt=""
                          />
                          <Button
                            variant="contained"
                            component="label"
                            className="drp-off-btn"
                          >
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={HandleLeftImage}
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <CssTextField
                    fullWidth
                    placeholder="Add Additional Information"
                    multiline
                    maxRows={4}
                    onChange={HandleDescription}
                    value={Description}
                  />
                  <Box>
                    <Button
                      variant="text"
                      component="label"
                      className="add-more-upload-btn"
                      sx={{ color: "#fff !important" }}
                    >
                      Click to add more images
                      <input
                        id="photo"
                        name="photo"
                        type="file"
                        multiple={true}
                        onChange={handleImageChange}
                        hidden
                        accept="image/jpg, image/jpeg, image/png"
                      />
                    </Button>
                    <Box className="addmore-img-grid">
                      {file.map((image, i) => (
                        <Box className="addmore-img-grid-item-box">
                          <img
                            className="addmore-img-grid-item"
                            src={image}
                            alt=""
                          />
                          <IconButton
                            className="cross-btn cross-btn-3"
                            onClickCapture={(i) => {
                              removeImage(image, i);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Typography className="drp-off-item-heading">
                    Add Hours/Miles
                  </Typography>
                  <FormGroup >
                    <Box className="checbox-main-div">
                      <Box className="checbox-div" sx={{ color: "#fff !important" }}>
                        <Checkbox
                          className="checkbox-label"
                          checked={NotAvailable}
                          onChange={HandleNotAvailable}
                        />
                        <label>Not Available</label>
                      </Box>

                      {!NotAvailable && (
                        <>
                          <Box className="checbox-div" sx={{ color: "#fff !important" }}>
                            <Checkbox
                              className="checkbox-label"
                              checked={Hours}
                              onChange={HandleHourCheck}
                            />
                            <label>Hours</label>
                          </Box>
                          <Box className="checbox-div" sx={{ color: "#fff !important" }}>
                            <Checkbox
                              className="checkbox-label"
                              checked={Miles}
                              onChange={HandleMilesCheck}
                            />
                            <label>Miles</label>
                          </Box>
                        </>
                      )}
                    </Box>
                  </FormGroup>
                  {!NotAvailable && (
                    <>
                      <Box className="drp-off-reading">
                        <Typography className="" sx={{ color: "#fff !important" }}>Current Reading</Typography>
                        <CssTextField
                          placeholder="Ex. 22222"
                          type="text"
                          onChange={HandleReading}
                          value={CurrentReading}
                        />
                      </Box>

                      <Box>
                        <Button
                          variant="text"
                          component="label"
                          className="add-more-upload-btn"
                          sx={{ color: "#fff !important" }}
                        >
                          Click to add Hours/Miles images
                          <input
                            id="photo"
                            name="photo"
                            type="file"
                            multiple={true}
                            onChange={HandleHourMileImageChange}
                            hidden
                            accept="image/jpg, image/jpeg, image/png"
                          />
                        </Button>
                        <Box className="addmore-img-grid">
                          {HourMilesImages.map((images, i) => (
                            <Box className="addmore-img-grid-item-box">
                              <img
                                className="addmore-img-grid-item"
                                src={images}
                                alt=""
                              />
                              <IconButton
                                className="cross-btn cross-btn-3"
                                onClickCapture={(i) => {
                                  RemoveHourMileImage(images, i);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </>
                  )}
                  <Button
                    className="signin-btn"
                    type="button"
                    //fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={Submit}
                  >
                    Upload
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="signin-btn"
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#faa61a !important" }}
                    onClick={() => {
                      setPickupDropoffpopup(false);
                    }}
                  >
                    Skip
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


      {/* Equipment Pickup and Dropoff Additional comment */}
      <Modal
        keepMounted
        open={FinalPopup}
        onClose={() => setFinalPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setFinalPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Box>
                  <Typography variant="h5" style={{ color: "#faa61a" }}>
                    Additional Info
                  </Typography>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  <CssTextField
                    fullWidth
                    placeholder="Add Information"
                    multiline
                    minRows={4}
                    value={AdditionalInfo}
                    onChange={HandleAdditionalInfo}
                  />
                  <Button
                    className="signin-btn"
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={FinalSubmit}
                  >
                    Save
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    className="signin-btn"
                    type="button"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#faa61a !important" }}
                    onClickCapture={FinalSubmit}
                  >
                    Skip
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Order Detail Popup Section */}
      <Modal
        keepMounted
        open={OrderDetailpopup}
        onClose={() => setOrderDetailpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >

              <Box className="login__main-div__form popup-cmmn popup-cmmn-modal pupup-scroll edit-eqp-inner">
                <span onClick={() => setOrderDetailpopup(false)}>
                  <CloseIcon className="modal-popup" />
                </span>

                <Box className='new-modal-heading'>
                  <img src={Orderdetailimg} style={{ width: "5%" }} />
                  <Typography variant="h5">
                    <span style={{ color: "#faa61a" }}>Order</span>Detail
                  </Typography>
                </Box>

                <Box className="extend-rental-od">
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  {OrderDetailpopup && (
                    <Box className=''>
                      <OrderDetail OrderObj={OrderDetailPopData} title={'no'} ExtendedOrders={ExtendOrderObj} Success={(data) => {
                        if (data) {
                          //console.log('datas',data);
                        }
                      }} />
                    </Box>

                  )}

                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


      {/* Extend rental Popup */}
      
      <Modal
        keepMounted
        // by GD 29 Dec added open false to prevent open of extend rental popup
        // open={false}
        open={ExtendRentalPopup}

        onClose={() => setExtendRentalPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >

              {/* <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner cal-pop"> */}
              <Box className="login__main-div__form popup-cmmn popup-cmmn-modal pupup-scroll edit-eqp-inner extend-rental">
                <span onClick={() => {
                  // RentedEquip(AuthProfileData['user_id'], 0)
                  setExtendRentalPopup(false)
                }}>
                  <CloseIcon className="modal-popup" />
                </span>


                <Box className="">
                  {ExtendRentalPopup && (
                    <ExtendPop ActiveOrder={ActiveEquip} Result={(data) => {
                      if (data) {
                        setExtendRentalPopup(false);
                        // if (data == 'closepopup') {                          
                        //   //RentedEquip(AuthProfileData['user_id'], 0)
                        // }
                        // by GD 5 jan 2024 to reload orders data
                        if (data == 'orderExtended') {                          
                          RentedEquip(AuthProfileData['user_id'], 0)
                        }
                        
                      }
                    }} />
                  )}


                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>


      {/* Order cancel popup */}
      <Modal
        keepMounted
        open={OrderCancelPopup}
        onClose={() => setOrderCancelPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h4" style={{ color: "#faa61a", textAlign: 'center' }}>
                  Cancel
                </Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                </Box>

                <Box>
                  <FormControl fullWidth className="form-fields" sx={{ width: "100%" }}>
                    <NewCssTextField
                      id="outlined-multiline-static"
                      multiline
                      placeholder={'Cancellation reason'}
                      // maxRows={2}
                      rows={2}
                      fullWidth
                      variant="filled"
                      //label="Instructions"
                      onChange={(value) => {
                        reason = value.target.value
                      }}
                      //value={ExtendOrderCancelIdreason}
                      className="popup-input"
                    />
                  </FormControl>
                </Box>


                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }} >
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#139246" }}
                    onClickCapture={CancelMainOrder}>
                    Ok
                  </Button>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#faa61a !important" }}
                    onClickCapture={() => {
                      reason = '';
                      setOrderCancelPopup(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Box>

              </Box>
            </Box>

          </Box>

        </Box>
      </Modal>


    </>
  );
}
export default Irented