import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Box, CircularProgress, Typography, Backdrop, Link, Modal, FormLabel, Button, FormControl, TextField, styled } from '@mui/material';
import moment from 'moment';

import { IStoreValue } from 'utils/types';
//import CloseIcon from "@mui/icons-material/Close";
import { EquipmentAPIs } from "../../service/api-service";
import { useDispatch, useSelector } from "react-redux";

import { setalertMessagetype, setalertMessage, } from "redux/redux-slice";

import ExtendedOrderDetail from '../common/Extendorderdetail';

let reason = ''

const VieworderDetail = ({ OrderObj, title = 'no', ExtendedOrders = [], Success }) => {
  // console.log('OrderObj', OrderObj);
  const dispatch = useDispatch();
  const [Orderloading, setOrderloading] = React.useState(false);
  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);

  const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)
  const [OrderDetailPopData, setOrderDetailPopData] = useState({
    "thumbnail": "",
    "id": "",
    "make": "",
    "model": "",
    "start_date": "",
    "start_time": "",
    "end_date": "",
    "end_time": "",
    "profile_pic": "",
    "first_name": "",
    "order_status": "",
    "order_from": "",
    "rental_price": 0,
    "minimum_delivery_fees": 0,
    "security_deposit_refund_txt": "",
    "security_deposit": 0,
    "grand_total_txt": "",
    "grand_total": 0,
    "protection_fee": 0,
    "transport_fee": 0,
    "admin_fee": 0,
    "side": "renter",
    "extend_orders": [],
  });

  const [ActiveOrder, setActiveOrder] = React.useState([]);
  const [UserInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (OrderObj) {

      console.log('OrderObj', OrderObj);
      setOrderloading(true)
      setOrderDetailPopData(OrderObj);
      setOrderloading(false)

      setActiveOrder([]);
      let ex_orders = OrderObj.extend_orders;

      if (ex_orders != undefined && ex_orders != null && ex_orders.length > 0) {
        setActiveOrder(ex_orders);
      }

      let user_info = {
        profile_pic: OrderObj.profile_pic,
        first_name: OrderObj.first_name,
      }
      setUserInfo(user_info);

      console.log('OrderObj', OrderObj);

    }
  }, [OrderObj]);

  // Extended Order section start
  const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
  }

  const NewCssTextField = styled(TextField)({

    "& .MuiInput-underline:after": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "0 !important",
      borderBottomColor: "transparent !important",
    },

    "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
      color: "#2d2d2d",
    },
    "& .MuiInputBase-root": {
      color: "#2d2d2d",
      background: "#fff",
      fontFamily: "Gotham !important",
      fontSize: "clamp(12px, 3vw, 15px) !important",
      borderRadius: "10px !important",
      // border: "2px solid #faa61a",
    },
    "&. MuiInputLabel-shrink": {
      color: "red !important",
    },

    "&. MuiInputLabel-shrink.Mui-focused": {
      color: "red !important",
    },

    "& label.Mui-focused": {
      color: "#2d2d2d !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid #faa61a",
        backgroundColor: "#fff !important"
      },
      "&:hover fieldset": {
        borderColor: "#2d2d2d",
        border: "2px solid #faa61a",
        backgroundColor: "#fff !important"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#faa61a",
      },
    },
    "& .MuiInputBase-root-MuiFilledInput-root:before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
  });

  const [Loading, setLoading] = React.useState(false);
  const [ExtendedOrdersData, setExtendedOrdersData] = React.useState(false);
  const [ExtendOrderCancelPopup, setExtendOrderCancelPopup] = React.useState(false);
  const [ExtendOrderCancelId, setExtendOrderCancelId] = React.useState(null);
  const [ExtendOrderCancelIdreason, setExtendOrderCancelIdreason] = React.useState('');

  const HandleOrderCancelReason = (event) => {
    setExtendOrderCancelIdreason(event.target.value)
  }

  // Cancel Extend Order
  const CancelExtendedOrder = async () => {
    try {
      setLoading(true);
      // let reason = ExtendOrderCancelIdreason;

      if (reason == '' || reason == null) {
        dispatch(setalertMessage('Please add reason for cancellation.'));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false
      }

      let data = {
        order_id: ExtendOrderCancelId.toString(),
        user_id: AuthProfileData['user_id'].toString(),
        reason: reason,
      };

      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrder(
        data,
        headers
      );

      if (Result['type'] == "RXSUCCESS") {
        reason = '';
        dispatch(setalertMessage('Order Cancel Successfully.'));
        dispatch(setalertMessagetype('success'));
        setLoading(false);
        setExtendOrderCancelPopup(false);
      }
      else {
        dispatch(setalertMessage(Result['message']));
        dispatch(setalertMessagetype('error'));
        setLoading(false);
        return false
      }

    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ExtendedOrders && ExtendedOrders.length > 0 && ExtendedOrders != null) {
      setExtendedOrdersData(true)

    }
  }, [ExtendedOrders]);
  // Extended order section end

  return (
    <>
      {Orderloading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={Orderloading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      {/* Order Detail Popup Section */}

      <Box className="rentyrd-grid-item rentyrd-right">
        <Box className="rentyrd-left-float">
          {title == "yes" && (
            <Box className="equipment-details-heading">
              <Typography
                sx={{
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "clamp(16px, 3vw , 25px)",
                  fontFamily: "GothamBold !important",
                }}
              >
                Order Details
              </Typography>
            </Box>
          )}

          <Box>
            <Box className="rentyrd-card">
              <Box className="rentyrd-card-grid">
                <Box className="rentyrd-card-item-left">
                  <Box className="rentyrd-card-img">
                    <img className='eqppmnt-img' src={OrderDetailPopData.thumbnail} alt="" />
                  </Box>
                </Box>
                <Box className="rentyrd-card-item-right">
                  <Box className="rentyrd-right">
                    <Typography className="vehicle-model"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      title={OrderDetailPopData.make
                        ? OrderDetailPopData.make
                        : "Not Available"}
                    >
                      {OrderDetailPopData.make
                        ? OrderDetailPopData.make
                        : "Not Available"}
                    </Typography>
                    <Typography className="vehicle-type"
                     sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                    }}
                    >
                      {OrderDetailPopData.model
                        ? OrderDetailPopData.model
                        : "Not Available"}
                    </Typography>
                    <Typography sx={{ color: "#fff" }}>{OrderDetailPopData.id}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="rentyrd-order">
                <Link
                  className="rentyrd-order-id"
                  // onClick={() => HandleOrderDetail(ActiveEquip)}
                  style={{ cursor: "pointer" }}
                >
                  {/* Order ID {ActiveEquip["order_id"]} */}
                </Link>
                <Typography className="rentyrd-dates-heading">
                  RENTAL DATES
                </Typography>

                <Box className="rentyrd-dates">
                  <Box className="rentyrd-dates-item rental-dates">
                    <Typography>
                      <span>Start:</span>
                      <span>
                        {moment(OrderDetailPopData.start_date, [
                          "YYYY-MM-DD",
                        ]).format("MM/DD/YY")}{" "}
                        {moment(OrderDetailPopData.start_time, [
                          "HH:mm:ii",
                        ]).format("hh:mm A")}
                        &nbsp;({OrderObj["timeZone"]})
                      </span>
                    </Typography>
                    <Typography>
                      <span>End:</span>

                      {OrderDetailPopData.side == "renter" ? (
                        <>
                          {(ExtendedOrdersData && ExtendedOrders.length > 0) ? (
                            <>
                              <span>
                                {moment(ExtendedOrders[0]['start_date'], [
                                  "YYYY-MM-DD",
                                ]).format("MM/DD/YY")}{" "}
                                {moment(ExtendedOrders[0]['start_time'], ["HH:mm:ii"]).format(
                                  "hh:mm A"
                                )}
                                &nbsp;({OrderObj["timeZone"]})
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                {moment(OrderDetailPopData.end_date, [
                                  "YYYY-MM-DD",
                                ]).format("MM/DD/YY")}{" "}
                                {moment(OrderDetailPopData.end_time, [
                                  "HH:mm:ii",
                                ]).format("hh:mm A")}
                                &nbsp;({OrderObj["timeZone"]})
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <><span>
                          {moment(OrderDetailPopData.end_date, [
                            "YYYY-MM-DD",
                          ]).format("MM/DD/YY")}{" "}
                          {moment(OrderDetailPopData.end_time, [
                            "HH:mm:ii",
                          ]).format("hh:mm A")}
                          &nbsp;({OrderObj["timeZone"]})
                        </span></>
                      )}




                      {/* <span>
                        {moment(OrderDetailPopData.end_date, [
                          "YYYY-MM-DD",
                        ]).format("MM/DD")}{" "}
                        {moment(OrderDetailPopData.end_time, [
                          "HH:mm:ii",
                        ]).format("hh:mm a")}
                      </span> */}
                    </Typography>
                  </Box>

                  <Box className="rentyrd-dates-item renter-detail">
                    <Box className="ro-img">
                      <img src={OrderDetailPopData.profile_pic} alt="" />
                    </Box>
                    <Box className="ro-name">
                      <Typography
                        // style={{ cursor: "pointer" }}
                        // onClick={() => {
                        //   setOwnerInfo(true);
                        // }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span>{OrderDetailPopData.side != "renter" ? (
                          <>{AuthProfileData['first_name'] + ' ' + AuthProfileData['last_name'] == OrderDetailPopData.first_name ? "Owner" : "Renter"}</>
                        ) : (
                          <>Owner</>
                        )}: </span>
                        <span className="gem-ro-name">
                          {OrderDetailPopData.first_name.charAt(0).toUpperCase() + OrderDetailPopData.first_name.slice(1)}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <hr className="yellow-line" />
            <Box className="">
              <Box className="new-rate-box">
                <Box
                  className="rate-list-grid"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#fff" }}>
                    Status :{" "}
                    <span
                      style={{
                        color: "#faa61a",
                        textTransform: "uppercase",
                      }}
                    >
                      {OrderDetailPopData.order_status == "completed" ||
                        OrderDetailPopData.order_status == "security_settled"
                        ? "COMPLETE"
                        : OrderDetailPopData.order_status == "order_canceled"
                          ? "Cancelled"
                          : OrderDetailPopData.order_status ==
                            "payment_complete"
                            ? "pending"
                            : "Active"}
                    </span>
                  </Typography>
                </Box>
                <Box
                  className="rate-list-grid"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#fff" }}>
                    Transport : {OrderDetailPopData.order_from}
                  </Typography>
                </Box>
                <Box
                  className="rate-list-grid"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#fff" }}>Rental Price</Typography>
                  <Typography
                    sx={{ color: "#fff", flex: "1" }}
                    className="dash-white-border"
                  ></Typography>
                  <Typography sx={{ color: "#fff" }}>
                    {/* ${OrderDetailPopData.rental_price.toFixed(2)} */}
                    ${new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(OrderDetailPopData.rental_price)}
                  </Typography>
                </Box>
                <Box
                  className="rate-list-grid"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#fff" }}>
                    Security Deposit{" "}
                    {OrderDetailPopData.security_deposit_refund_txt}
                  </Typography>
                  <Typography
                    sx={{ color: "#fff", flex: "1" }}
                    className="dash-white-border"
                  ></Typography>
                  <Typography sx={{ color: "#fff" }}>
                    {/* ${OrderDetailPopData.security_deposit.toFixed(2)} */}
                    ${new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(OrderDetailPopData.security_deposit)}
                  </Typography>
                </Box>

                {OrderDetailPopData.protection_fee != undefined &&
                  OrderDetailPopData.protection_fee != null &&
                  OrderDetailPopData.protection_fee != 0 && (
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ color: "#faa61a" }}>
                        EquipMe Protection{" "}
                      </Typography>
                      <Typography
                        sx={{ color: "#faa61a", flex: "1" }}
                        className="dash-white-border"
                      ></Typography>
                      <Typography sx={{ color: "#faa61a" }}>

                        ${new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(OrderDetailPopData.protection_fee)}
                      </Typography>
                    </Box>
                  )}

                <Box
                  className="rate-list-grid"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {OrderDetailPopData.order_from != "Renter" && (
                    <>
                      <Typography sx={{ color: "#fff" }}>
                        Transport Charges
                      </Typography>
                      <Typography
                        sx={{ color: "#fff", flex: "1" }}
                        className="dash-white-border"
                      ></Typography>
                      <Typography sx={{ color: "#fff" }}>
                        ${new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(OrderDetailPopData.transport_fee)}
                      </Typography>
                    </>
                  )}
                </Box>

                {OrderDetailPopData.admin_fee > 0 && (
                  <Box
                    className="rate-list-grid"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#fff" }}>Admin Fee</Typography>
                    <Typography
                      sx={{ color: "#fff", flex: "1" }}
                      className="dash-white-border"
                    ></Typography>
                    <Typography sx={{ color: "#fff" }}>
                      -${new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(OrderDetailPopData.admin_fee)}
                    </Typography>
                  </Box>
                )}

                {OrderDetailPopData.order_status == "order_canceled" && (
                  <Box
                    className="rate-list-grid"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: "#faa61a" }}>
                      Order has been Cancelled
                    </Typography>
                  </Box>
                )}

                <Box
                  className="rate-list-grid g-total"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ color: "#fff" }}>
                    {OrderDetailPopData.side == "renter" ? (
                      <>Total Charges</>
                    ) : (
                      <>Total Earned</>
                    )}

                    {OrderDetailPopData.grand_total_txt}
                  </Typography>
                  <Typography
                    sx={{ color: "#fff", flex: "1" }}
                    className="dash-white-border"
                  ></Typography>
                  <Typography sx={{ color: "#fff" }}>
                    ${new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(OrderDetailPopData.grand_total)}

                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Extended order section start */}

            {ExtendedOrdersData && (
              <Box className='extendedorder-details'>
                {/* <hr className="yellow-line" /> */}
                <Box className='extendedorder-item'>
                  <Box className="new-rate-box">
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ color: "#faa61a", textTransform: "uppercase" }}>
                        EXTENDED ORDER DETAILS:
                      </Typography>
                    </Box>
                  </Box>


                  {/* {ExtendedOrders.length > 0 && ExtendedOrders.map((images, i) => ( */}
                  {ExtendedOrders.length > 0 && ExtendedOrders.map((Ex_Order, i) => {

                    var security = Ex_Order.security_deposit;
                    var refund_security = Ex_Order.returnd_deposit != undefined &&
                      Ex_Order.returnd_deposit != null &&
                      Ex_Order.returnd_deposit != 0 ? Ex_Order.returnd_deposit : 0;

                    var epf = Ex_Order.equipment_protection_fees;

                    var refund_epf = Ex_Order.refunded_protection_fee != undefined &&
                      Ex_Order.refunded_protection_fee != null &&
                      Ex_Order.refunded_protection_fee != 0 ? Ex_Order.refunded_protection_fee : 0;

                    var final_security = security - refund_security;
                    var final_epf = epf - refund_epf;

                    var total_charges = Ex_Order.price + final_security + final_epf;

                    refund_security = Ex_Order.returnd_deposit != undefined &&
                      Ex_Order.returnd_deposit != null &&
                      Ex_Order.returnd_deposit != 0 ? Ex_Order.returnd_deposit : '';

                    refund_epf = Ex_Order.refunded_protection_fee != undefined &&
                      Ex_Order.refunded_protection_fee != null &&
                      Ex_Order.refunded_protection_fee != 0 ? Ex_Order.refunded_protection_fee : '';

                    epf = Ex_Order.equipment_protection_fees != undefined &&
                      Ex_Order.equipment_protection_fees != null &&
                      Ex_Order.equipment_protection_fees != 0 ? Ex_Order.equipment_protection_fees : '';

                    return <>
                      <Box>
                        <Box className="rentyrd-card">

                          <Box className="rentyrd-order">
                            <Typography className="rentyrd-dates-heading">
                              RENTAL DATES
                            </Typography>

                            <Box className="rentyrd-dates">
                              <Box className="rentyrd-dates-item rental-dates">
                                <Typography>
                                  <span>Start:</span>
                                  <span>
                                    {moment(Ex_Order.start_date, [
                                      "YYYY-MM-DD",
                                    ]).format("MM/DD/YY")}{" "}
                                    {moment(Ex_Order.start_time, [
                                      "HH:mm:ii",
                                    ]).format("hh:mm A")}
                                    {/* &nbsp;({OrderObj["timeZone"]}) */}
                                  </span>
                                </Typography>
                                <Typography>
                                  <span>End:</span>
                                  <span>
                                    {moment(Ex_Order.end_date, [
                                      "YYYY-MM-DD",
                                    ]).format("MM/DD/YY")}{" "}
                                    {moment(Ex_Order.end_time, [
                                      "HH:mm:ii",
                                    ]).format("hh:mm A")}
                                    {/* &nbsp;({OrderObj["timeZone"]}) */}
                                  </span>
                                </Typography>
                              </Box>

                              <Box className="rentyrd-dates-item renter-detail">
                                <Box className="ro-img">
                                  <img src={OrderDetailPopData.profile_pic} alt="" />
                                </Box>
                                <Box className="ro-name">
                                  <Typography
                                    sx={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <span>{OrderDetailPopData.side != "renter" ? (
                                      <>{AuthProfileData['first_name'] + ' ' + AuthProfileData['last_name'] == OrderDetailPopData.first_name ? "Owner" : "Renter"}</>
                                    ) : (
                                      <>Owner</>
                                    )}: </span>
                                    <span className="gem-ro-name">
                                      {OrderDetailPopData.first_name.charAt(0).toUpperCase() + OrderDetailPopData.first_name.slice(1)}
                                    </span>
                                  </Typography>
                                </Box>
                              </Box>

                            </Box>
                          </Box>
                        </Box>


                        <Box className="new-rate-box">
                          <Box
                            className="rate-list-grid"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ color: "#fff" }}>Rental Price</Typography>
                            <Typography
                              sx={{ color: "#fff", flex: "1" }}
                              className="dash-white-border"
                            ></Typography>
                            <Typography sx={{ color: "#fff" }}>

                              ${new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(Ex_Order.price)}
                            </Typography>
                          </Box>
                          <Box
                            className="rate-list-grid"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ color: "#fff" }}>
                              Security Deposit{" "}

                              {refund_security &&
                                (
                                  <>
                                    (Refunded-$
                                    {new Intl.NumberFormat("en-US", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(refund_security)})
                                  </>
                                )}

                            </Typography>
                            <Typography
                              sx={{ color: "#fff", flex: "1" }}
                              className="dash-white-border"
                            ></Typography>
                            <Typography sx={{ color: "#fff" }}>
                              $
                              {new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(final_security)}
                            </Typography>
                          </Box>

                          {epf && (
                            <Box
                              className="rate-list-grid"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ color: "#faa61a" }}>
                                EquipMe Protection{" "}

                                {refund_epf &&
                                  (
                                    <>
                                      <span style={{ color: "#fff" }}>(Refunded-$
                                        {new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }).format(refund_epf)})
                                      </span>
                                    </>
                                  )}

                              </Typography>
                              <Typography
                                sx={{ color: "#faa61a", flex: "1" }}
                                className="dash-white-border"
                              ></Typography>
                              <Typography sx={{ color: "#faa61a" }}>

                                $
                                {new Intl.NumberFormat("en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }).format(final_epf)}



                              </Typography>
                            </Box>
                          )}


                          {Ex_Order.order_status == "order_canceled" && (
                            <Box
                              className="rate-list-grid"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ color: "#faa61a" }}>
                                Order has been Cancelled
                              </Typography>
                            </Box>
                          )}

                          <Box
                            className="rate-list-grid g-total"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ color: "#fff" }}>
                              Total Charges
                            </Typography>
                            <Typography
                              sx={{ color: "#fff", flex: "1" }}
                              className="dash-white-border"
                            ></Typography>
                            <Typography sx={{ color: "#fff" }}>
                              ${new Intl.NumberFormat("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(total_charges)}

                            </Typography>
                          </Box>

                          {(Ex_Order.order_status == "payment_complete" || Ex_Order.order_status == "pickup") && (
                            <Box
                              className="rate-list-btn"
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ color: "#c21414", cursor: "pointer", textDecorationLine: 'underline', textDecorationColor: '#c21414', padding: "2px 8px", borderRadius: "5px", fontFamily: 'GothamBold !important' }}
                                onClick={() => {
                                  setExtendOrderCancelIdreason('')
                                  setExtendOrderCancelId(Ex_Order.id)
                                  setExtendOrderCancelPopup(true)
                                }}
                              >
                                X&nbsp;Cancel Order
                              </Typography>


                            </Box>
                          )}

                        </Box>

                        <br></br>
                        <hr className="yellow-line" />
                      </Box>

                    </>;
                  })}

                </Box>

              </Box>
            )}

            {/* Extended order section end */}

            {/* Extended order owner section start */}
            {/* Extended orders in owner side */}
            {ActiveOrder.length > 0 && OrderDetailPopData.side != "renter" && (
              <ExtendedOrderDetail ExtendedOrder={ActiveOrder} UserInfo={UserInfo}
                Success={(data) => {
                  if (data) {
                    Success('data', data);
                  }
                }}
              />
            )}
            {/* Extended order owner section end */}



          </Box>
        </Box>
      </Box>



      {/* Extended order cancel popup */}
      <Modal
        keepMounted
        open={ExtendOrderCancelPopup}
        onClose={() => setExtendOrderCancelPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h4" style={{ color: "#faa61a", textAlign: 'center' }}>
                  Cancel
                </Typography>

                <Box>
                  {Loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={Loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                </Box>

                <Box>
                  <FormControl fullWidth className="form-fields" sx={{ width: "100%" }}>
                    <NewCssTextField
                      id="outlined-multiline-static"
                      multiline
                      placeholder={'Cancellation reason'}
                      // maxRows={2}
                      rows={2}
                      fullWidth
                      variant="filled"
                      //label="Instructions"
                      onChange={(value) => {
                        reason = value.target.value
                      }}
                      //value={ExtendOrderCancelIdreason}
                      className="popup-input"
                    />
                  </FormControl>
                </Box>


                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }} >
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#139246" }}
                    onClickCapture={CancelExtendedOrder}>
                    Ok
                  </Button>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#faa61a !important" }}
                    onClickCapture={() => {
                      reason = '';
                      setExtendOrderCancelPopup(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Box>

              </Box>
            </Box>

          </Box>

        </Box>
      </Modal>




    </>
  );
}
export default VieworderDetail