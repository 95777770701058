import React, { useCallback, useEffect, useRef, useState, Dispatch } from "react";
import {
  Box, Typography, CircularProgress, Backdrop, IconButton, Modal, useMediaQuery, Button, Container, Link, FormControl, TextField, InputLabel, Select, MenuItem, FormLabel, FormControlLabel, RadioGroup, Radio, Dialog, DialogActions, DialogContent, DialogTitle, styled, Checkbox
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import NumericInput from 'material-ui-numeric-input';

import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import MainLayout from "layouts/MainLayout";
import { useParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import moment from 'moment';
import { Calendar } from "react-modern-calendar-datepicker";


import gemArrow from 'assets/images/gem-arrow.png'
import GoogleMapLocation from "../equipment-list/GoogleautoComplete";
import Geocode from "react-geocode";

//photo tips
import Suggest1 from 'assets/images/suggest1.png'
import Suggest2 from 'assets/images/suggest2.png'
import Suggest3 from 'assets/images/suggest3.png'
import Suggest4 from 'assets/images/suggest4.png'
import Check from 'assets/images/check.png'

//import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css'
import { CheckBox, Padding } from "@mui/icons-material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import './styles.scss'

import Img from 'assets/images/dummy-profile-pic.jpeg';
//import { convertCompilerOptionsFromJson } from "typescript";
//var images_gallery = [];
import ExtendedOrderDetail from '../common/Extendorderdetail';

const NewCssTextField = styled(TextField)({

  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important"
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const NewCssNumericInput = styled(NumericInput)({

  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});


const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const CssNumericInput = styled(NumericInput)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});


const loginstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
}

/** commenting by GD
 * EditEquipment Component
 *
 * Description:
 * This component handles the editing functionality for equipment in both rental and sales yards.
 * It contains modals/pop-ups for updating equipment details, pricing, location, photos, and other related information.
 * Additionally, it includes the functionality to add equipment to sales or rent.
 * The component is versatile, accommodating various scenarios based on the yard type and the specific modal/pop-up opened.
 *
 * Other Modals/Pop-ups in This Component:
 * - Availability Calendar
 * - Equipment Location
 * - Pricing & Quantity
 * - Equipment Details
 * - Transport Pricing & Specs
 * - Rental Instructions
 * - Photos
 * - Rental History
 * - Add GPS
 * - SaleIt (for rental yard)
 * - Equipment Pricing (for sales yard)
 * - Equipment Photos (for sales yard)
 * - RentIt (for sales yard)
 *
 * Argument Details:
 * - Ref_EquipId: ID of the selected equipment from the rental or sales yard listing.
 * - OnSuccess: Callback passed to the parent component to update data.
 * - EditClick: State passed from the parent component to show/hide the edit full equipment modal/pop-up.
 * - ListEquipType: Indicates whether the edit component is called from the sales or rental yard.
 * - UpdateTab: Argument passed down from the main office trailer page, used to navigate through sales and rental yard tabs.
 * - equipType: Type of equipment (rent, sale, both), used to show/hide sellIt and rentIt options.
 * - reloadTabUpdate: Used to reload sales and rental yard, passed down from the main office trailer.
 *
 * @param {string|null} Ref_EquipId - ID of the selected equipment from the listing.
 * @param {Function} OnSuccess - Callback to update data in the parent component.
 * @param {boolean} EditClick - State to show/hide the edit full equipment modal/pop-up.
 * @param {string|null} ListEquipType - Type of equipment list (sales or rental yard).
 * @param {Function} UpdateTab - Callback to navigate through sales and rental yard tabs.
 * @param {string|null} equipType - Type of equipment (rent, sale, both).
 * @param {Function} reloadTabUpdate - Callback to reload sales and rental yard.
 */
// end commenting by GD

const EditEquipment = ({ Ref_EquipId = null, OnSuccess, EditClick = false, ListEquipType = null, UpdateTab, equipType = null , reloadTabUpdate }) => {
  const inRef = useRef(null)
  const searchRef = useRef();
  const EditRef = useRef(null);
  //const orderSCroll = useRef(null);
  const orderHistoryScroll = useRef(null);
  const APIKEY = process.env.REACT_APP_GEOCODDER_KEY
  Geocode.setApiKey(APIKEY);

  const [loadings, setLoadings] = useState(false);
  const params = useParams();
  const matchesMobileX = useMediaQuery('(max-width:425px)')
  const dispatch = useDispatch()
  const UserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser)

  //  const [UserID, setUserID] = React.useState('');
  const [EquipData, setEquipData] = React.useState([]);
  const [MyOrders, setMyOrders] = React.useState([]);
  const [MyOrdersPager, setMyOrdersPager] = React.useState([]);
  const [defaultMyOrdersPager, setdefaultMyOrdersPager] = React.useState(10);
  const [MyOrdersHistory, setMyOrdersHistory] = React.useState([]);
  const [PagerMyOrdersHistory, setPagerMyOrdersHistory] = React.useState([]);
  const [defaultPageMyOrdersHistory, setdefaultPageMyOrdersHistory] = React.useState(10);
  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
  const [addBlockdates, setaddBlockdates] = React.useState(false);
  const [Equiplocationpopup, setEquiplocationpopup] = React.useState(false);
  const [EquipPricingpopup, setEquipPricingpopup] = React.useState(false);
  const [EquipGallerypopup, setEquipGallerypopup] = React.useState(false);
  const [EquipDetailpopup, setEquipDetailpopup] = React.useState(false);
  const [EquipTransportationpopup, setEquipTransportationpopup] = React.useState(false);
  const [EquipRentalInstructionpopup, setEquipRentalInstructionpopup] = React.useState(false);
  const [EquipRentalHistorypopup, setEquipRentalHistorypopup] = React.useState(false);
  const [EquipAllDatapopup, setEquipAllDatapopup] = React.useState(false);
  const [OrderDetailpopup, setOrderDetailpopup] = React.useState(false);
  const [OrderStatusChange, setOrderStatusChange] = React.useState(false);
  const [RefundSecuritypopup, setRefundSecuritypopup] = React.useState(false);
  const [RefundSecurityamt, setRefundSecurityamt] = React.useState(0);
  const [RefundSecurityamount, setRefundSecurityamount] = React.useState(0);
  const [RefundSecurityamtreson, setRefundSecurityamtreson] = React.useState("");

  const [blockdate, setBlockdate] = useState([]);
  const [selectedDates, setselectedDates] = useState([]);
  const [EquipAddress, setEquipAddress] = useState('');
  const [EquipLocation, setEquipLocation] = useState({
    "lat": "",
    "lng": "",
  });

  const [FullAddress, setFullAddress] = useState({
    "address": "",
    "city": "",
    "state": "",
    "short_state": "",
    "postal_code": "",
    "country": "",
    "lat": "",
    "lng": "",
  });

  const [OrderDetailPopData, setOrderDetailPopData] = useState({
    "id": "",
    "make": "",
    "model": "",
    "start_date": "",
    "start_time": "",
    "end_date": "",
    "end_time": "",
    "profile_pic": "",
    "first_name": "",
    "order_status": "",
    "order_from": "",
    "rental_price": 0,
    "minimum_delivery_fees": 0,
    "security_deposit_refund_txt": "",
    "security_deposit": 0,
    "grand_total_txt": "",
    "grand_total": 0,
    "protection_fee": 0,
    "transport_fee": 0,
    "admin_fee": 0,
  });

  const [OrderStatusObj, setOrderStatusObj] = useState({
    order_id: "",
    renter_id: "",
    order_status: ""
  });

  const [priceperhour, setpriceperhour] = React.useState('');
  const [priceperday, setpriceperday] = React.useState('');
  const [priceperweek, setpriceperweek] = React.useState('');
  const [pricepermonth, setpricepermonth] = React.useState('');
  const [hourinclude, sethourinclude] = React.useState('24');

  const [category, setcategory] = React.useState('');
  const [AllCategories, setAllCategories] = useState([]);
  const [ListingTitle, setListingTitle] = React.useState('');
  const [Makemodel, setMakemodel] = React.useState('');
  const [Year, setYear] = React.useState('');
  const [vinNumber, setvinNumber] = React.useState('');
  const [Feature, setFeature] = React.useState('');
  const [Description, setDescription] = React.useState('');
  const [pendingDiscriptionChar, setpendingDiscriptionChar] = React.useState(1000);

  const [Thumbimage, setThumbimage] = useState(0);
  const [imagearr, setimagearr] = useState([]);
  const [Uploadedpath, setUploadedpath] = useState([]);
  const [file, setFile] = useState([]);

  const [rentpickupreturn, setrentpickupreturn] = React.useState('yes');
  const [ownerdeliveryreturn, setownerdeliveryreturn] = React.useState('yes');
  const [equpheight, setequpheight] = React.useState(null);
  const [equpHeightRadio, setequpHeightRadio] = React.useState('ft');
  const [equpwidth, setequpwidth] = React.useState(null);
  const [equpWidthRadio, setequpWidthRadio] = React.useState('ft');
  const [equplength, setequplength] = React.useState(null);
  const [equpLengthRadio, setequpLengthRadio] = React.useState('ft');
  const [equpweight, setequpweight] = React.useState(null);
  const [mintransportchrg, setmintransportchrg] = React.useState('');
  const [permiledeliverchrg, setpermiledeliverchrg] = React.useState('');
  const [permile, setpermile] = React.useState('');
  const [maximumtransportrange, setmaximumtransportrange] = React.useState('');
  const [availableSaturday, setavailableSaturday] = React.useState('yes');
  const [availableSunday, setavailableSunday] = React.useState('yes');

  const [DamageLiability, setDamageLiability] = React.useState(true);
  const [MaintenceService, setMaintenceService] = React.useState(true);
  const [WelcomeMessage, setWelcomeMessage] = React.useState(true);
  const [OperatingInstruction, setOperatingInstruction] = React.useState(true);
  const [DamageLiabilityText, setDamageLiabilityText] = React.useState(false);
  const [MaintenceServiceText, setMaintenceServiceText] = React.useState('');
  const [WelcomeMessageText, setWelcomeMessageText] = React.useState('');
  const [OperatingInstructionText, setOperatingInstructionText] = React.useState('');

  const [orderID, setorderID] = React.useState(null);
  const [RentalClick, setRentalClick] = React.useState('no');

  const [ActiveOrder, setActiveOrder] = React.useState([]);
  const [UserInfo, setUserInfo] = useState(null);

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let yearArr = [];
  for (let index = Number(year); index >= 1900; index--) {
    yearArr.push(index);
  }

  const minimumDate = {
    year: year,
    month: month,
    day: day,
  };

  const myCustomLocale = {
    // months list by order
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],

    // week days by order
    weekDays: [
      {
        name: 'Sunday', // used for accessibility 
        short: 'Sun', // displayed at the top of days' rows
        isWeekend: true, // is it a formal weekend or not?
      },
      {
        name: 'Monday',
        short: 'Mon',
      },
      {
        name: 'Tuesday',
        short: 'Tue',
      },
      {
        name: 'Wednesday',
        short: 'Wed',
      },
      {
        name: 'Thursday',
        short: 'Thu',
      },
      {
        name: 'Friday',
        short: 'Fri',
      },
      {
        name: 'Saturday',
        short: 'Sat',
        isWeekend: true,
      },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },

    // texts in the date picker
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',

    // for input range value
    from: 'from',
    to: 'to',


    // used for input value when multi dates are selected
    digitSeparator: ',',

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
  }

  const [img, setImg] = useState([]);


  //Equip sell section start
  // sell equip fields and popups states
  const [SellitPopup, setSellitPopup] = useState(false);
  const [SaleitpopupType, setSaleitpopupType] = useState('');

  const [EquipTypeRent, setEquipTypeRent] = React.useState('off');
  const [EquipTypeSale, setEquipTypeSale] = React.useState('off');

  const [EquipType, setEquipType] = React.useState('rent');
  const [Mileage, setMileage] = React.useState('');
  const [Hours, setHours] = React.useState('');

  const [SalePrice, setSalePrice] = React.useState('');
  const [Obo, setObo] = React.useState('off');
  const [Rpo, setRpo] = React.useState('off');

  // by GD for delete popup and functionality
  const [deletePopup, setDeletePopup] = React.useState(false)



  /** commenting by GD
   * Function: viewDetail
   *
   * Description:
   * Fetches detailed information for a specific equipment by its ID.
   * Handles loading states and updates various state variables with fetched data.
   * Additionally, sets up data for pop-ups based on the provided 'type'.
   *
   * Arguments:
   *  id - ID of the equipment to view details.
   *  type - Type of pop-up to display (e.g., "PhotoPopup", "EquipLocation", "EquipPricing", etc.).
   */

  const viewDetail = async (id = null, type = null) => {
    try {

      // console.log("viewDetail type:" ,type)
      setLoadings(true);
      const equipments: IApiResponseRawData = await EquipmentAPIs.viewEquipment(
        {
          id: id,
        }
      );
      // Check if data is available.
      if (equipments.data.rowCount > 0) {
        let viewresult = equipments.data.rows[0][0];

        let new_FIles = [];
        let images = viewresult['images'].split(",")
        images.map((item) => {
          new_FIles.push(item)
        })

        // Update state variables with fetched data.
        setimagearr([])
        setFile(new_FIles)
        setEquipData(viewresult);
        setEquipLocation({
          "lat": viewresult['lat'],
          "lng": viewresult['lng'],
        })

        // Construct full address.
        let full_address = viewresult['address'] ? viewresult['address'] : '' + viewresult['city'] ? ', ' + viewresult['city'] : '' + viewresult['state'] ? ', ' + viewresult['state'] : '' + viewresult['postal_code'] ? ', ' + viewresult['postal_code'] : '';
        setEquipAddress(full_address);

        // Set block dates for availability.
        let block_dates = viewresult["block_dates"];
        let book_dates = ',' + equipments.data.rows[1];

        block_dates = block_dates
        let dates = block_dates.concat(book_dates);

        let block_arr = dates.split(",");
        let mainblockArr = [];

        // Filter and format block dates for display.
        block_arr.map((item, index) => {
          if (item != '' && (item != '0' || item != 0)) {
            let single_date = item.split("-");
            let n_day = day < 10 ? `0${day}` : `${day}`
            let n_month = month < 10 ? `0${month}` : `${month}`
            let today_date = year + '-' + n_month + '-' + n_day;
            let _today = Date.parse(today_date);
            let singleMonth = single_date[1].length <= 1 ? `0${single_date[1]}` : single_date[1];
            let singleDate = single_date[2].length <= 1 ? `0${single_date[2]}` : single_date[2];
            let _block_d = `${single_date[0]}-${singleMonth}-${singleDate}`;
            let block = Date.parse(_block_d);

            if (_today <= block) {
              // let b_dates = { year: parseInt(single_date[0]), month: parseInt(single_date[1]), day: parseInt(single_date[2]), className: 'block_day' }
              let b_dates = { day: parseInt(single_date[2]), month: parseInt(single_date[1]), year: parseInt(single_date[0]) }
              mainblockArr.push(b_dates);
            }
          }
        });

        // Update state variables with block dates.
        setBlockdate(mainblockArr);
        setselectedDates(mainblockArr);

        // Set up data for various pop-ups based on 'type'.
        // Set Photos popup data
        if (type == "PhotoPopup") {
          setEquipGallerypopup(true)
        }
        else if (type == "EquipLocation") {
          setEquiplocationpopup(true)
        }
        else if (type == "EquipPricing") {
          // Set up pricing data.
          setpriceperhour(viewresult['price_per_hour'])
          setpriceperday(viewresult['price_day'])
          setpriceperweek(viewresult['price_per_week'])
          setpricepermonth(viewresult['price_month'])
          // sethourinclude(viewresult['included_hrs_day'])
          // by gd due to def val of included_hrs_day 24
          sethourinclude(viewresult['included_hrs_day'] || '24')
          setEquipPricingpopup(true)
        }
        else if (type == "EquipDetail") {
          // Set up equipment detail data.
          setcategory(viewresult['category'])
          setListingTitle(viewresult['make'])
          setMakemodel(viewresult['model'])
          setYear(viewresult['year'])
          setvinNumber(viewresult['vin_no'])
          setFeature(viewresult['feature'])
          setDescription(viewresult['description'])

          // let len = viewresult['description'].length;
          // by gd due to character left error
          let len = viewresult['description'] ?  viewresult['description'].length : 0 ;

          setpendingDiscriptionChar(1000 - len);
          setEquipDetailpopup(true)
        }
        else if (type == "TransportationPopup") {
          // Set up transportation data.
          setrentpickupreturn(viewresult['renter_pickup_return'] == "1" ? "yes" : "no")
          setownerdeliveryreturn(viewresult['owner_pickup_return'] == "1" ? "yes" : "no")
          setequpheight(viewresult['height'])
          setequpHeightRadio(viewresult['height_unit_type'])
          setequpwidth(viewresult['width'])
          setequpWidthRadio(viewresult['width_unit_type'])
          setequplength(viewresult['length'])
          setequpLengthRadio(viewresult['length_unit_type'])
          setequpweight(viewresult['weight'])
          setmintransportchrg(viewresult['minimum_delivery_charges'])
          setpermiledeliverchrg(viewresult['additional_miles_fee'])
          setpermile(viewresult['free_delivery_miles'])
          setmaximumtransportrange(viewresult['max_miles'])
          setavailableSaturday(viewresult['weekends'] == "1" ? "yes" : "no")
          setavailableSunday(viewresult['sunday'] == "1" ? "yes" : "no")
          setEquipTransportationpopup(true)
        }
        else if (type == "RentalPopup") {
          // Set up rental instruction data.
          setMaintenceServiceText(viewresult['pickup_return_instruction'])
          setWelcomeMessageText(viewresult['welcome_message'])
          setOperatingInstructionText(viewresult['guide'])
          setEquipRentalInstructionpopup(true)
        }
        else if (type == "RentalHistory") {
          // Set up rental history data.
          setEquipRentalHistorypopup(true)
          setPagerMyOrdersHistory(viewresult.slice(0, defaultPageMyOrdersHistory))
        }
        else if (type == "Edit") {
          // Set up data for editing full equipment.
          console.log("viewresult edit: " , viewresult);
          
          setpriceperhour(viewresult['price_per_hour'])
          setpriceperday(viewresult['price_day'])
          setpriceperweek(viewresult['price_per_week'])
          setpricepermonth(viewresult['price_month'])
          // sethourinclude(viewresult['included_hrs_day'])
          // by gd due to def val of included_hrs_day 24
          sethourinclude(viewresult['included_hrs_day'] || '24')
          setcategory(viewresult['category'])
          setListingTitle(viewresult['make'])
          setMakemodel(viewresult['model'])
          setYear(viewresult['year'])
          setvinNumber(viewresult['vin_no'])
          setFeature(viewresult['feature'])
          setDescription(viewresult['description'])

          // let len = viewresult['description'].length;
          // by GD due to error in length
          let len = viewresult['description'] ? viewresult['description'].length : 0;
          setpendingDiscriptionChar(1000 - len);

          // by GD 12 Feb 2024 commented 2 lines and added if else due to error while conevrting an equip from sale to rent and both renter_pickup and owner pickup comes 0 in that condition and one is required to be 1
          // setrentpickupreturn(viewresult['renter_pickup_return'] == "1" ? "yes" : "no")
          // setownerdeliveryreturn(viewresult['owner_pickup_return'] == "1" ? "yes" : "no")
          if(viewresult['renter_pickup_return'] == "0" && viewresult['owner_pickup_return'] == "0"){
             setrentpickupreturn("yes");
             setownerdeliveryreturn("no");
          }else{
            setrentpickupreturn(viewresult['renter_pickup_return'] == "1" ? "yes" : "no");
            setownerdeliveryreturn(viewresult['owner_pickup_return'] == "1" ? "yes" : "no");
          }
          // end by GD
          setequpheight(viewresult['height'])
          setequpHeightRadio(viewresult['height_unit_type'])
          setequpwidth(viewresult['width'])
          setequpWidthRadio(viewresult['width_unit_type'])
          setequplength(viewresult['length'])
          setequpLengthRadio(viewresult['length_unit_type'])
          setequpweight(viewresult['weight'])
          setmintransportchrg(viewresult['minimum_delivery_charges'])
          setpermiledeliverchrg(viewresult['additional_miles_fee'])
          setpermile(viewresult['free_delivery_miles'])
          setmaximumtransportrange(viewresult['max_miles'])
          setavailableSaturday(viewresult['weekends'] == "1" ? "yes" : "no")
          setavailableSunday(viewresult['sunday'] == "1" ? "yes" : "no")

          setMaintenceServiceText(viewresult['pickup_return_instruction'])
          setWelcomeMessageText(viewresult['welcome_message'])
          setOperatingInstructionText(viewresult['guide'])

          // sales fields
          //setEquipType(viewresult['equip_list_type'] == 'both' || viewresult['equip_list_type'] == 'sale' ? 'sale' : '')
          setMileage(viewresult['mileage'])
          setHours(viewresult['hours'])
          setSalePrice(viewresult['equip_sale_price'])
          setObo(viewresult['obo'] == 1 ? "on" : "off")
          setRpo(viewresult['rpo_available'] == 1 ? "on" : "off")

          setEquipTypeRent('on');
          setEquipTypeSale('off');
        }

        // for sale
        else if (type == "sale") {
          // Set up data for sales-related pop-up.

          // setEquipType(viewresult['equip_list_type'] == 'both' || viewresult['equip_list_type'] == 'sale' ? 'sale' : '')
          setMileage(viewresult['mileage'])
          setYear(viewresult['year'])
          setFeature(viewresult['feature'])
          setDescription(viewresult['description'])
          setHours(viewresult['hours'])
          // let len = viewresult['description']?.length;
          // by gd due to length error
          let len = viewresult['description']?.length || 0 ;
          setpendingDiscriptionChar(1000 - len);

          setSalePrice(viewresult['equip_sale_price'])
          setObo(viewresult['obo'] == 1 ? "on" : "off")
          setRpo(viewresult['rpo_available'] == 1 ? "on" : "off")

          // by GD 11 Dec for category , make , and listing titile
          setcategory(viewresult['category']);
          setListingTitle(viewresult['make']);
          setMakemodel(viewresult['model']);
          // end by GD
        }

        // if (viewresult['equip_list_type'] == 'both') {
        //   setEquipTypeRent('on');
        //   setEquipTypeSale('on');
        // } else if (viewresult['equip_list_type'] == 'sale') {
        //   setEquipTypeRent('off');
        //   setEquipTypeSale('on');
        // } else if (viewresult['equip_list_type'] == 'rent') {
        //   setEquipTypeRent('on');
        //   setEquipTypeSale('off');
        // }

        setLoadings(false);

      } else {
        // Set loading state to false if no data is available.
        setLoadings(false);
      }
    } catch (err) {
      // Log and handle unexpected errors.
      console.log(err);
      setLoadings(false);
    }
  };

  /** commenting by GD
   * Function: viewOrders
   *
   * Description:
   * Fetches order details for a specific equipment ID.
   * Handles loading states, updates state variables with fetched data, and filters orders based on status.
   *
   * @param {string} id - ID of the equipment for which order details are fetched.
   * @param {string} token - Authorization token for API request.
  */

  const viewOrders = async (id, token) => {
    try {
      setLoadings(true);
      const headers = { Authorization: `${token}`, request_from: "Web" };
      // Fetch order details for the specified equipment ID.
      const Ordersdetail = await EquipmentAPIs.MyOrder(
        {
          equip_id: id
        }, headers
      );

      // Handle different response scenarios.
      if (Ordersdetail['type'] == "RXSUCCESS") {
        // Extract all orders and update state variables.
        let allOrders = Ordersdetail['data'];
        setMyOrdersHistory(allOrders)
        setPagerMyOrdersHistory(allOrders.slice(0, defaultPageMyOrdersHistory))
        var NewData = [];
        // Filter orders based on specific statuses.
        allOrders.map((item) => {
          item["order_status"] != "completed" &&
            item["order_status"] != "order_canceled" &&
            item["order_status"] != "order_cancelled" &&
            item["order_status"] != "security_settled" &&
            item["order_status"] != "dispute_settled" &&
            NewData.push(item);
        });
         // Update state variables with filtered orders.
        setMyOrders(NewData)
        setMyOrdersPager(NewData.slice(0, defaultMyOrdersPager))
        setLoadings(false);
      }
      else if (Ordersdetail["status"] == false && Ordersdetail["slug"] == "Session Expired") {
        // Handle session expiration.
        setLoadings(false)
        dispatch(setSessionExpire(true));
        return false
      }
      else {
        // Handle other error scenarios.
        setLoadings(false)
        dispatch(setalertMessage(Ordersdetail["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      // Log and handle unexpected errors.
      console.log(err);
      setLoadings(false);
    }
  };

  /** commenting by GD
   * Function: HandleBlockDates
   * Description:
   * Displays the block dates popup and fetches calendar details for the specified equipment.
   * Resets the selectedDates array.
   */
  const HandleBlockDates = () => {
    viewDetail(Ref_EquipId, 'Calendar')
    setaddBlockdates(true)
    setselectedDates([])
  }

  
  const handleDisabledSelect = (disabledDay) => {
    dispatch(setalertMessage('Selected date is disabled.'));
    dispatch(setalertMessagetype('error'));
  };

  // handles dates in calendar in full edit or AVAILABILITY CALENDAR popUps 
  const selectDateHandle = (val) => {
    //setselectedDates(val)

    /*********************/
    setselectedDates(val)
  }

  /** commenting by GD
   * Function: updateBlockDates
   *
   * Description:
   * Updates block dates for a specific equipment.
   * Validates selected dates and formats them before sending the update request to the server.
   * Calls the UpdateEquipe function with the updated block dates.
   */
  const updateBlockDates = () => {

    if (selectedDates.length > 0) {
      let NewDates = [];
      // Format selected dates before updating.
      selectedDates.map((item, index) => {
        var endDay = item.day;
        endDay = endDay > 9 ? endDay : '0' + endDay;
        var endMonth = item.month;
        endMonth = endMonth > 9 ? endMonth : '0' + endMonth;
        var endYear = item.year;
        var orderEnd = moment(endYear + '-' + endMonth + '-' + endDay).format("YYYY-MM-DD");
        NewDates.push(orderEnd)
      })
      // Concatenate formatted dates to create a string for block_dates.
      let blocks = ',' + NewDates.toString();


      //let Previous_bloackDates = EquipData['block_dates'];    
      //let allBlockDates = Previous_bloackDates.concat(blocks);
      let allBlockDates = blocks;

      // Create the body for the update request.
      let body = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        block_dates: allBlockDates
      }
      // Call the UpdateEquipe function with the 'Calendar' type.
      UpdateEquipe(body, 'Calendar')

    } else {
      // Handle the scenario when no dates are selected.
      let NewDates = [];
      selectedDates.map((item, index) => {
        var endDay = item.day;
        endDay = endDay > 9 ? endDay : '0' + endDay;
        var endMonth = item.month;
        endMonth = endMonth > 9 ? endMonth : '0' + endMonth;
        var endYear = item.year;
        var orderEnd = moment(endYear + '-' + endMonth + '-' + endDay).format("YYYY-MM-DD");
        NewDates.push(orderEnd)
      })

      // Create the body for the update request.
      let body = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        block_dates: NewDates.toString()
      }

      // Call the UpdateEquipe function with the 'Calendar' type.
      UpdateEquipe(body, 'Calendar')

      // dispatch(setalertMessage('Please select date for update.'));
      // dispatch(setalertMessagetype('error'));
    }
  }

  /**
   * Function: HandleEquipLocation
   *
   * Description:
   * Initiates the process to view equipment location details and opens the equipment location pop-up.
   * Calls the viewDetail function with the 'EquipLocation' type and the equipment ID.
   * Additionally, sets the 'EquipLocation' pop-up to be visible.
   */
  const HandleEquipLocation = () => {
    // Call the viewDetail function to fetch and view equipment location details.
    viewDetail(Ref_EquipId, 'EquipLocation')
    // Set the 'EquipLocation' pop-up to be visible.
    setEquiplocationpopup(true)
  }

  /** commenting by GD
   * Function: getFullAddress
   *
   * Description:
   * Retrieves the full address details for a given latitude and longitude using the Geocode API.
   * Updates state variables with the fetched address components.
   *
   * @param {Object} center - Object containing latitude (lat) and longitude (lng) values.
   */
  // Get city state country address etc from geocode
  const getFullAddress = async (center) => {
    if (center.lat != null && center.lng != null) {
      // Use the Geocode API to fetch address details based on latitude and longitude.
      Geocode.fromLatLng(center.lat, center.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country, short_state, postal_code;
          // Extract address components from the Geocode API response.
          for (let i = 0; i < response.results[0].address_components.length; i++) {
            for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {

              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  short_state = response.results[0].address_components[i].short_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
                // case "administrative_area_level_1":
                //   short_state = response.results[0].address_components[i].short_name;                
                //   break;
                case "postal_code":
                  postal_code = response.results[0].address_components[i].short_name;
                  break;
              }
            }
          }
          if(!postal_code) {
            const addressData = response.results.find(address => address.types.includes("postal_code"));
            const postalCodeArr = addressData?.address_components?.find(address => !isNaN(+address.short_name));
            postal_code = postalCodeArr?.short_name || postalCodeArr?.long_name || "";
          }
          // Construct a full address object.
          let full_address = {
            "address": address,
            "city": city,
            "state": state,
            "short_state": short_state,
            "postal_code": postal_code,
            "country": country,
            "lat": center.lat,
            "lng": center.lng,
          };
           // Update state variable with the full address.
          setFullAddress(full_address)
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      // Handle the scenario when latitude or longitude is not provided.
      setFullAddress({
        "address": "",
        "city": "",
        "state": "",
        "short_state": "",
        "postal_code": "",
        "country": "",
        "lat": "",
        "lng": "",
      })
    }
  }

  /** commenting by GD
   * Function: updateLocation
   *
   * Description:
   * Validates and updates the location details of a specific equipment.
   * Sends the update request to the server with the provided location details.
   * Displays an alert message if required fields are not filled.
   */
  // Update Equip Location  
  const updateLocation = () => {
    // Display an alert message if latitude or longitude is not provided.
    if (FullAddress.lat == '' || FullAddress.lat == null || FullAddress.lng == '' || FullAddress.lng == null) {
      dispatch(setalertMessage('Please fill equip location for update!'));
      dispatch(setalertMessagetype('error'));
      return false;
    }
    // Check if latitude and longitude are provided.
    if (FullAddress.lat != "" && FullAddress.lng != "") {
      let body = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        address: FullAddress.address || "",
        city: FullAddress.city || "",
        country: FullAddress.country || "",
        lat: FullAddress.lat,
        lng: FullAddress.lng,
        short_state_name: FullAddress.short_state || "",
        state: FullAddress.state || "",
        postal_code: FullAddress.postal_code || "",
      }
      // Call the UpdateEquipe function with the 'Location' type.
      UpdateEquipe(body, 'Location')
    }
    else {
      // If latitude or longitude is not provided, use existing equipment data.
      let body = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        address: EquipData['address'],
        city: EquipData['city'],
        country: EquipData['country'],
        lat: EquipData['lat'],
        lng: EquipData['lng'],
        short_state_name: EquipData['short_state'],
        state: EquipData['state'],
        postal_code: EquipData['postal_code'],
      }
      // Call the UpdateEquipe function with the 'Location' type.
      UpdateEquipe(body, 'Location')
      // dispatch(setalertMessage('please fill address for update equip location.'));
      // dispatch(setalertMessagetype('error'));
    }
  }
  /** commenting by GD
   * Function: HandleEquipPricing
   * Description:
   * Initiates the process to view equipment pricing details and opens the equipment pricing pop-up.
   * Calls the viewDetail function with the 'EquipPricing' type and the equipment ID.
   * Additionally, sets the 'EquipPricing' pop-up to be visible.
   */
  const HandleEquipPricing = () => {
    // setpriceperhour(EquipData['price_per_hour'])
    // setpriceperday(EquipData['price_day'])
    // setpriceperweek(EquipData['price_per_week'])
    // setpricepermonth(EquipData['price_month'])
    // sethourinclude(EquipData['included_hrs_day'])
    // 
    viewDetail(Ref_EquipId, 'EquipPricing')
    setEquipPricingpopup(true)
  }
   /** commenting by GD
   * Functions: PriceperHour, PriceperDay, PriceperWeek, PriceperMonth, HourInclude
   * Description:
   * this functions are used for set data for EQUIPMENT PRICING and Add equipment for rent popUps.
   */
  const PriceperHour = (event) => {
    let val = event.target.value
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpriceperhour(val)
    }
    // setpriceperhour(event.target.value)
  }
  const PriceperDay = (event) => {
    let val = event.target.value
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpriceperday(val)
    }
    // setpriceperday(event.target.value)
  }
  const PriceperWeek = (event) => {
    let val = event.target.value
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpriceperweek(val)
    }
    // setpriceperweek(event.target.value)
  }
  const PriceperMonth = (event) => {
    let val = event.target.value
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpricepermonth(val)
    }
    // setpricepermonth(event.target.value)
  }
  const HourInclude = (event) => {
    // if (event.target.value > 0 && event.target.value <= 24) {
    if (parseInt(event.target.value) > 0 && parseInt(event.target.value) <= 24) {
      sethourinclude(event.target.value)
    } else {
      if (event.target.value == '') {
        sethourinclude(event.target.value)
      } else {
        dispatch(setalertMessage('Invalid selected hours.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
    }
  }

  /** commenting by GD
   * Function: updatePricing
   * Description:
   * Validates and updates the pricing details of a specific equipment.
   * Sends the update request to the server with the provided pricing details.
   * Displays an alert message if any required fields are not filled or if pricing relationships are not valid.
   */
  const updatePricing = () => {
    try {
      // Validate and retrieve price per hour.
      var _priceperhour = priceperhour;
      if(_priceperhour && parseFloat(_priceperhour) === 0) {
        dispatch(setalertMessage("Price per hour can't be zero."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      if (_priceperhour == '' || _priceperhour == null) {
        dispatch(setalertMessage('Please enter price per hour.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Validate and retrieve price per day.
      var _priceperday = priceperday;
      if (_priceperday == '' || _priceperday == null) {
        dispatch(setalertMessage('Please enter price per day.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Ensure that day price is greater than hour price.
      if (parseFloat(_priceperhour) >= parseFloat(_priceperday)) {
        dispatch(setalertMessage('Day price must be greater than hour price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Validate and retrieve price per week.
      var _priceperweek = priceperweek;
      if (_priceperweek == '' || _priceperweek == null) {
        dispatch(setalertMessage('Please enter price per week.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Ensure that week price is greater than day price.
      if (parseFloat(_priceperday) >= parseFloat(_priceperweek)) {
        dispatch(setalertMessage('Week price must be greater than day price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Validate and retrieve price per month.
      var _pricepermonth = pricepermonth;
      if (_pricepermonth == '' || _pricepermonth == null) {
        dispatch(setalertMessage('Please enter price per month.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Ensure that month price is greater than week price.
      if (parseFloat(_priceperweek) >= parseFloat(_pricepermonth)) {
        dispatch(setalertMessage('Month price must be greater than week price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Validate and retrieve included hours per day.
      var _hourinclude = hourinclude.trim();
      if (_hourinclude == '' || _hourinclude == null) {
        dispatch(setalertMessage('Please enter per day included hours.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Ensure that included hours per day are within a valid range.
      if (Number(_hourinclude) > 24) {
        dispatch(setalertMessage('Please enter valid per day included hours.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // Create the body for the update request.
      let body = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        price_per_hour: _priceperhour,
        price_day: _priceperday,
        price_per_week: _priceperweek,
        price_month: _pricepermonth,
        included_hrs_day: _hourinclude
      }

      // Call the UpdateEquipe function with the 'Rental' type.
      UpdateEquipe(body, 'Rental')
    } catch (err) {
      console.log(err);
      setLoadings(false);
      setaddBlockdates(false)
    }
  }

  /** commenting by gd
   * Function: HandleEquipGalley
   * Description:
   * Initiates the process to view the photo gallery of a specific equipment.
   * Resets the file state and fetches the equipment details using the viewDetail function with the 'PhotoPopup' type.
   * Additionally, sets the 'EquipGallerypopup' to be visible.
   */
  const HandleEquipGalley = () => {
    // Reset the file state.
    setFile([])
    // Call the viewDetail function to fetch and view equipment details for the photo gallery.
    viewDetail(Ref_EquipId, 'PhotoPopup')
    // setFile(EquipData['images'].split(","))
    // setThumbimage(EquipData['images'].split(",").indexOf(EquipData['thumbnail']))

    // Set the 'EquipGallerypopup' to be visible.
    setEquipGallerypopup(true)
  }

  /** commenting by gd
   * Function: HandleEquipDetail
   * Description:
   * Initiates the process to view and edit the details of a specific equipment.
   * Fetches the equipment details using the viewDetail function with the 'EquipDetail' type.
   * Additionally, sets the 'EquipDetailpopup' to be visible.
   */
  const HandleEquipDetail = () => {
    
    // setcategory(EquipData['category'])
    // setListingTitle(EquipData['make'])
    // setMakemodel(EquipData['model'])
    // setYear(EquipData['year'])
    // setvinNumber(EquipData['vin_no'])
    // setFeature(EquipData['feature'])
    // setDescription(EquipData['description'])

    // let len = EquipData['description'].length;
    // setpendingDiscriptionChar(1000 - len);

    // Call the viewDetail function to fetch and view equipment details.
    viewDetail(Ref_EquipId, 'EquipDetail')
    // Set the 'EquipDetailpopup' to be visible.
    setEquipDetailpopup(true)

  }
  
  /** commenting by GD
   * Function: LoadCategories
   * Description:
   * Fetches all equipment categories asynchronously and sets the retrieved categories in the state.
   * Handles loading states during the API call.
   */
  // Get All categories
  const LoadCategories = async () => {
    try {
      setLoadings(true)
      // Fetch all equipment categories.
      const categoriesRes: IApiResponseRawData = await EquipmentAPIs.getAllCategories();

      // Check if the API call was successful.
      if (categoriesRes.result == 'success') {
        setLoadings(false);
        // Set the retrieved categories in the state.
        setAllCategories(categoriesRes.data.rows);
      } else {
        // Set loading state to false in case of an unsuccessful API call.
        setLoadings(false)
      }
    } catch (err) {
      // Set loading state to false in case of an error during the API call.
      setLoadings(false)
      console.log(err);
    }
  }
  
  // EQUIPMENT DETAILS
   /** commenting by GD
   * Functions: changeCategory, HandlelistingTitle, Handlemakemodel, HandleYear, HandleVinNumber, HandleFeature
   * Description:
   * handles values for EQUIPMENT DETAILS and full edit popup opened form edit this listing button
   */
  const changeCategory = (event) => {
    setcategory(event.target.value)
  }
  const HandlelistingTitle = (event) => {
    setListingTitle(event.target.value?.replace(/\//g, ''))
  }
  const Handlemakemodel = (event) => {
    setMakemodel(event.target.value?.replace(/\//g, ''))
  }

  const HandleYear = (event) => {
    setYear(event.target.value)
  }

  const HandleVinNumber = (event) => {
    setvinNumber(event.target.value)
  }

  const HandleFeature = (event) => {
    setFeature(event.target.value)
  }

  /**n commenting by GD
   * Function: HandleDescription
   * Description:
   * Handles the input change event for the equipment description.
   * Updates the description state based on the entered value.
   * Limits the description length to 300 characters and updates the character counter.
   */
  const HandleDescription = (event) => {
    let val = event.target.value
    let len = val.length;

    if (len > 1000) {
      let remove_char = len - 1000;
      val = val.substring(0, val.length - remove_char);
      setpendingDiscriptionChar(0);
      setDescription(val);
    } else {
      setpendingDiscriptionChar(1000 - len);
      setDescription(val);
    }
  }

  /** commenting by GD
   * Function: updateEquipmentDescription
   * Description:
   * Updates the equipment details including category, listing title, make, model, year,
   * VIN number, features, and description.
   * Validates input fields before making the update request.
   * Displays error messages for invalid input.
  */
  const updateEquipmentDescription = () => {
    var _category = category.trim();
    if (_category == '' || _category == null) {
      dispatch(setalertMessage('Please select Category to continue.'));
      dispatch(setalertMessagetype('error'));
      return false
    }

    var _handlelistingTitle = ListingTitle.trim();
    if (_handlelistingTitle == '' || _handlelistingTitle == null) {
      dispatch(setalertMessage('Please add listing title.'));
      dispatch(setalertMessagetype('error'));
      return false
    }

    let body = {
      equip_id: Ref_EquipId.toString(),
      user_id: UserID.toString(),
      category: category,
      make: ListingTitle,
      model: Makemodel,
      year: Year,
      vin_no: vinNumber,
      feature: Feature,
      description: Description,
    }
    // Make the update request with type Equipment
    UpdateEquipe(body, 'Equipment')
  }

  /** commenting by GD
   * Function: handleImageChange
   *
   * Description:
   * Handles the change event when multiple images are selected.
   * Validates the number of images and types before updating state.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   */
  // Select multiple images
  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    // Check if files are selected.
    if (fileList.length > 0) {
      setLoadings(true)
      var result = Object.entries(fileList);
      
      // Check if the total number of images exceeds the limit.
      // Add on 19 Jan 2024 by MK total number of images upload change 6 to 20
      if (result.length > 20 || (file.length + result.length) > 20) {
        setLoadings(false)
        dispatch(setalertMessage('you can upload only 20 images.'));
        dispatch(setalertMessagetype('error'));
        return false
      } else {
        // if (file.length > 0) {
        //   var arr = [...file, ...result]
        //   console.log('object=======>',arr);
        //   setFile(arr)
        // } else {
        //   console.log('=======>>>RESULT', result)
        //   console.log('objectPURANA++=======>',file);
        if (imagearr.length > 0) {
          var arr = [...imagearr, ...result]
          setimagearr(arr)
        } else {
          setimagearr(result);
        }
        // }

        let upload_files = [...file];

         // Check if the file type is an image.
        for (let index = 0; index < result.length; index++) {
          if (e.target.files[index].type == 'image/jpg' || e.target.files[index].type == 'image/jpeg' || e.target.files[index].type == 'image/png') {
            var fileLoaded = URL.createObjectURL(e.target.files[index]);
            upload_files.push(fileLoaded);
          }
        }

        // Update state with the modified arrays.
        setFile(upload_files);
        setLoadings(false)
      }
    }
  };

  /** commenting by GD
   * Function: removeImage
   * Description:
   * Removes an image from the file array and updates the state.
   * Handles the removal of both local and server-side images.
   * Validates the index before attempting removal.
   * @param {string} fileName - Name of the file to be removed.
   * @param {number} i - Index of the file to be removed.
  */
  const removeImage = (fileName, i) => {
    // Create a copy of the file and imagearr arrays.
    var myArray = [...file];
    var _myarray = [...imagearr];

    // Find the index of the file in the array.
    const index = myArray.indexOf(fileName);

    /******************/
    // Filter out local images from myArray.
    let new_A = []
    if (myArray.length > 0) {
      myArray.map((filename, i) => {
        if (filename.indexOf('blob:http') > -1) {
        } else {
          new_A.push(filename);
        }
      });
    }

    // console.log('upload',_myarray);
    // console.log('File',myArray);

    // Handle removal based on the adjusted index.
    if (i >= new_A.length) {
      var new_indx = i - new_A.length;

      _myarray.splice(new_indx, 1);
      myArray.splice(i, 1);
    } else {
      myArray.splice(i, 1);
    }

    /*************/

    // Update state with the modified arrays.
    if (index > -1) {
      setThumbimage(i == Thumbimage ? 0 : Thumbimage > i ? 0 : 0)
      // _myarray.splice(index, 1);
      //  myArray.splice(index, 1);
    }
    setFile(myArray)
    setimagearr(_myarray)

  }

  /** commenting by GD
   * Function: SaveFile
   * Description:
   * Uploads images one by one, saving their paths to the server.
   * @param {number} i - The index of the current image being processed.
   * @param {Array} upload_files_path - An array to store the paths of uploaded images.
   * @param {number} total - The total number of images to upload.
   * @param {Function} completion - A callback function to be executed after all images are uploaded.
   */
  const SaveFile = async (i = 0, upload_files_path, total, completion) => {

    let imgObg = imagearr[i];
    let file_obj = imgObg[1];

    // Check if a file is present.
    if (file_obj) {
      try {
        const formData = new FormData();
        formData.append('image', file_obj);
        formData.append('name', file_obj.name);

        let access_token = AuthToken;
        const headers = {
          "Authorization": `${access_token}`,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          request_from: "Web"
        };

        // Upload the image to the server.
        const upload_Equip_file: IApiResponseRawData = await EquipmentAPIs.uploadEquipImage(
          formData,
          headers
        );

        if (upload_Equip_file['status'] == 200) {
          // console.log("in upload if: " , upload_Equip_file);
          
          var uploaded_path = upload_Equip_file['data']['image'];
          upload_files_path.push(uploaded_path);

           // Check if all images are uploaded.
          if (total - 1 == i) {
          // console.log("in completion true if");
            completion(true)
            return
          } else {
            // Recursively call SaveFile for the next image.
            await SaveFile(i + 1, upload_files_path, total, completion)
          }
          //return;
        } else if (upload_Equip_file['status'] == false) {
          dispatch(setalertMessage(upload_Equip_file['slug']));
          dispatch(setalertMessagetype('error'));
          setLoadings(false)
          return false
        } else {
          dispatch(setalertMessage(upload_Equip_file['message']));
          dispatch(setalertMessagetype('error'));
          //  return 'false'
        }
      } catch (err) {
        dispatch(setalertMessage(err));
        dispatch(setalertMessagetype('error'));
        setLoadings(false)
        // return 'false'
      }
    }

  }

  /** commenting by GD
   * Function: uploadfiles
   * Description:
   * Uploads equipment images. It checks if there are selected files, then processes and saves them.
   * If imagearr has files, it uses the SaveFile function to upload them and updates the equipment images.
   * If no files are selected, it displays an error message.
   */
  const uploadfiles = async () => {
    if (file.length > 0) {
      setLoadings(true)
      let upload_files_path = [];

      // Filter out blob URLs from the file array
      if (file.length > 0) {
        file.map((filename, i) => {
          if (filename.indexOf('blob:http') > -1) {
          } else {
            upload_files_path.push(filename);
          }
        });
      }
      // console.log('imagearr',imagearr);
      if (imagearr.length > 0) {

        SaveFile(0, upload_files_path, imagearr.length, (success) => {
          //console.log("Completed.......")

          let _array = [...upload_files_path]

          // Set the primary image as the thumbnail
          var primaryImage = _array[Thumbimage];
          _array.splice(Thumbimage, 1);
          let equipImages = [primaryImage, ..._array]

          let body = {
            equip_id: Ref_EquipId.toString(),
            user_id: UserID.toString(),
            images: equipImages.toString(),
            thumbnail: primaryImage.toString()
          }

          // Reset Equip Gallery Popup
          setEquipGallerypopup(false)
          // console.log('body', body);
          setLoadings(false)
          setimagearr([])
          UpdateEquipe(body, 'Photos')
          //}

        });

      } else {

        // No additional images, use the selected files
        var primaryImage = file[Thumbimage];
        file.splice(Thumbimage, 1);
        let equipImages = [primaryImage, ...file]

        let body = {
          equip_id: Ref_EquipId.toString(),
          user_id: UserID.toString(),
          images: equipImages.toString(),
          thumbnail: primaryImage.toString()
        }

        // Reset Equip Gallery Popup
        setEquipGallerypopup(false)

        setLoadings(false)
        UpdateEquipe(body, 'Photos')
      }
    }

    else {
      dispatch(setalertMessage('Please select image first for upload.'));
      dispatch(setalertMessagetype('error'));
      return false
    }
  }

  /** commenting by GD
   * Function: Rentpickupreturn
   * Description:
   * Handles the change event for the rent pickup/return option.
   * Displays an alert message if both rent pickup/return and owner delivery/return are set to 'no'.
   * Sets the rent pickup/return option based on the event target value.
   */
  const Rentpickupreturn = (event) => {
    //setrentpickupreturn(event.target.value)

    // Check if both rent pickup/return and owner delivery/return are set to 'no'
    if (event.target.value == 'no' && ownerdeliveryreturn == 'no') {
      dispatch(setalertMessage('One Transport option must be yes'));
      dispatch(setalertMessagetype('error'));
      return false;
      //setownerdeliveryreturn('yes')
    } else {
      // Set the rent pickup/return option based on the event target value
      setrentpickupreturn(event.target.value)
    }
  }

  /**
   * Function: HandleOwnerDeliveryReturn
   * Description:
   * Handles the change event for the owner delivery/return option.
   * Displays an alert message if both owner delivery/return and rent pickup/return are set to 'no'.
   * Sets the owner delivery/return option based on the event target value.
   */
  const HandleOwnerDeliveryReturn = (event) => {
    //setownerdeliveryreturn(event.target.value)    
    // Check if both owner delivery/return and rent pickup/return are set to 'no'
    if (event.target.value == 'no' && rentpickupreturn == 'no') {
      dispatch(setalertMessage('One Transport option must be yes'));
      dispatch(setalertMessagetype('error'));
      return false;
      //setrentpickupreturn('yes')
    } else {
      // Set the owner delivery/return option based on the event target value
      setownerdeliveryreturn(event.target.value)
    }
  }
  
   /** commenting by GD
   * Functions: EqupHeight, EqupHeightRadio, EqupWidth, EqupWidthRadio, EqupLength, EqupLengthRadio, EqupWeight, 
   * HandleMinTransportChrg , PerMileDeliverChrg, PerMile , MaximumTransportRange , AvailableSaturday
   * AvailableSunday , 
   * Description:
   * handles values for TransportationPopup and full edit popup opened form edit this listing button
   */
  const EqupHeight = (event) => {
    var val = event.target.value;
    if (!isNaN(val)) {
      setequpheight(val)
    }
  }
  const EqupHeightRadio = (event) => {
    setequpHeightRadio(event.target.value)
  }
  const EqupWidth = (event) => {
    var val = event.target.value;
    if (!isNaN(val)) {
      setequpwidth(val)
    }
  }
  const EqupWidthRadio = (event) => {
    setequpWidthRadio(event.target.value)
  }
  const EqupLength = (event) => {
    var val = event.target.value;
    if (!isNaN(val)) {
      setequplength(val)
    }
  }
  const EqupLengthRadio = (event) => {
    setequpLengthRadio(event.target.value)
  }
  const EqupWeight = (event) => {
    var val = event.target.value;
    if (!isNaN(val)) {
      setequpweight(val)
    }
  }
  const HandleMinTransportChrg = (event) => {
    var val = event.target.value;
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setmintransportchrg(val);
    }
    // if (!isNaN(val)) {
    //   setmintransportchrg(val)
    // }
  }
  const PerMileDeliverChrg = (event) => {
    var val = event.target.value;
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpermiledeliverchrg(val)
    }
    // if (!isNaN(val)) {
    //   setpermiledeliverchrg(val)
    // }
  }
  const PerMile = (event) => {
    var val = event.target.value;
    if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
      setpermile(val)
    }
    // if (!isNaN(val)) {
    //   setpermile(val)
    // }
  }
  const MaximumTransportRange = (event) => {
    var val = event.target.value;
    if (!isNaN(val)) {
      setmaximumtransportrange(val)
    }
  }

  const AvailableSaturday = (event) => {
    setavailableSaturday(event.target.value)
  }
  const AvailableSunday = (event) => {
    setavailableSunday(event.target.value)
  }

  /** commenting by gd
   * Function: OpenTransportationPopup
   * Description:
   * Opens the transportation popup and fetches details for the specified equipment.
   */
  const OpenTransportationPopup = (event) => {
    // Fetch details for the specified equipment and open the transportation popup
    viewDetail(Ref_EquipId, 'TransportationPopup')
    setEquipTransportationpopup(true)
  }

  /**
   * Function: updateTransportation
   * Description:
   * Updates transportation details for the specified equipment. Validates input fields based on the selected options.
   * Calls the UpdateEquipe function to perform the update.
   */
  const updateTransportation = () => {
    // by GD  added if for checking rent pickupreturn fields validations only if rentpickupreturn is selected
    if(rentpickupreturn == "yes"){
      var _height = equpheight;
      if (_height == '' || _height == null) {
        dispatch(setalertMessage('Please add height.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      var _width = equpwidth;
      if (_width == '' || _width == null) {
        dispatch(setalertMessage('Please add width.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      var _equplength = equplength;
      if (_equplength == null) {
        dispatch(setalertMessage('Please add length.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      var _equpweight = equpweight;
      if (_equpweight == '' || _equpweight == null) {
        dispatch(setalertMessage('Please add weight.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
    }
    
    // Validate input fields if owner delivery/return is selected
    if (ownerdeliveryreturn == "yes") {
      var _mintransportchrg = mintransportchrg;
      if (_mintransportchrg == '' || _mintransportchrg == null) {
        dispatch(setalertMessage('Please enter minimum transport charges to continue.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      var _permiledeliverchrg = permiledeliverchrg;
      if (_permiledeliverchrg == '' || _permiledeliverchrg == null) {
        dispatch(setalertMessage('Please enter per-mile delivery charge begin after.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      var _permile = permile;
      if (_permile == '' || _permile == null) {
        dispatch(setalertMessage('Please enter charge for per mile.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      var _maximumtransportrange = maximumtransportrange;
      if (_maximumtransportrange == '' || _maximumtransportrange == null) {
        dispatch(setalertMessage('Please enter maximum transport range.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
    }

    let body = {
      equip_id: Ref_EquipId.toString(),
      user_id: UserID.toString(),
      renter_pickup_return: rentpickupreturn == "yes" ? "1" : "0",
      owner_pickup_return: ownerdeliveryreturn == "yes" ? "1" : "0",
      height: equpheight.toString(),
      height_unit_type: equpHeightRadio,
      width: equpwidth.toString(),
      width_unit_type: equpWidthRadio,
      length: equplength.toString(),
      length_unit_type: equpLengthRadio,
      weight: equpweight.toString(),
      minimum_delivery_charges: ownerdeliveryreturn == "yes" ? mintransportchrg.toString() : "0",
      additional_miles_fee: ownerdeliveryreturn == "yes" ? permiledeliverchrg.toString() : "0",
      free_delivery_miles: ownerdeliveryreturn == "yes" ? permile.toString() : "0",
      max_miles: ownerdeliveryreturn == "yes" ? maximumtransportrange.toString() : "0",
      weekends: availableSaturday == "yes" ? "1" : "0",
      sunday: availableSunday == "yes" ? "1" : "0",
    }

    // Call the UpdateEquipe function to perform the update
    UpdateEquipe(body, 'Transportation')
  }

  /** commenting by GD
   * Function: OpenRentalPopup
   * Description:
   * Opens the rental popup, fetching details for the specified equipment.
   */ 
  const OpenRentalPopup = () => {
    // setMaintenceServiceText(EquipData['pickup_return_instruction'])
    // setWelcomeMessageText(EquipData['welcome_message'])
    // setOperatingInstructionText(EquipData['guide'])
    // 
    // Fetch equipment details for the specified equipment ID
    viewDetail(Ref_EquipId, 'RentalPopup')
    
    // Set the state to open the rental instruction popup
    setEquipRentalInstructionpopup(true)
  }

  const HandleDamageLiabilityText = (event) => {
    setDamageLiabilityText(event.target.value)
  }
  const HandleMaintenceServiceText = (event) => {
    setMaintenceServiceText(event.target.value)
  }
  const HandleWelcomeMessageText = (event) => {
    setWelcomeMessageText(event.target.value)
  }
  const HandleOperatingInstructionText = (event) => {
    setOperatingInstructionText(event.target.value)
  }

  /** commenting by GD
   * Function: UpdateRentalInstruction
   * Description:
   * Updates the rental instructions for a particular equipment.
   */
  const UpdateRentalInstruction = () => {
    let body = {
      equip_id: Ref_EquipId.toString(),
      user_id: UserID.toString(),
      pickup_return_instruction: MaintenceServiceText,
      welcome_message: WelcomeMessageText,
      guide: OperatingInstructionText
    }
    UpdateEquipe(body, 'RentalInstructions')
  }

  /** commenting by GD
   * Function: HandleRentalHistory
   * Description:
   * Opens the rental history popup for a specific equipment and fetches the rental history.
   */
  const HandleRentalHistory = () => {
    viewOrders(Ref_EquipId.toString(), AuthToken)
    //viewDetail(Ref_EquipId, 'RentalHistory')
    setEquipRentalHistorypopup(true)
  }

  /** commenting by GD
   * Function: UpdateEquipe
   * Description:
   * Asynchronous function to update equipment information based on the specified type.
   * @param {Object} body - The data payload containing information to be updated.
   * @param {string} type - The type of update operation (e.g., 'Calendar', 'Location', 'Rental', etc.).
   * @returns {Promise<boolean>} - A Promise indicating the success or failure of the update operation.
   */

  const UpdateEquipe = async (body, type = null) => {
    try {
      setLoadings(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipUpdate = await EquipmentAPIs.UpdateBlockDates(
        body, headers
      );

      // Check if the update operation was successful
      if (EquipUpdate['type'] == "RXSUCCESS") {
        setLoadings(false)

        // Display success message based on the update type
        if (type == 'Calendar') {
          dispatch(setalertMessage('Calendar Availability Updated Successfully.'));
        } else if (type == 'Location') {
          dispatch(setalertMessage('Equipment Location Updated Successfully.'));
        } else if (type == 'Rental') {
          dispatch(setalertMessage('Rental Pricing Updated Successfully.'));
        } else if (type == 'Photos') {
          dispatch(setalertMessage('Photos Updated Successfully.'));
        } else if (type == 'Equipment') {
          dispatch(setalertMessage('Equipment Updated Successfully.'));
        } else if (type == 'Transportation') {
          dispatch(setalertMessage('Transportation Prices Updated Successfully.'));
        } else if (type == 'RentalInstructions') {
          dispatch(setalertMessage('Rental Instructions Updated Successfully.'));
        } else if (type == 'FullEdit') {
          dispatch(setalertMessage('Equipment Updated Successfully.'));
          // by gd for redirecting
          if( ListEquipType == 'sale'){
            UpdateTab({ 'status': 'success', 'type': 'rent' })
          };
        } else if (type == 'SalesEdit') {
          dispatch(setalertMessage('Sales Equipment Updated Successfully.'));
          setSellitPopup(false)
          // by GD on 4Dec for redirection
          UpdateTab({ 'status': 'success', 'type': 'sale' })
        }

        // Store the equipment ID in local storage for reference
        localStorage.setItem("EquipID", body['equip_id']);

        // Trigger success handling function
        OnSuccess('success')

        // let equip_list_type = ''
        // if (EquipTypeSale == 'on' && EquipTypeRent == 'on') {
        //   equip_list_type = 'both'
        // } else if (EquipTypeSale == 'on' && EquipTypeRent == 'off') {
        //   equip_list_type = 'sale'
        // } else if (EquipTypeSale == 'off' && EquipTypeRent == 'on') {
        //   equip_list_type = 'rent'
        // }
        // UpdateTab({ 'status': 'success', 'type': equip_list_type })

        // Dispatch a success alert message
        dispatch(setalertMessagetype('success'));
        setaddBlockdates(false)
        setselectedDates([])

        //reset data and popup        
        // reset Full Address
        setFullAddress({
          "address": "",
          "city": "",
          "state": "",
          "short_state": "",
          "postal_code": "",
          "country": "",
          "lat": "",
          "lng": "",
        });
        setEquiplocationpopup(false)

        // reset Equip Price section       
        setEquipPricingpopup(false)

        // reset Equip Detail section
        setEquipDetailpopup(false)

        // // Reset Equip Gallet Popup
        // setEquipGallerypopup(false)

        // Reset Equipm Transport popup
        setEquipTransportationpopup(false)

        setThumbimage(0)
        // Reset Rental Instruction section 
        setEquipRentalInstructionpopup(false)

        setEquipAllDatapopup(false)

        //Handle redirct
        if (body.equip_list_type == 'both' || body.equip_list_type == 'rent') {
          localStorage.setItem("EquipType", 'rent');
          // window.location.reload();
        }

      }
      else if (EquipUpdate["status"] == false && EquipUpdate["slug"] == "Session Expired") {
        // Handle session expiration case
        
        setLoadings(false)
        dispatch(setSessionExpire(true));
        return false
      }
      else {
        // Handle other error cases
        setLoadings(false)
        dispatch(setalertMessage(EquipUpdate["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      // else if (EquipUpdate['type'] == "RXERROR") {
      //   setLoadings(false);
      //   dispatch(setalertMessage(EquipUpdate['message']));
      //   dispatch(setalertMessagetype('error'));
      // } else {
      //   setLoadings(false);
      //   setaddBlockdates(false)
      // }
    } catch (err) {
      // Handle unexpected errors
      console.log(err);
      setLoadings(false);
      setaddBlockdates(false)
    }
  }

  // const HandleMyOrder = () => {
  //   setLoadings(true);
  //   setTimeout(() => {
  //     orderSCroll?.current.scrollTo({ top: 1500, left: 0, behavior: 'smooth' });
  //   }, 150);
  //   let total = Number(MyOrders.length);
  //   let pagerlen = Number(MyOrdersPager.length);
  //   let new_len = Number(pagerlen) + Number(defaultMyOrdersPager);
  //   if (new_len > total) {
  //     setMyOrdersPager(MyOrders.slice(0, total))
  //   } else {
  //     setMyOrdersPager(MyOrders.slice(0, new_len))
  //   }
  //   setLoadings(false);
  // }

  /** commenting by GD
   * Function: HandleMyOrderHistory
   * Description:
   * Handles the action for loading additional items in the My Order History section.
   * Scrolls to a specific position and updates the pager to load more items.
   */
  const HandleMyOrderHistory = () => {
    setLoadings(true);
    setTimeout(() => {
      orderHistoryScroll?.current.scrollTo({ top: 1500, left: 0, behavior: 'smooth' });
    }, 150);
    let total = Number(MyOrdersHistory.length);
    let pagerlen = Number(PagerMyOrdersHistory.length);
    let new_len = Number(pagerlen) + Number(defaultPageMyOrdersHistory);

    // Get the current pager length and default page value
    if (new_len > total) {
      setPagerMyOrdersHistory(MyOrdersHistory.slice(0, total))
    } else {
      setPagerMyOrdersHistory(MyOrdersHistory.slice(0, new_len))
    }

    // Reset loading state upon completion
    setLoadings(false);
  }

  /** commenting by GD
   * Function: HandleOrderDetail
   * Description:
   * Handles the action for displaying detailed information about a specific order.
   * @param {Object} orderObj - The order object containing details about the order.
   */
  const HandleOrderDetail = async (orderObj) => {
    // Close the Rental History popup and open the Order Detail popup
    setEquipRentalHistorypopup(false)
    setOrderDetailpopup(true)

     // Extract relevant information from the order object
    let ex_orders = orderObj['order_extend'];
    setActiveOrder([]);
    if (ex_orders != null) {
      setActiveOrder(ex_orders);
    }
    let user_info = {
      "profile_pic": orderObj['profile_pic'] ? orderObj['profile_pic'] : Img,
      "first_name": orderObj['first_name'] ? orderObj['first_name'] : 'NA',
    }
    setUserInfo(user_info);


    // Calculate various values for display in the Order Detail popup
    let devilry = orderObj['delivery_charges'] * 2;
    let minimun = orderObj['minimum_delivery_fees'];
    let totalMi = devilry + minimun;
    let _changeInTotal = (totalMi / 100) * 10;

    let returnDepo =
      orderObj['returnd_deposit'] != undefined ||
        orderObj['returnd_deposit'] != null
        ? orderObj['returnd_deposit']
        : null;

    let rent_price = orderObj['price'];

    let security_depo = returnDepo == orderObj['security_deposit']
      ? 0
      : returnDepo == null
        ? orderObj['security_deposit']
        : orderObj['security_deposit'] - returnDepo

    let transport_fees = 0;
    if (orderObj['delivery_charges'] != undefined &&
      orderObj['delivery_charges'] != null &&
      orderObj['delivery_charges'] != "0") {
      transport_fees = orderObj['delivery_charges'] * 2 + orderObj['minimum_delivery_fees'];
    }

    let refunded_protection =
      orderObj['refunded_protection'] != undefined ||
        orderObj['refunded_protection'] != ""
        ? orderObj['refunded_protection']
        : "";

    let protection_fees = orderObj['protection_fees_refunded'] == "1"
      ? orderObj['equipment_protection_fees'] -
      (refunded_protection == ""
        ? orderObj['equipment_protection_fees']
        : refunded_protection)
      : orderObj['equipment_protection_fees'];

    let grand_total = orderObj['protection_fees_refunded'] != "1"
      ? orderObj['admin_commission'] != null
        ? orderObj['total_price']
        : orderObj['total_price']
      : orderObj['admin_commission'] != null
        ? orderObj['total_price'] -
        (orderObj['order_status'] == "order_canceled"
          ? orderObj['security_deposit']
          : 0)
        : orderObj['total_price'];

    let admin_fee = orderObj['admin_commission'] +
      (orderObj['minimum_delivery_fees'] != undefined &&
        orderObj['minimum_delivery_fees'] != null &&
        orderObj['minimum_delivery_fees'] != "0" &&
        orderObj['delivery_address'] != "" &&
        parseFloat(
          orderObj['admin_transport_commission']
        ));

    // Set the data for the Order Detail popup
    setOrderDetailPopData({
      "id": orderObj['id'],
      "make": orderObj['make'] ? orderObj['make'] : 'NA',
      "model": orderObj['model'] ? orderObj['model'] : '',
      "start_date": orderObj['start_date'],
      "start_time": orderObj['start_time'],
      "end_date": orderObj['end_date'],
      "end_time": orderObj['end_time'],
      "profile_pic": orderObj['profile_pic'] ? orderObj['profile_pic'] : Img,
      "first_name": orderObj['first_name'] ? orderObj['first_name'] : 'NA',
      "order_status": orderObj['order_status'],
      "order_from": orderObj['delivery_address'] == "" ? "Renter" : "Owner",
      "rental_price": rent_price,
      "minimum_delivery_fees": (orderObj['minimum_delivery_fees']),
      "security_deposit_refund_txt": orderObj['returnd_deposit'] != undefined ||
        orderObj['returnd_deposit'] != null
        ? "(Refunded" +
        "-$" +
        new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }).format(orderObj['returnd_deposit'])

        +
        ")"
        : "",
      "security_deposit": security_depo,
      "grand_total_txt": orderObj['order_status'] == "order_canceled" ? "(Refunded)" : "",
      "grand_total": grand_total,
      "protection_fee": protection_fees,
      "transport_fee": transport_fees,
      "admin_fee": admin_fee,
    });
  }

  /** commenting by GD
   * Function: HandleOrderStatusPopup
   * Description:
   * Handles the action for displaying the order status popup based on the order status.
   * @param {string} order_id - The order ID.
   * @param {string} user_id - The user ID associated with the order.
   * @param {string} order_status - The current order status.
   * @param {number} refund_security - The refund amount for the security deposit (if applicable).
   */
  const HandleOrderStatusPopup = (order_id, user_id, order_status, refund_security) => {
    if (order_status == "returned") {
      // Display refund security popup for returned orders
      setRefundSecurityamt(refund_security)
      setRefundSecurityamount(0)
      setRefundSecurityamtreson("")
      setRefundSecuritypopup(true)
    } else {

      // Display order status change popup for other order statuses
      setOrderStatusChange(true)
      setOrderStatusObj({
        order_id: order_id,
        renter_id: user_id,
        order_status: order_status
      });
    }
  }

  /** commenting by GD
   * Function: HandleorderStatus
   * Description:
   * Handles the order status update based on the specified order status and performs necessary actions.
   */
  // Update order status
  const HandleorderStatus = async () => {
    try {
      setLoadings(true);
      let order_id = OrderStatusObj.order_id;
      let renter_id = OrderStatusObj.renter_id;
      let order_status = OrderStatusObj.order_status;
      let title = '';

      // Set the title based on the order status
      if (order_status == "payment_complete") {
        title = "Pickup";
      } else if (order_status == "pickup") {
        title = "Return";
      } else if (order_status == "returned") {
        title = "";
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };

      // hit the api to get data
      const OrderStatus = await EquipmentAPIs.UpdateOrderStatus(
        {
          user_id: UserID,
          order_id: order_id.toString(),
          owner_id: UserID.toString(),
          renter_id: renter_id.toString(),
          title: title,
        }, headers
      );

      if (OrderStatus['type'] == "RXSUCCESS") {

        // Reset state and update order details
        setOrderStatusChange(false)
        viewDetail(Ref_EquipId)
        dispatch(setalertMessage(OrderStatus['message']));
        dispatch(setalertMessagetype('success'));

        // Refresh the order history
        viewOrders(Ref_EquipId, AuthToken)
      }
      else if (OrderStatus["status"] == false && OrderStatus["slug"] == "Session Expired") {
        setLoadings(false)
        dispatch(setSessionExpire(true));
        return false
      }
      else {
        setLoadings(false);
        setOrderStatusChange(false)
        dispatch(setalertMessage(OrderStatus['message']));
        dispatch(setalertMessagetype('error'));

        // Reset order status object
        setOrderStatusObj({
          order_id: "",
          renter_id: "",
          order_status: ""
        });
      }
    } catch (err) {
      // Handle errors, if any
      setLoadings(false);
      setOrderStatusChange(false)

      // Reset order status object
      setOrderStatusObj({
        order_id: "",
        renter_id: "",
        order_status: ""
      });
    }
  }

  // handle refund security Reson
  const HandleSecurityReson = (event) => {
    var val = event.target.value;
    setRefundSecurityamtreson(val);
  }

  // Handle Refund amount
  const HandleRefundAmount = (event) => {
    var val = event.target.value;
    // if (val > RefundSecurityamt) {
    //   dispatch(setalertMessage("Refund amount cannot be greater than security deposit."));
    //   dispatch(setalertMessagetype('error'));   
    //   return false;
    // } else {
    setRefundSecurityamount(val);
    //}
  }

  // Refund Order Security
  const securityRefund = async () => {
    try {
      setLoadings(true);
      let ordersecurity = RefundSecurityamt;
      let amount = RefundSecurityamount;
      let resion = RefundSecurityamtreson;

      // if (amount == null) {
      //   dispatch(setalertMessage("Please fill security amount."));
      //   dispatch(setalertMessagetype('error'));
      //   setLoadings(false);
      //   return false;
      // }

      if (amount > ordersecurity) {
        dispatch(setalertMessage('Refund amount cannot be greater than security deposit.'));
        dispatch(setalertMessagetype('error'));
        setLoadings(false);
        return false;
      } else if (amount < ordersecurity && resion == "") {
        dispatch(setalertMessage("Please enter the reason."));
        dispatch(setalertMessagetype('error'));
        setLoadings(false);
        return false;
      } else {
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const RefundStatus = await EquipmentAPIs.RefundSecurity(
          {
            owner_id: UserID.toString(),
            order_id: orderID.toString(),
            description: resion.toString(),
            amount: amount.toString()
          }, headers
        );

        if (RefundStatus['type'] == "RXSUCCESS") {
          dispatch(setalertMessage('$ ' + amount + ' Security refunded successfully.'));
          dispatch(setalertMessagetype('success'));
          viewOrders(Ref_EquipId, AuthToken)
          setRefundSecurityamount(0)
          setRefundSecurityamtreson("")
          setLoadings(false);
          setRefundSecuritypopup(false)
        }
        else if (RefundStatus["status"] == false && RefundStatus["slug"] == "Session Expired") {
          setLoadings(false)
          dispatch(setSessionExpire(true));
          return false
        }
        else {
          setOrderStatusChange(false)
          dispatch(setalertMessage(RefundStatus['message']));
          dispatch(setalertMessagetype('error'));
          setorderID(null)
          setLoadings(false);
        }
      }
    } catch (err) {
      setLoadings(false);
    }
  }


  /** commenting by GD
   * Function: HandleEditpopup
   * Description:
   * Opens the edit popup and fetches details for the specified equipment.
   */
  const HandleEditpopup = () => {
    // Fetch details for the specified equipment and open the edit popup
    // viewDetail(Ref_EquipId, 'Edit')
    // setEquipAllDatapopup(true)
    // setselectedDates([])
    // setFile([])
    // by GD 20 Feb 2024
    setFile([]);
    setselectedDates([]);
    viewDetail(Ref_EquipId, 'Edit');
    setEquipAllDatapopup(true);
    // by GD for default selection of EquipTypeRent on click of (Edit this listing button) in NewRentalDetail
    // setEquipTypeRent('on');
    // setEquipTypeSale('off');
    // setSaleitpopupType('rent');
  }

  /** commenting by GD
   * Function: EditUpdateEquipData
   * Description:
   * Handles the update of equipment data based on user input.
   */
  //  fxn used for full edit in rental yard and add a equipmnt for rent from sales yard 
  const EditUpdateEquipData = async () => {
    // console.log("files in update: " , file);

    // Initialize an empty object to store block date information
    var blockDate = {};
    //Block Date Section
    if (EquipTypeRent == 'on') {

      // Check if selectedDates array has items
      if (selectedDates.length > 0) {
        let NewDates = [];

        // Format selected dates and push to NewDates array
        selectedDates.map((item, index) => {
          var endDay = item.day;
          endDay = endDay > 9 ? endDay : '0' + endDay;
          var endMonth = item.month;
          endMonth = endMonth > 9 ? endMonth : '0' + endMonth;
          var endYear = item.year;
          var orderEnd = moment(endYear + '-' + endMonth + '-' + endDay).format("YYYY-MM-DD");
          NewDates.push(orderEnd)
        })

        // Create a string of blocked dates separated by commas
        let blocks = ',' + NewDates.toString();
        let allBlockDates = blocks;

        // Populate blockDate object with relevant information
        blockDate = {
          equip_id: Ref_EquipId.toString(),
          user_id: UserID.toString(),
          block_dates: allBlockDates
        }

      } else {

        // Handle the case when no dates are selected
        let NewDates = [];
        selectedDates.map((item, index) => {
          var endDay = item.day;
          endDay = endDay > 9 ? endDay : '0' + endDay;
          var endMonth = item.month;
          endMonth = endMonth > 9 ? endMonth : '0' + endMonth;
          var endYear = item.year;
          var orderEnd = moment(endYear + '-' + endMonth + '-' + endDay).format("YYYY-MM-DD");
          NewDates.push(orderEnd)
        })

        // Populate blockDate object with relevant information
        blockDate = {
          equip_id: Ref_EquipId.toString(),
          user_id: UserID.toString(),
          block_dates: NewDates.toString()
        }
      }
    }

    // Equipe Location Section
    if (FullAddress.lat == '' || FullAddress.lat == null || FullAddress.lng == '' || FullAddress.lng == null) {
      dispatch(setalertMessage('Please fill equip location for update!'));
      dispatch(setalertMessagetype('error'));
      return false;
    }

    // Initialize an object to store equipment location address
    var EquiplocationAddress = {}
    if (FullAddress.lat != "" && FullAddress.lng != "") {
      // Populate EquiplocationAddress with the provided location information
      EquiplocationAddress = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        address: FullAddress.address,
        city: FullAddress.city,
        country: FullAddress.country,
        lat: FullAddress.lat,
        lng: FullAddress.lng,
        short_state_name: FullAddress.short_state,
        state: FullAddress.state,
        postal_code: FullAddress.postal_code,
      }
    }
    else {
      // Populate EquiplocationAddress with the existing equipment location information
      EquiplocationAddress = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        address: EquipData['address'],
        city: EquipData['city'],
        country: EquipData['country'],
        lat: EquipData['lat'],
        lng: EquipData['lng'],
        short_state_name: EquipData['short_state'],
        state: EquipData['state'],
        postal_code: EquipData['postal_code'],
      }
    }

    //Equip Pricing Section
    var EquipPricingSectionData = {}
    if (EquipTypeRent == 'on') {
      // Validate and retrieve pricing information for equipment rental
      var _priceperhour = priceperhour;
      if(_priceperhour && parseFloat(_priceperhour) === 0) {
        dispatch(setalertMessage("Price per hour can't be zero."));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      if (_priceperhour == '' || _priceperhour == null) {
        dispatch(setalertMessage('Please enter price per hour.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      // Additional pricing validations...
      var _priceperday = priceperday;
      if (_priceperday == '' || _priceperday == null) {
        dispatch(setalertMessage('Please enter price per day.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      if (parseFloat(_priceperhour) >= parseFloat(_priceperday)) {
        dispatch(setalertMessage('Day price must be greater than hour price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      var _priceperweek = priceperweek;
      if (_priceperweek == '' || _priceperweek == null) {
        dispatch(setalertMessage('Please enter price per week.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      if (parseFloat(_priceperday) >= parseFloat(_priceperweek)) {
        dispatch(setalertMessage('Week price must be greater than day price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      var _pricepermonth = pricepermonth;
      if (_pricepermonth == '' || _pricepermonth == null) {
        dispatch(setalertMessage('Please enter price per month.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      if (parseFloat(_priceperweek) >= parseFloat(_pricepermonth)) {
        dispatch(setalertMessage('Month price must be greater than week price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      var _hourinclude = hourinclude;
      console.log("hourinclude: " , hourinclude);
      
      if (_hourinclude == null || _hourinclude == '' || _hourinclude.trim() == '') {
        dispatch(setalertMessage('Please enter per day included hours.'));
        dispatch(setalertMessagetype('error'));
        return false
      }
      if (Number(_hourinclude) > 24) {
        dispatch(setalertMessage('Please enter valid per day included hours.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // set data of pricing section
      EquipPricingSectionData = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        price_per_hour: _priceperhour,
        price_day: _priceperday,
        price_per_week: _priceperweek,
        price_month: _pricepermonth,
        included_hrs_day: _hourinclude
      }
    }

    // Equip Gallery Section
    var EquipGallerySectionData = {}

    if (file.length > 0) {
      setLoadings(true)
      let upload_files_path = [];

      // Additional gallery handling...
      if (file.length > 0) {
        file.map((filename, i) => {
          if (filename.indexOf('blob:http') > -1) {
          } else {
            upload_files_path.push(filename);
          }
        });
      }
      // console.log('imagearr',imagearr);
      if (imagearr.length > 0) {
        
       await SaveFile(0, upload_files_path, imagearr.length, (success) => {
          // console.log("Completed.......")
          let _array = [...upload_files_path]
          var primaryImage = _array[Thumbimage];
          _array.splice(Thumbimage, 1);
          let equipImages = [primaryImage, ..._array]

          EquipGallerySectionData = {
            equip_id: Ref_EquipId.toString(),
            user_id: UserID.toString(),
            images: equipImages.toString(),
            thumbnail: primaryImage.toString()
          }
          // console.log("EquipGallerySectionData in saveFile: " , EquipGallerySectionData);

          setEquipGallerypopup(false)
          setLoadings(false)
          setimagearr([])

        });

      } else {

        var primaryImage = file[Thumbimage];
        file.splice(Thumbimage, 1);
        let equipImages = [primaryImage, ...file];
        setFile(equipImages)

        // Set EquipGallerySectionData with relevant gallery information...
        EquipGallerySectionData = {
          equip_id: Ref_EquipId.toString(),
          user_id: UserID.toString(),
          images: equipImages.toString(),
          thumbnail: primaryImage.toString()
        }

        // Reset Equip Gallery Popup
        setEquipGallerypopup(false)
        setLoadings(false)
      }
    }
    else {
      // Display an alert message if no image is selected
      dispatch(setalertMessage('Please select image first for upload.'));
      dispatch(setalertMessagetype('error'));
      return false
    }

    //Equipment Description Section
    var EquipDescriptionSectionData = {}
    if (EquipTypeRent == 'on') {

      // Validate and retrieve equipment description information
      // var _category = category.trim();
      var _category = category;
      if (_category == null || _category == '' || _category.trim() == '') {
        dispatch(setalertMessage('Please select Category to continue.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // var _handlelistingTitle = ListingTitle.trim();
      var _handlelistingTitle = ListingTitle;
      if (_handlelistingTitle == null || _handlelistingTitle == '' || _handlelistingTitle.trim() == '') {
        dispatch(setalertMessage('Please add listing title.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // set data for api request
      EquipDescriptionSectionData = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        category: category,
        make: ListingTitle,
        model: Makemodel,
        year: Year,
        vin_no: vinNumber,
        feature: Feature,
        description: Description,
      }
    }

    // Equipment Transport Section
    var EquipTransportionSectionData = {}
    if (EquipTypeRent == 'on') {
    // by GD added if for rentpickupreturn fields check to validate only if "Renter pick up and return?" is selected "yes"
      if (rentpickupreturn == "yes") {
        var _height = equpheight;
        if (_height == '' || _height == null) {
          dispatch(setalertMessage('Please add height.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
        var _width = equpwidth;
        if (_width == '' || _width == null) {
          dispatch(setalertMessage('Please add width.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
        var _equplength = equplength;
        if (_equplength == null) {
          dispatch(setalertMessage('Please add length.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
        var _equpweight = equpweight;
        if (_equpweight == '' || _equpweight == null) {
          dispatch(setalertMessage('Please add weight.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
      } 

      // only to check validations if owner delivery return is selected
      if (ownerdeliveryreturn == "yes") {
        var _mintransportchrg = mintransportchrg;
        if (_mintransportchrg == '' || _mintransportchrg == null) {
          dispatch(setalertMessage('Please enter minimum transport charges to continue.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
        var _permiledeliverchrg = permiledeliverchrg;
        if (_permiledeliverchrg == '' || _permiledeliverchrg == null) {
          dispatch(setalertMessage('Please enter per-mile delivery charge begin after.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
        var _permile = permile;
        if (_permile == '' || _permile == null) {
          dispatch(setalertMessage('Please enter charge for per mile.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
        var _maximumtransportrange = maximumtransportrange;
        if (_maximumtransportrange == '' || _maximumtransportrange == null) {
          dispatch(setalertMessage('Please enter maximum transport range.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
      }

      // Set EquipTransportionSectionData with relevant transport information...
      EquipTransportionSectionData = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        renter_pickup_return: rentpickupreturn == "yes" ? "1" : "0",
        owner_pickup_return: ownerdeliveryreturn == "yes" ? "1" : "0",
        height: equpheight.toString(),
        height_unit_type: equpHeightRadio,
        width: equpwidth.toString(),
        width_unit_type: equpWidthRadio,
        length: equplength.toString(),
        length_unit_type: equpLengthRadio,
        weight: equpweight.toString(),
        minimum_delivery_charges: ownerdeliveryreturn == "yes" ? mintransportchrg.toString() : "0",
        additional_miles_fee: ownerdeliveryreturn == "yes" ? permiledeliverchrg.toString() : "0",
        free_delivery_miles: ownerdeliveryreturn == "yes" ? permile.toString() : "0",
        max_miles: ownerdeliveryreturn == "yes" ? maximumtransportrange.toString() : "0",
        weekends: availableSaturday == "yes" ? "1" : "0",
        sunday: availableSunday == "yes" ? "1" : "0",
      }

      console.log("EquipTransportionSectionData : " , EquipTransportionSectionData);
      // return
      
    }
    // Equipment Rental Instruction Section
    var EquipRentalInstructionSectionData = {}
    if (EquipTypeRent == 'on') {

      EquipRentalInstructionSectionData = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        pickup_return_instruction: MaintenceServiceText,
        welcome_message: WelcomeMessageText,
        guide: OperatingInstructionText
      }
    }

    // Sales fields 
    let salefileds = {}
    if (EquipTypeSale == 'on') {

      let sale_year = Year;
      let sale_feature = Feature;
      let sale_mileage = Mileage;
      let sale_hours = Hours;
      let sale_description = Description;

      if (sale_year == '') {
        dispatch(setalertMessage('Please add year.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      if (sale_feature == '') {
        dispatch(setalertMessage('Please add equipment feature.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // if (sale_mileage == '') {
      //   dispatch(setalertMessage('Please add mileage.'));
      //   dispatch(setalertMessagetype('error'));
      //   return false
      // }

      // if (sale_hours == '') {
      //   dispatch(setalertMessage('Please add hours.'));
      //   dispatch(setalertMessagetype('error'));
      //   return false
      // }

      if (sale_description == '') {
        dispatch(setalertMessage('Please add equipment description.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      var _priceperhour = SalePrice;
      if (_priceperhour == '' || _priceperhour == null) {
        dispatch(setalertMessage('Please enter asking price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // set data for sales fields for the api
      salefileds = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),

        year: sale_year, // this field is used sale and rent both
        feature: sale_feature, // this field is used sale and rent both                
        mileage: sale_mileage ? sale_mileage : null,
        hours: sale_hours ? sale_hours : null,
        description: sale_description ? sale_description : null, // this field is used sale and rent both               
        //equip_list_type: EquipType,
        // Pricing options
        equip_sale_price: _priceperhour,
        obo: Obo == "on" ? 1 : 0,
        rpo_available: Rpo == "on" ? 1 : 0,
      }
    }

    //check equip_list_type
    let equip_list_type = {};
    // by GD comment start due to updating of equip_list_type updating
    // if (EquipTypeSale == 'on' && EquipTypeRent == 'on') {
    //   equip_list_type = { equip_list_type: 'both' }
    // } else if (EquipTypeSale == 'on' && EquipTypeRent == 'off') {
    //   equip_list_type = { equip_list_type: 'sale' }
    // } else if (EquipTypeSale == 'off' && EquipTypeRent == 'on') {
    //   equip_list_type = { equip_list_type: 'rent' }
    // }
    // end by GD comment end

    // by gd for equip_list_type including in body
    console.log('ListEquipType in edit: ' , ListEquipType);
    if(ListEquipType == 'sale'){
      equip_list_type = { equip_list_type: 'both' }
    }
    // end by GD

    // final body data for api request
    var bodyData = { ...blockDate, ...EquiplocationAddress, ...EquipPricingSectionData, ...EquipGallerySectionData, ...EquipDescriptionSectionData, ...EquipTransportionSectionData, ...EquipRentalInstructionSectionData, ...salefileds, ...equip_list_type }
    
    console.log('bodyDatabodyData', bodyData);
    // console.log('EquipGallerySectionData', EquipGallerySectionData);
    // return

    // hit updateEquip api with type fullEdit
    UpdateEquipe(bodyData, 'FullEdit')
  }

  /**
   * Executes the specified function when the `EditClick` dependency changes.
   */
  // Check full info edit button clicked or not
  useEffect(() => {
    if (EditClick) {
      HandleEditpopup()
    }
  }, [EditClick]);

  //Executes the specified function when the `Ref_EquipId` dependency changes.
  useEffect(() => {
    // Fetch details for the specified equipment and load categories
    viewDetail(Ref_EquipId, ListEquipType)
    LoadCategories()
  }, [Ref_EquipId]);


  // Sell it section start

  /** commenting by GD
   * Function: HandleSellitPopup
   * Description:
   * Opens the sell popup and sets the equipment type based on the 'ListEquipType'.
   * Fetches details for the specified equipment and opens the edit popup.
   * Resets selected dates and files.
   * @param {string} type - The type of equipment ('sale' or 'rent'). Default is null.
   */
  const HandleSellitPopup = (type = null) => {
    //setSellitPopup(true)
    //setEquipType(type)
    // Set default sale and rent type based on 'ListEquipType'
    // by GD for default set sale and rent type
    if (ListEquipType == 'sale') {
      setEquipTypeRent('on');
      setEquipTypeSale('off')
    } else {
      setEquipTypeRent('off');
      setEquipTypeSale('on')
    }
    // end by GD

    // Fetch details for the specified equipment and open the edit popup
    viewDetail(Ref_EquipId, 'Edit')
    setEquipAllDatapopup(true)
    setselectedDates([])
    setFile([])
  }


  /**
   * Function: deleteEquipment
   * Description:
   * Deletes the specified equipment and handles success or error scenarios.
   * If successful, closes the delete popup and triggers a refresh on the other tab.
   */ 
  const deleteEquipment = async () => {
    try {
      // Prepare data for equipment deletion
      let data = {
        equip_id: Ref_EquipId.toString(),
        user_id: UserID.toString(),
        equip_list_type: ListEquipType == 'sale' ? 'sale' : 'rent'
      }
      // console.log("delete data : " , data);

      // Call the API to delete the equipment
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const equipments = await EquipmentAPIs.DeleteEquip(
        data, headers
      );
      // console.log('equipments', equipments);

      // Check if the deletion was successful
      if (equipments['type'] == 'RXSUCCESS') {
        setDeletePopup(false)

        // for refresh data on the other tab
        // if(ListEquipType == 'sale' && equipType == 'both'){
        //   // UpdateTab({ 'status': 'success', 'type': 'sale' })
        //   console.log('in delete function');
        //   reloadTabUpdate();
        // }else if ( equipType == 'both'){
        //   console.log('in delete function second if');
        //   reloadTabUpdate();
        // }
       // reloadTabUpdate();
        // end
        // Trigger a refresh on the other tab
        OnSuccess('success')
      } else {
        // Display an error message if deletion fails
        dispatch(setalertMessage('error while deleting equipment.'));
        dispatch(setalertMessagetype('error'));
        setDeletePopup(false)
      }

    } catch (err) {

      // Handle errors that occur during the deletion process
      console.log(err);
      dispatch(setalertMessage('error while deleting equipment.'));
      dispatch(setalertMessagetype('error'));
      setDeletePopup(false)
    }

  }

  // by GD for reset sales fields from popup in rental yard 
  const handleSalesStatesReset = () => {
    // setYear('');
    // setFeature('');
    setMileage('');
    setHours('');
    setSalePrice('');
    setObo('');
    setRpo('');
    // setDescription('');
  }
  // end by GD

  // fxn for milage set of an equipment when listing for sales
  const HandleMileage = (event) => {
    var val = event.target.value;
    val = val.replace(/\D/g, "");

    //if (val === '' || /^\d+(\.\d{0,2})?$/.test(val)) {
    setMileage(val)
    //}
  }

  // fxn for hours set of an equipment when listing for sales
  const HandleHours = (event) => {
    var val = event.target.value;
    val = val.replace(/\D/g, "");
    setHours(val)
  }

  /** commenting by GD
   * Function: updateSalesEquip
   * Description:
   * Updates sales-related information for the specified equipment.
   * Validates input fields and handles different update scenarios based on SaleitpopupType.
   */
  const updateSalesEquip = () => {

    // Extract sale-related information from input fields
    let sale_year = Year;
    let sale_feature = Feature;
    let sale_mileage = Mileage;
    let sale_hours = Hours;
    let sale_description = Description;

    // Check if SaleitpopupType is 'both'
    if (SaleitpopupType == "both") {
      // Validate required fields for both sale and rent

      // if (sale_year == '') {
      //   dispatch(setalertMessage('Please add year.'));
      //   dispatch(setalertMessagetype('error'));
      //   return false
      // }

      // if (sale_feature == '') {
      //   dispatch(setalertMessage('Please add equipment feature.'));
      //   dispatch(setalertMessagetype('error'));
      //   return false
      // }

      // if (sale_description == '') {
      //   dispatch(setalertMessage('Please add equipment description.'));
      //   dispatch(setalertMessagetype('error'));
      //   return false
      // }

      var _priceperhour = SalePrice;
      if (_priceperhour == '' || _priceperhour == null) {
        dispatch(setalertMessage('Please enter asking price.'));
        dispatch(setalertMessagetype('error'));
        return false
      }

      // if(Obo == 'off' && Rpo == 'off') {
      //   dispatch(setalertMessage('Please select one of obo or rpo.'));
      //   dispatch(setalertMessagetype('error'));
      //   return false
      // }

    } else {

      // Check if SaleitpopupType is 'pricing'
      if (SaleitpopupType == "pricing") {
        var _priceperhour = SalePrice;
        if (_priceperhour == '' || _priceperhour == null) {
          dispatch(setalertMessage('Please enter asking price.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
      } else {
        // by GD 11Dec for category , makeModel and listing title
        var _category = category.trim();
        if (_category == '' || _category == null) {
          dispatch(setalertMessage('Please select Category to continue.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
    
        var _handlelistingTitle = ListingTitle.trim();
        if (_handlelistingTitle == '' || _handlelistingTitle == null) {
          dispatch(setalertMessage('Please add listing title.'));
          dispatch(setalertMessagetype('error'));
          return false
        }
      //  end by GD
        // if (sale_year == '') {
        //   dispatch(setalertMessage('Please add year.'));
        //   dispatch(setalertMessagetype('error'));
        //   return false
        // }

        // if (sale_feature == '') {
        //   dispatch(setalertMessage('Please add equipment feature.'));
        //   dispatch(setalertMessagetype('error'));
        //   return false
        // }

        // if (sale_mileage == '') {
        //   dispatch(setalertMessage('Please add mileage.'));
        //   dispatch(setalertMessagetype('error'));
        //   return false
        // }

        // if (sale_hours == '') {
        //   dispatch(setalertMessage('Please add hours.'));
        //   dispatch(setalertMessagetype('error'));
        //   return false
        // }

        // if (sale_description == '') {
        //   dispatch(setalertMessage('Please add equipment description.'));
        //   dispatch(setalertMessagetype('error'));
        //   return false
        // }
      }
    }


    // Prepare data for equipment update
    let bodyData: any = {
      equip_id: Ref_EquipId.toString(),
      user_id: UserID.toString(),

      year: sale_year ? sale_year : null, // this field is used sale and rent both
      feature: sale_feature ? sale_feature : null, // this field is used sale and rent both                
      mileage: sale_mileage ? sale_mileage : null,
      hours: sale_hours ? sale_hours : null,
      description: sale_description ? sale_description : null, // this field is used sale and rent both               
      //equip_list_type: EquipType,
      // Pricing options
      equip_sale_price: _priceperhour,
      obo: Obo == "on" ? 1 : 0,
      rpo_available: Rpo == "on" ? 1 : 0,
    }

    // Check SaleitpopupType and include equip_list_type accordingly
    if (SaleitpopupType == 'both') {
      bodyData.equip_list_type = 'both'
    }else if(SaleitpopupType == 'details' && ListEquipType == 'sale'){
      // console.log("SaleitpopupType else: " , bodyData);
      // by GD elseif added due to adding category , make , model on equip details edit from sales yard
       bodyData.category = category;
       bodyData.make = ListingTitle;
       bodyData.model = Makemodel;
    }

    console.log("bodyData from editDetails: " , bodyData);
    
    // Call the function to update the equipment
    UpdateEquipe(bodyData, 'SalesEdit')
  }

  // fxn for salesPrice set of an equipment when listing for sales
  const HandleSalePrice = (event) => {
    let val = event.target.value
    if (val === '' || /^\d+(\.\d{0,10})?$/.test(val)) {
      setSalePrice(val)
    }
  }

  // fxn for OBO set of an equipment when listing for sales
  const HandleOBO = (event) => {
    // let chk_status = event.target.value;
    // if (chk_status == 'off') {
    //   setObo('on')
    // } else {
    //   setObo('off')
    // }

    let val = event.target.checked;
    if (val) {
      setObo("on");
    } else {
      setObo("off");
    }

  }

  // fxn for RPO set of an equipment when listing for sales
  const HandleRPO = (event) => {
    // let chk_status = event.target.value;
    // if (chk_status == 'off') {
    //   setRpo('on')
    // } else {
    //   setRpo('off')
    // }

    let val = event.target.checked;
    if (val) {
      setRpo("on");
    } else {
      setRpo("off");
    }
  }

  const HandleRent = (event) => {
    let chk_status = event.target.value;
    if (chk_status == 'off') {
      setEquipTypeRent('on')
    } else {
      setEquipTypeRent('off')
    }
  }

  const HandleSale = (event) => {
    let chk_status = event.target.value;
    if (chk_status == 'off') {
      setEquipTypeSale('on')
    } else {
      setEquipTypeSale('off')
    }
  }

  //Equip sell section End

  return (
    <>
      {/* Main section */}
      <Box className="edit-listing-options" style={{ cursor: "pointer" }}>
        {/* check from which of sales or rental yard editEquipment is rendered . ListEquipType == 'sale' is for sales yard  */}
        {ListEquipType == 'sale' ? (
          <>
            <Box className="edit-listing-options-item">
              <Link onClick={() => { setSellitPopup(true); setSaleitpopupType('details') }}>Equipment Details</Link>
              <Link onClick={() => { setSellitPopup(true); setSaleitpopupType('pricing') }}>Equipment Pricing</Link>
              <Link onClick={HandleEquipLocation}>Equipment Location</Link>
            </Box>
            <Box className="edit-listing-options-item">
              <Link onClick={HandleEquipGalley}>Equipment Photos</Link>
              {/* {EquipData['list_equip_type'] == 'sale' && ( */}

              {/* by GD for hiding rent it option when equipType is both */}
              {
                (equipType != 'both') &&
                <Link onClick={HandleSellitPopup}>Rent It</Link>
              }
              {/* by gd for delete equipment functionality */}
              {/* <Link onClick={() => {
                setDeletePopup(true);
              }}>Delete Equipment</Link> */}
              {/* end by GD */}

              {/* <Link onClick={HandleSellitPopup}>Rent It</Link> */}
              {/* )} */}
            </Box>
            <Box></Box>
            <Box className="edit-listing-options-item">
              <Link
                className="delete-eqp"
                sx={{ color: '#ff0000 !important', textDecorationColor :'#ff0000 !important', display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              onClick={() => {
                //console.log("clicked on delete equipment");
                setDeletePopup(true);
                }}>X Delete Equipment </Link>
            </Box>
          </>
        ) : (
          <>
            <Box className="edit-listing-options-item">
              <Link onClick={HandleBlockDates}>Availability Calendar</Link>
              <Link onClick={HandleEquipLocation}>Equipment Location</Link>
              <Link onClick={HandleEquipPricing}>Pricing & Quantity</Link>
              <Link onClick={HandleEquipDetail}>Equipment Details</Link>
              <Link onClick={OpenTransportationPopup}>
                Transport Pricing & Specs
              </Link>
            </Box>
            <Box className="edit-listing-options-item">
              <Link onClick={OpenRentalPopup}>Rental Instructions</Link>
              <Link onClick={HandleEquipGalley}>Photos</Link>
              <Link onClick={HandleRentalHistory}>Rental History</Link>
              <Link
                onClick={() => {
                  // dispatch(setalertMessage("Upcoming feature"));
                  // dispatch(setalertMessagetype("info"));
                  // history.push("/myGps")
                  window.location.href = '/myGps';
                }}
              >Add GPS</Link>
              {/* {EquipData['list_equip_type'] == 'rent' && ( */}
              {/* <Link onClick={HandleSellitPopup}>Sell It</Link> */}
              {/* by GD for hiding sell it when equipType is both */}
              {(equipType != 'both') &&
                <Link onClick={async() => {
                  setSaleitpopupType('both');
                  setSellitPopup(true);
                  await viewDetail(Ref_EquipId, 'sale');
                  // handleSalesStatesReset();

                }}>Sell It</Link>
              }
              {/* by gd for delete equipment functionality */}
              {/* <Link onClick={() => {
                setDeletePopup(true);
              }}>Delete Equipment</Link> */}
              {/* end by GD */}
              {/* )} */}
            </Box>
            <Box></Box>
            <Box className="edit-listing-options-item">
              <Link
                className="delete-eqp"
                sx={{ color: '#ff0000 !important', textDecorationColor :'#ff0000 !important', display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              onClick={() => {
                //console.log("clicked on delete equipment");
                setDeletePopup(true);
                }}>X Delete Equipment </Link>
            </Box>
          </>
        )}

      </Box>

      {/* Block date section */}
      {/* commenting by GD
        * Description:
        * Renders a modal for selecting and blocking dates on the availability calendar.
      */}
      <Modal
        keepMounted
        open={addBlockdates}
        onClose={() => setaddBlockdates(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setaddBlockdates(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner cal-pop">
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  AVAILABILITY CALENDAR
                </Typography>

                <Typography className="" sx={{ color: "#fff !important", margin: "1rem 0" }}>
                  Select the dates below you want to block out making the
                  equipment unavailable to rent for those dates.
                </Typography>

                <Box>
                  {!loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box className="myot-cal" style={{ display: !addBlockdates ? "none" : "block" }}>

                    <Calendar
                      locale={myCustomLocale}
                      value={selectedDates}
                      onChange={(event) => {
                        selectDateHandle(event);
                      }}
                      minimumDate={minimumDate}
                      calendarClassName="responsive-calendar"
                      calendarTodayClassName="custom-today-day"
                    //   customDaysClassName={blockdate}
                    // disabledDays={blockdate}
                    // onDisabledDayError={handleDisabledSelect}
                    />
                  </Box>

                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      updateBlockDates();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Location */}
      <Modal
        keepMounted
        open={Equiplocationpopup}
        onClose={() => setEquiplocationpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquiplocationpopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
              //component="form"
              // onSubmit={updatePricing}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Edit EQUIPMENT LOCATION
                </Typography>
                <Typography sx={{ color: "#fff !important", margin: "1rem 0" }}>
                  Tell us where this equipment is located
                </Typography>
                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  {Equiplocationpopup && (
                    <GoogleMapLocation
                      onPress={(center) => {
                        getFullAddress(center);
                      }}
                      inputRefs={inRef}
                      searchRef={searchRef}
                      equipLocation={EquipLocation}
                      equipAddress={EquipAddress}
                    />
                  )}
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      updateLocation();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Pricing */}
      <Modal
        keepMounted
        open={EquipPricingpopup}
        onClose={() => setEquipPricingpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquipPricingpopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              //component="form"
              // onSubmit={updatePricing}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  EQUIPMENT PRICING
                </Typography>
                <Typography sx={{ color: "#fff !important", marginTop: "1rem" }}>
                  Set your rental prices
                </Typography>
                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <FormControl
                    fullWidth
                    className="form-fields form-fields-radio"
                  >

                    <FormControl>
                      {/* myChange */}
                      <TextField
                        id="outlined-disabled"
                        className="popup-input"
                        label="Price per hour"
                        onChange={PriceperHour}
                        value={priceperhour}
                        variant="filled"
                        placeholder=""
                      />
                      {/* <NewCssNumericInput
                        prefix={'$'}
                        name="example"
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        label="Price per hour"
                        onChange={PriceperHour}
                        value={priceperhour}
                        variant="filled"
                        className="popup-input"
                        inputProps={{
                          maxLength: 10,
                          disableUnderline: true
                        }}
                        // InputProps={{ disableUnderline: true }}
                        sx={{
                          "& .MuiInput-underline:after": {
                            borderBottom: "0 !important",
                            borderBottomColor: "transparent !important",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottom: "0 !important",
                            borderBottomColor: "transparent !important",
                          },
                          "& .MuiFilledInput-underline:after": {
                            borderBottom: "0 !important",
                            borderBottomColor: "transparent !important",
                          },
                          "& .MuiFilledInput-underline:before": {
                            borderBottom: "0 !important",
                            borderBottomColor: "transparent !important",
                          },

                        }}

                      /> */}
                    </FormControl>
                    <FormControl>
                      {/* myChange */}
                      <TextField
                        id="outlined-disabled"
                        className="popup-input"
                        label="Price per day"
                        onChange={PriceperDay}
                        value={priceperday}
                        variant="filled"
                        placeholder=""
                      />
                      {/* <NewCssNumericInput
                        prefix={'$'}
                        name="example"
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        label="Price per day"
                        onChange={PriceperDay}
                        value={priceperday}
                        variant="filled"
                        className="popup-input"
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{ disableUnderline: true }}
                      /> */}
                    </FormControl>
                  </FormControl>

                  <FormControl
                    fullWidth
                    className="form-fields form-fields-radio"
                  >
                    <FormControl>
                      {/* myChange */}
                      <TextField
                        id="outlined-disabled"
                        className="popup-input"
                        label="Price per week"
                        onChange={PriceperWeek}
                        value={priceperweek}
                        variant="filled"
                        placeholder=""
                      />
                      {/* <NewCssNumericInput
                        prefix={'$'}
                        name="example"
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        label="Price per week"
                        onChange={PriceperWeek}
                        value={priceperweek}
                        variant="filled"
                        className="popup-input"
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{ disableUnderline: true }}
                      /> */}
                    </FormControl>
                    <FormControl>
                      {/* myChange */}
                      <TextField
                        id="outlined-disabled"
                        className="popup-input"
                        label="Price per month"
                        onChange={PriceperMonth}
                        value={pricepermonth}
                        variant="filled"
                        placeholder=""
                      />
                      {/* <NewCssNumericInput
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        prefix={'$'}
                        name="example"
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        label="Price per month"
                        onChange={PriceperMonth}
                        value={pricepermonth}
                        variant="filled"
                        className="popup-input"
                        inputProps={{
                          maxLength: 10,
                        }}
                      /> */}
                    </FormControl>
                  </FormControl>
                  <FormControl fullWidth className="form-fields">
                    <FormControl>
                      <NewCssTextField
                        //disabled
                        prefix={'$$$$'}
                        id="hours-include"
                        label="Hours included per day"
                        placeholder=""
                        // defaultValue="24"
                        onChange={HourInclude}
                        value={hourinclude ? hourinclude : ''}
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}

                        variant="filled"
                        className="popup-input"
                        inputProps={{
                          maxLength: 2,
                        }}
                      />
                    </FormControl>
                  </FormControl>
                  <Button
                    className="signin-btn update-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      updatePricing();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Gallery */}
      <Modal
        keepMounted
        open={EquipGallerypopup}
        onClose={() => setEquipGallerypopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquipGallerypopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Edit EQUIPMENT PHOTOS
                </Typography>
                <Typography variant="h6">Let's show that you've got</Typography>

                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box className="img-box">
                    <Box className="img-box-grid">
                      {file.map((image, i) => (
                        <Box
                          className="img-box-item"
                          onClickCapture={() => {
                            setThumbimage(i);
                          }}
                          key={i}
                        >
                          <img
                            className="main-img-box-item"
                            src={image}
                            alt=""
                          />
                          <IconButton
                            className="cross-btn-2"
                            onClickCapture={() => {
                              removeImage(image, i);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>

                          {Thumbimage == i && (
                            <img className="img-check" src={Check} alt="" />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <FormControl fullWidth className="form-fields">
                    <Button
                      variant="contained"
                      component="label"
                      className="upload-btn"
                      sx={{ fontSize: "25px" }}
                    >
                      Choose
                      <input
                        id="photo"
                        name="photo"
                        type="file"
                        multiple={true}
                        onChange={handleImageChange}
                        hidden
                        accept="image/jpg, image/jpeg, image/png"
                      />
                    </Button>
                  </FormControl>
                  <Typography
                    variant="h6"
                    component="h6"
                    className="service-item__content-para"
                    sx={{ fontWeight: "bold" }}
                  >
                    PHOTO TIPS
                  </Typography>
                  <hr className="yellow-line" />
                  <Box className="photo-tips-main">
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                      // sx={{ fontWeight: "bold", color: "#fff !important" }}
                      >
                        Horizontal Orientation
                      </Typography>
                      <img src={Suggest1} className="photo-tips" alt="" />
                    </Box>
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                        sx={{ fontWeight: "bold" }}
                      >
                        Well Lit
                      </Typography>
                      <img src={Suggest2} className="photo-tips" alt="" />
                    </Box>
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                        sx={{ fontWeight: "bold" }}
                      >
                        No Graphics/Text
                      </Typography>
                      <img src={Suggest3} className="photo-tips" alt="" />
                    </Box>
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                        sx={{ fontWeight: "bold" }}
                      >
                        Composition/Angle
                      </Typography>
                      <img src={Suggest4} className="photo-tips" alt="" />
                    </Box>
                  </Box>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      uploadfiles();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Detail */}
      <Modal
        keepMounted
        open={EquipDetailpopup}
        onClose={() => setEquipDetailpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquipDetailpopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  EQUIPMENT DETAILS
                </Typography>
                <Typography variant="h6">
                  Tell us about your equipment.
                </Typography>

                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <FormControl
                    fullWidth
                    className="form-fields form-fields-radio"
                  >
                    <FormControl variant="filled">
                      <InputLabel id="demo-simple-select-filled-label" sx={{ color: "#2d2d2d !important" }}>
                        Category
                      </InputLabel>
                      <Select
                        // className="select-field"
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select"
                        value={category}
                        label="Category"
                        onChange={changeCategory}
                        sx={{
                          "& .MuiSelect-select": {
                            color: "#2D2D2D !important",
                            fontFamily: "Gotham !important",
                          },
                        }}
                        style={{
                          backgroundColor: "#fff",
                          border: "2px solid #FAA61A",
                          borderRadius: "10px",
                        }}
                        className="select-dropdwn"
                      // sx={{
                      //   "& .MuiSelect-select": {
                      //     color: "#2D2D2D !important",
                      //     fontFamily: "GothamBold !important",
                      //   },
                      // }}
                      // style={{
                      //   backgroundColor: "#fff",
                      //   border: "2px solid #FAA61A",
                      //   borderRadius: "10px",
                      // }}
                      >
                        <MenuItem value="">Select Here</MenuItem>
                        {AllCategories.map((item, index) => (
                          <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <NewCssTextField
                        onChange={HandlelistingTitle}
                        id="listing-title"
                        label="Listing Title"
                        variant="filled"
                        value={ListingTitle}
                        className="popup-input"
                        inputProps={{
                          maxLength: 150,
                        }}
                      />
                    </FormControl>
                  </FormControl>

                  <FormControl
                    fullWidth
                    className="form-fields form-fields-radio"
                  >
                    <FormControl>
                      <NewCssTextField
                        onChange={Handlemakemodel}
                        value={Makemodel}
                        id="make-model"
                        label="Make & Model"
                        variant="filled"
                        className="popup-input"
                        inputProps={{
                          maxLength: 150,
                        }}
                      />
                    </FormControl>
                    <FormControl variant="filled">
                      <InputLabel id="demo-simple-select-filled-label">
                        Year
                      </InputLabel>
                      <Select
                        className="select-dropdwn"
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select"
                        value={Year}
                        label="Year"
                        onChange={HandleYear}
                        sx={{
                          "& .MuiSelect-select": {
                            color: "#2D2D2D !important",
                            fontFamily: "Gotham !important",
                          },
                        }}
                        style={{
                          backgroundColor: "#fff",
                          border: "2px solid #FAA61A",
                          borderRadius: "10px",
                        }}
                      >
                        <MenuItem value="">Select Year</MenuItem>
                        {yearArr.map((yearitem, index) => (
                          <MenuItem key={index} value={yearitem}>{yearitem}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>

                  {/* <FormControl
                    fullWidth
                    className="form-fields form-fields-radio"
                  > */}
                    {/* <FormControl>
                      <NewCssTextField
                        onChange={HandleVinNumber}
                        value={vinNumber}
                        id="vin-serial"
                        label="VIN / Serial #"
                        variant="filled"
                        className="popup-input"
                      />
                    </FormControl> */}
                    {/* Commented on 19 Jan 2024 by MK */}
                    {/* <FormControl>
                      <NewCssTextField
                        onChange={HandleFeature}
                        value={Feature}
                        id="equipmentfeature"
                        label="Equipment Feature"
                        variant="filled"
                        className="popup-input"
                      />
                    </FormControl> */}
                  {/* </FormControl> */}

                  <FormControl fullWidth className="form-fields">
                    <NewCssTextField
                      id="outlined-multiline-static"
                      label="Equipment Description"
                      //placeholder={"Max. 400 characters"}
                      multiline
                      // maxRows={4}
                      rows={4}
                      fullWidth
                      variant="filled"
                      onChange={HandleDescription}
                      value={Description}
                      className="popup-input"
                    />
                  </FormControl>
                  <span className="characters-p">
                    Characters Left&nbsp;
                    <span style={{ color: "#faa61a" }}>
                      {pendingDiscriptionChar}
                    </span>
                  </span>
                </Box>
                <Button
                  className="signin-btn"
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                  onClickCapture={() => {
                    updateEquipmentDescription();
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Transportation Section */}
      <Modal
        keepMounted
        open={EquipTransportationpopup}
        onClose={() => setEquipTransportationpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquipTransportationpopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  TRANSPORTATION
                </Typography>

                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Typography
                    className="service-item__content-para"
                    sx={{ color: "#fff !important", margin: "0.5rem 0" }}
                  >
                    Select transport options
                  </Typography>
                  <FormControl className="form-fields form-fields-radio">
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "fff !important" }}>
                      Renter pick up and return?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={Rentpickupreturn}
                      className="radio-grp list-epq-radio-grp"
                    >
                      <FormControlLabel
                        checked={rentpickupreturn == "yes"}
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        checked={rentpickupreturn == "no"}
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="form-fields form-fields-radio">
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="radio-label"
                    >
                      Owner delivery and return?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={HandleOwnerDeliveryReturn}
                      className="radio-grp list-epq-radio-grp"
                    >
                      <FormControlLabel
                        checked={ownerdeliveryreturn == "yes"}
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        checked={ownerdeliveryreturn == "no"}
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  {/* by GD for rentpickupreturn fields show/hide acc to the option selected */}
                  {rentpickupreturn == "yes" && (
                    <>
                      <Typography
                        variant="h6"
                        component="h6"
                        sx={{ color: "#000", marginTop: "0.5rem" }}
                      >
                        EQUIPMENT TRANSPORT SPECIFICATIONS
                      </Typography>

                      <FormControl
                        fullWidth
                        className="form-fields form-fields-radio"
                      >
                        <FormControl>
                          <NewCssTextField
                            id="height"
                            label="Height"
                            variant="filled"
                            onChange={EqupHeight}
                            value={equpheight}
                            className="popup-input"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="ft"
                          name="radio-buttons-group"
                          onChange={EqupHeightRadio}
                          className="radio-grp list-epq-radio-grp"
                        >
                          <FormControlLabel
                            checked={equpHeightRadio == "ft"}
                            value="ft"
                            control={<Radio />}
                            label="feet"
                          />
                          <FormControlLabel
                            checked={equpHeightRadio == "in"}
                            value="in"
                            control={<Radio />}
                            label="Inches"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className="form-fields form-fields-radio"
                      >
                        <FormControl>
                          <NewCssTextField
                            id="width"
                            label="Width"
                            variant="filled"
                            onChange={EqupWidth}
                            value={equpwidth}
                            className="popup-input"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="ft"
                          name="radio-buttons-group"
                          className="radio-grp list-epq-radio-grp"
                          onChange={EqupWidthRadio}
                        >
                          <FormControlLabel
                            checked={equpWidthRadio == "ft"}
                            value="ft"
                            control={<Radio />}
                            label="feet"
                          />
                          <FormControlLabel
                            checked={equpWidthRadio == "in"}
                            value="in"
                            control={<Radio />}
                            label="Inches"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className="form-fields form-fields-radio"
                      >
                        <FormControl>
                          <NewCssTextField
                            id="length"
                            label="Length"
                            variant="filled"
                            onChange={EqupLength}
                            value={equplength}
                            className="popup-input"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="ft"
                          name="radio-buttons-group"
                          className="radio-grp list-epq-radio-grp"
                          onChange={EqupLengthRadio}
                        >
                          <FormControlLabel
                            checked={equpLengthRadio == "ft"}
                            value="ft"
                            control={<Radio />}
                            label="feet"
                          />
                          <FormControlLabel
                            checked={equpLengthRadio == "in"}
                            value="in"
                            control={<Radio />}
                            label="Inches"
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControl
                        fullWidth
                        className="form-fields form-fields-radio"
                      >
                        <FormControl>
                          <NewCssTextField
                            id="weight"
                            label="Weight"
                            variant="filled"
                            onChange={EqupWeight}
                            value={equpweight}
                            className="popup-input"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#fff !important" }}>
                          lbs.
                        </FormLabel>
                      </FormControl>
                    </>
                  )}
                  {/* end by GD */}
                  <hr className="yellow-line" />

                  {ownerdeliveryreturn == "yes" && (
                    <>
                      <hr className="yellow-line" />
                      <Typography variant="h6" component="h6" sx={{ color: "#fff !important" }}>
                        TRANSPORTATION RATES
                      </Typography>

                      <FormControl
                        fullWidth
                        className="form-fields form-fields-radio"
                      >
                        {/* myChange */}
                        <TextField
                          id="outlined-disabled"
                          className="popup-input"
                          label="Min. transport charges (for round trip)"
                          onChange={HandleMinTransportChrg}
                          value={mintransportchrg}
                          variant="filled"
                          placeholder="$ 0"
                        />
                        <TextField
                          id="outlined-disabled"
                          className="popup-input"
                          label="Per-mile charge begins after"
                          onChange={PerMile}
                          value={permile}
                          variant="filled"
                          placeholder="0"
                        />

                      </FormControl>

                      <FormControl
                        fullWidth
                        className="form-fields form-fields-radio"
                      >
                        {/* myChange */}
                        <TextField
                          id="outlined-disabled"
                          className="popup-input"
                          label="Per mile rate"
                          onChange={PerMileDeliverChrg}
                          value={permiledeliverchrg}
                          variant="filled"
                          placeholder="$ 0"
                        />

                        <NewCssTextField
                          id="max-transaport-range"
                          label="Maximum transport range"
                          variant="filled"
                          onChange={MaximumTransportRange}
                          value={maximumtransportrange}
                          className="popup-input"
                        />
                      </FormControl>
                    </>
                  )}

                  <hr className="yellow-line" />
                  <FormControl className="form-fields form-fields-radio">
                    <FormLabel id="demo-radio-buttons-group-label">
                      Transport available on Saturday?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={AvailableSaturday}
                      className="radio-grp list-epq-radio-grp"
                    >
                      <FormControlLabel
                        checked={availableSaturday == "yes"}
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        checked={availableSaturday == "no"}
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="form-fields form-fields-radio">
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="radio-label"
                    >
                      Transport available on Sunday?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={AvailableSunday}
                      className="radio-grp list-epq-radio-grp"
                    >
                      <FormControlLabel
                        checked={availableSunday == "yes"}
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        checked={availableSunday == "no"}
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      updateTransportation();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Rental Instruction Section */}
      <Modal
        keepMounted
        open={EquipRentalInstructionpopup}
        onClose={() => setEquipRentalInstructionpopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquipRentalInstructionpopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  RENTAL INSTRUCTIONS
                </Typography>

                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box>
                    <Typography
                      sx={{
                        margin: "0.5rem 0",
                        fontFamily: "GothamBold !important",
                        color: "#fff !important",
                      }}
                    >
                      Damage and Liability Coverage
                    </Typography>

                    {DamageLiability && (
                      <Box>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          className="radio-label"
                          style={{ color: "#fff !important" }}
                        >
                          The information you provide below will be made available to your renter after the booking is secured.
                        </FormLabel>

                        <Box>
                          <Link
                            sx={{
                              color: "#faa61a !important",
                              textDecorationColor: "#faa61a !important",
                              fontSize: "clamp(14px, 3vw, 16px) !important",
                              textDecoration: "underline",
                              marginTop: "0.5rem",
                            }}
                            href="/terms_and_conditions"
                            target="_blank"
                          >
                            See terms and conditions.
                          </Link>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontFamily: "GothamBold !important",
                        margin: "0.5rem 0",
                      }}
                    >
                      Maintenance and Service
                    </Typography>

                    {MaintenceService && (
                      <Box>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          className="radio-label"
                          style={{ color: "#fff !important" }}
                        >
                          Include any special instructions for picking up and returning this equipment ( i.e. where to find the equipment, where the keys are located, etc.)
                        </FormLabel>
                        <FormControl fullWidth className="form-fields">
                          <NewCssTextField
                            id="outlined-multiline-static"
                            multiline
                            // maxRows={2}
                            rows={2}
                            fullWidth
                            variant="filled"
                            label="Instructions"
                            onChange={HandleMaintenceServiceText}
                            value={MaintenceServiceText}
                            className="popup-input"
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="radio-label"
                      style={{
                        cursor: "pointer",
                        color: "#fff !important",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff !important",
                          fontFamily: "GothamBold !important",
                        }}
                      >
                        Welcome Message
                      </Typography>
                    </FormLabel>
                    {WelcomeMessage && (
                      <Box>
                        <FormControl fullWidth className="form-fields">
                          <NewCssTextField
                            id="outlined-multiline-static"
                            multiline
                            // maxRows={2}
                            rows={2}
                            fullWidth
                            variant="filled"
                            label="Message"
                            onChange={HandleWelcomeMessageText}
                            value={WelcomeMessageText}
                            className="popup-input"
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="radio-label"
                      style={{
                        cursor: "pointer",
                        fontFamily: "GothamBold !important",
                        fontWeight: "900",
                        color: "#fff !important",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff !important",
                          fontFamily: "GothamBold !important",
                        }}
                      >
                        Operating Instructions
                      </Typography>
                    </FormLabel>

                    {OperatingInstruction && (
                      <Box>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          className="radio-label"
                          style={{ color: "#faa61a" }}
                        >
                          Include any information that might be helpful to your renter to safely and efficiently operate this equipment.
                        </FormLabel>
                        <FormControl fullWidth className="form-fields">
                          <NewCssTextField
                            id="outlined-multiline-static"
                            multiline
                            // maxRows={4}
                            rows={4}
                            fullWidth
                            variant="filled"
                            label="Instructions"
                            onChange={HandleOperatingInstructionText}
                            value={OperatingInstructionText}
                            className="popup-input"
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      UpdateRentalInstruction();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Update Equipment Rental History Section */}
      <Modal
        keepMounted
        open={EquipRentalHistorypopup}
        onClose={() => setEquipRentalHistorypopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setEquipRentalHistorypopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                <Typography variant="h5" sx={{ color: "#fff !important" }}>
                  My<span style={{ color: "#faa61a" }}>Rental</span>History
                </Typography>

                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box className="owner-div">
                    <Box className="owner-img booked-img">
                      <img
                        src={EquipData["thumbnail"]}
                        alt=""
                        style={{ borderRadius: "0px" }}
                      />
                    </Box>
                    <Box sx={{ fontWeight: "bold" }}>
                      <Typography>
                        {EquipData["make"]
                          ? EquipData["make"]
                          : "Not Available"}
                      </Typography>
                      <Typography style={{ color: "#faa61a" }}>
                        {EquipData["model"]
                          ? EquipData["model"]
                          : "Not Available"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className="rental-sec-item-top">
                    <Box className="location-sec-grid">
                      <Box className="location-sec-inner">
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontFamily: "GothamBold !important",
                            fontWeight: "900",
                          }}
                        >
                          RENTER ORDERS
                        </Typography>
                      </Box>
                      <Box className="location-sec-inner">
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontFamily: "GothamBold !important",
                            fontWeight: "900",
                          }}
                        >
                          YOU EARNED
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <hr className="yellow-line" />

                  <Box className="bookings-box">
                    {MyOrdersHistory && MyOrdersHistory.length > 0 ? (
                      <>
                        <Box
                          className="active-rental-inner booking-box-inner"
                          ref={orderHistoryScroll}
                        >
                          {PagerMyOrdersHistory.map((myorderdata , index) => (
                            <Box key={index}>
                              <Box
                                className="rental-sec-item-top"
                                onClick={() => HandleOrderDetail(myorderdata)}
                                style={{ cursor: "pointer" }}
                              >
                                <Typography sx={{ color: "#faa61a !important", textDecorationLine: 'underline', textDecorationColor: '#faa61a !important' }}>
                                  Order ID #{myorderdata.id}
                                </Typography>

                                <Box
                                  className="location-sec-grid"
                                  sx={{ margin: "1rem 0 !important" }}
                                >
                                  <Box className="location-sec-inner">
                                    <Box className="rental-dates">
                                      <Box className="">
                                        <Typography>
                                          Start:
                                          {moment(myorderdata.start_date, [
                                            "YYYY-MM-DD",
                                          ]).format("MM/DD")}{" "}
                                          {moment(myorderdata.start_time, [
                                            "HH:mm:ii",
                                          ]).format("hh:mm a")}
                                        </Typography>
                                        <Typography>
                                          End:
                                          {moment(myorderdata.end_date, [
                                            "YYYY-MM-DD",
                                          ]).format("MM/DD")}{" "}
                                          {moment(myorderdata.end_time, [
                                            "HH:mm:ii",
                                          ]).format("hh:mm a")}
                                        </Typography>
                                        <Typography>
                                          Transport:
                                          {myorderdata.delivery_required == "0"
                                            ? "Renter"
                                            : "Owner"}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box className="location-sec-inner">
                                    <Typography>
                                      $
                                      {myorderdata.admin_commission != null
                                        ?
                                        new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }).format(myorderdata.total_price)
                                        : new Intl.NumberFormat("en-US", {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 2,
                                        }).format(myorderdata.total_price)
                                      }
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color:
                                          myorderdata.order_status ==
                                            "payment_complete"
                                            ? "#149247 !important"
                                            : myorderdata.order_status ==
                                              "pickup"
                                              ? "#149247 !important"
                                              : myorderdata.order_status ==
                                                "returned"
                                                ? "#faa61a !important"
                                                : myorderdata.order_status ==
                                                  "completed"
                                                  ? "#147a17 !important"
                                                  : myorderdata.order_status ==
                                                    "security_settled"
                                                    ? "#0a4823 !important"
                                                    : myorderdata.order_status ==
                                                      "disputed"
                                                      ? "#faa61a !important"
                                                      : myorderdata.order_status ==
                                                        "dispute_settled"
                                                        ? "#0a4823 !important"
                                                        : "",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {myorderdata.order_status
                                        .split("_")
                                        .join(" ")
                                        .toUpperCase()}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <hr className="yellow-line" />
                            </Box>
                          ))}

                          {MyOrdersHistory.length !=
                            PagerMyOrdersHistory.length && (
                              <Box className="pagination-section">
                                <Box
                                  className="btn-load-more"
                                  sx={{ color: "#faa61a !important" }}
                                  onClick={HandleMyOrderHistory}
                                >
                                  {"See More"}
                                  <img
                                    src={gemArrow}
                                    alt="GoEquipMe"
                                    className="gem-arrow arrow-load-more"
                                  />
                                </Box>
                                <Box sx={{ height: "80px" }}></Box>
                              </Box>
                            )}
                        </Box>
                      </>
                    ) : (
                      <Box className="no-bookings" sx={{ height: "100%" }}>
                        <Typography
                          sx={{
                            color: "#fff !important",
                            fontSize: "clamp(20px, 3vw ,30px)",
                            marginTop: "1rem",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          No bookings available
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Order Detail Popup Section */}
      <Modal
        keepMounted
        open={OrderDetailpopup}
        onClose={() =>
          RentalClick == "no"
            ? (setOrderDetailpopup(false), setEquipRentalHistorypopup(true))
            : (setOrderDetailpopup(false), setRentalClick("no"))
        }
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() =>
              RentalClick == "no"
                ? (setOrderDetailpopup(false), setEquipRentalHistorypopup(true))
                : (setOrderDetailpopup(false), setRentalClick("no"))
            }
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner">
                <Typography variant="h5">
                  <span style={{ color: "#faa61a" }}>Order</span><span className="detail-span-p">Detail</span>
                </Typography>

                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box className="owner-div">
                    <Box className="owner-img booked-img">
                      <img
                        src={EquipData["thumbnail"]}
                        alt=""
                        style={{ borderRadius: "0px" }}
                      />
                    </Box>
                    <Box sx={{ fontWeight: "bold" }}>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                      >
                        {OrderDetailPopData.make
                          ? OrderDetailPopData.make
                          : "Not Available"}
                      </Typography>
                      <Typography style={{ color: "#faa61a" }}>
                        {OrderDetailPopData.model
                          ? OrderDetailPopData.model
                          : "Not Available"}
                      </Typography>
                      <Typography>Order ID #{OrderDetailPopData.id}</Typography>
                    </Box>
                  </Box>

                  <Box className="cmmn-rental-date-main">
                    <Box>
                      <Typography>Rental Dates</Typography>
                      <Box className="cmmn-rental-date-innr">
                        <Typography>
                          <span>Start:</span>&nbsp;
                          <span>{moment(OrderDetailPopData.start_date, [
                            "YYYY-MM-DD",
                          ]).format("MM/DD")}{" "}
                            {moment(OrderDetailPopData.start_time, [
                              "HH:mm:ii",
                            ]).format("hh:mm a")}
                          </span>
                        </Typography>
                        <Typography>
                          <span>End&nbsp;&nbsp;:</span>&nbsp;
                          <span>
                            {moment(OrderDetailPopData.end_date, [
                              "YYYY-MM-DD",
                            ]).format("MM/DD")}{" "}
                            {moment(OrderDetailPopData.end_time, [
                              "HH:mm:ii",
                            ]).format("hh:mm a")}
                          </span>


                        </Typography>
                      </Box>

                    </Box>

                    <Box className="cmmn-o-r-detail">
                      <Box className="cmmn-o-r-img">
                        <img
                          src={OrderDetailPopData.profile_pic}
                          alt=""
                        />
                      </Box>
                      <Box className="cmmn-o-r-detail-text">
                        <Typography>RENTER :</Typography>
                        <Typography sx={{ color: "#faa61a" }}>
                          {OrderDetailPopData.first_name}
                        </Typography>
                      </Box>
                    </Box>

                  </Box>

                  <hr className="yellow-line" />
                  <Box className="equipment-rate-div">
                    <Box className="equipment-rate-inner cmmn-rate-list">
                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          Status :{" "}
                          <span
                            style={{
                              color: "#faa61a",
                              textTransform: "uppercase",
                            }}
                          >
                            {OrderDetailPopData.order_status == "completed" ||
                              OrderDetailPopData.order_status ==
                              "security_settled"
                              ? "COMPLETE"
                              : OrderDetailPopData.order_status ==
                                "order_canceled"
                                ? "Cancelled"
                                : OrderDetailPopData.order_status ==
                                  "payment_complete"
                                  ? "pending"
                                  : "Active"}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          Transport : {OrderDetailPopData.order_from}
                        </Typography>
                      </Box>
                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          Rental Price
                        </Typography>
                        <Typography
                          sx={{ color: "#fff !important", flex: "1" }}
                          className="dash-black-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff !important" }}>
                          {/* ${OrderDetailPopData.rental_price.toFixed(2)} */}
                          ${new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(OrderDetailPopData.rental_price)}
                        </Typography>
                      </Box>
                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          Security Deposit{" "}
                          {OrderDetailPopData.security_deposit_refund_txt}
                        </Typography>
                        <Typography
                          sx={{ color: "#fff !important", flex: "1" }}
                          className="dash-black-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff !important" }}>
                          {/* ${OrderDetailPopData.security_deposit.toFixed(2)} */}
                          ${new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(OrderDetailPopData.security_deposit)}
                        </Typography>
                      </Box>

                      {/* <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          Additional Miles Charge
                        </Typography>
                        <Typography
                          sx={{ color: "#fff !important", flex: "1" }}
                          className="dash-black-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff !important" }}>                          
                          ${new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(OrderDetailPopData.minimum_delivery_fees)}
                        </Typography>
                      </Box> */}

                      {OrderDetailPopData.transport_fee > 0 && (
                        <Box
                          className="rate-list-grid"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ color: "#fff !important" }}>
                            Transport Charges
                          </Typography>
                          <Typography
                            sx={{ color: "#000", flex: "1" }}
                            className="dash-black-border"
                          ></Typography>
                          <Typography sx={{ color: "#fff !important" }}>
                            ${new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(OrderDetailPopData.transport_fee)}
                          </Typography>
                        </Box>
                      )}

                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          Admin Fee
                        </Typography>
                        <Typography
                          sx={{ color: "#fff !important", flex: "1" }}
                          className="dash-black-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff !important" }}>
                          -${new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(OrderDetailPopData.admin_fee)}
                        </Typography>
                      </Box>
                      {OrderDetailPopData.order_status == "order_canceled" && (
                        <Box
                          className="rate-list-grid"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ color: "#faa61a" }}>
                            Order has been Cancelled
                          </Typography>
                        </Box>
                      )}

                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#fff !important" }}>
                          TOTAL EARNED {OrderDetailPopData.grand_total_txt}
                        </Typography>
                        <Typography
                          sx={{ color: "#fff !important", flex: "1" }}
                          className="dash-black-border"
                        ></Typography>
                        <Typography sx={{ color: "#fff !important" }}>
                          {/* $
                          {(
                            OrderDetailPopData.grand_total +
                            OrderDetailPopData.admin_fee
                          ).toFixed(2)} */}
                          ${new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(OrderDetailPopData.grand_total)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>


                  {ActiveOrder.length > 0 && (
                    <ExtendedOrderDetail ExtendedOrder={ActiveOrder} UserInfo={UserInfo}
                      Success={(data) => {
                        if (data) {
                        }
                      }}


                    />
                  )}



                </Box>


              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Edit Equipment popup/modal */}
      <Modal
        keepMounted
        open={EquipAllDatapopup}
        onClose={() => { setEquipAllDatapopup(false); OnSuccess('error') }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => {
              OnSuccess('error');
              setEquipAllDatapopup(false)
            }
            }
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form popup-cmmn popup-white edit-eqp-inner cal-pop">


                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box style={{ display: !EquipAllDatapopup ? "none" : "block" }}>

                    {/* {ListEquipType == 'sale' && (
                      <Typography variant="h5" style={{ color: "#faa61a" }}>
                        Sell it
                      </Typography>
                    )} */}

                    {/* by GD */}

                    <Typography variant="h5" style={{ color: "#faa61a" }}>
                      Rent it
                    </Typography>

                    {/* {ListEquipType == 'sale' ? (
                      <Typography variant="h5" style={{ color: "#faa61a" }}>
                        Rent it
                      </Typography>
                    ) : (
                      <Typography variant="h5" style={{ color: "#faa61a" }}>
                       Complete the following fields to list item for sale
                      </Typography>
                    )} */}
                    {/* end by GD */}
                    <FormControl className="">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        //   onClick={HandleRent}
                        className="radio-grp list-eqp-page"
                      >
                        <Box>
                          <FormControlLabel
                            // checked={EquipTypeRent == 'on'}
                            // value={EquipTypeRent}
                            control={<CheckBox />}
                            disabled={true}
                            label={
                              <Box>
                                <Typography sx={{ gap: '8px' }}>
                                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                    For Rent
                                  </span>
                                </Typography>
                              </Box>
                            }
                            className="radio-grp"
                          />
                        </Box>

                      </RadioGroup>
                    </FormControl>

                    {/* <FormControl className="">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        onClick={HandleSale}
                        className="radio-grp list-eqp-page"
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          <FormControlLabel
                            checked={EquipTypeSale == 'on'}
                            value={EquipTypeSale}
                            control={<Radio />}
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                <Typography sx={{ gap: '8px' }}>
                                  <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                    For Sale
                                  </span>
                                </Typography>
                              </Box>
                            }
                            className="radio-grp"
                          />

                        </Box>
                      </RadioGroup>
                    </FormControl> */}

                    {/* Equipment Detail Section */}
                    {EquipTypeRent == 'on' && (
                      <>
                        <Typography variant="h5" style={{ color: "#faa61a" }}>
                          EQUIPMENT DETAILS
                        </Typography>
                        <Typography variant="h6">
                          Tell us about your equipment.
                        </Typography>

                        <FormControl
                          fullWidth
                          className="form-fields form-fields-radio"
                        >
                          <FormControl variant="filled">
                            <InputLabel id="demo-simple-select-filled-label">
                              Category
                            </InputLabel>
                            <Select
                              className="select-dropdwn"
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select"
                              value={category}
                              label="Category"
                              onChange={changeCategory}
                              // InputProps={{
                              //   disableUnderline: true
                              // }}
                              sx={{
                                "& .MuiSelect-select": {
                                  color: "#2D2D2D !important",
                                  fontFamily: "Gotham !important",
                                },
                              }}
                              style={{
                                backgroundColor: "#fff",
                                border: "2px solid #FAA61A",
                                borderRadius: "10px",
                              }}
                            >
                              <MenuItem value="">Select Here</MenuItem>
                              {AllCategories.map((item, index) => (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl>
                            <NewCssTextField
                              onChange={HandlelistingTitle}
                              id="listing-title"
                              label="Listing Title"
                              variant="filled"
                              value={ListingTitle}
                              className="popup-input"
                              InputProps={{
                                disableUnderline: true
                              }}
                              inputProps={{
                                maxLength: 150,
                              }}
                            />
                          </FormControl>
                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields form-fields-radio"
                        >
                          <FormControl>
                            <NewCssTextField
                              onChange={Handlemakemodel}
                              value={Makemodel}
                              id="make-model"
                              label="Make & Model"
                              variant="filled"
                              className="popup-input"
                              InputProps={{
                                disableUnderline: true
                              }}
                              inputProps={{
                                maxLength: 150,
                              }}
                            />
                          </FormControl>
                          <FormControl variant="filled">
                            <InputLabel id="demo-simple-select-filled-label">
                              Year
                            </InputLabel>
                            <Select className="select-dropdwn"
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select"
                              value={Year}
                              label="Year"
                              onChange={HandleYear}
                              // InputProps={{
                              //   disableUnderline: true
                              // }}
                              sx={{
                                "& .MuiSelect-select": {
                                  color: "#2D2D2D !important",
                                  fontFamily: "GothamBold !important",
                                },
                              }}
                              style={{
                                backgroundColor: "#fff",
                                border: "2px solid #FAA61A",
                                borderRadius: "10px",
                              }}
                            >
                              <MenuItem value="">Select Year</MenuItem>
                              {yearArr.map((yearitem, index) => (
                                <MenuItem value={yearitem} key={index}>{yearitem}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FormControl>

                        {/* <FormControl
                          fullWidth
                          className="form-fields form-fields-radio"
                        > */}
                          {/* <FormControl>
                            <NewCssTextField
                              onChange={HandleVinNumber}
                              value={vinNumber}
                              id="vin-serial"
                              label="VIN / Serial #"
                              variant="filled"
                              className="popup-input"
                              InputProps={{
                                disableUnderline: true
                              }}
                            />
                          </FormControl> */}
                          {/* Commented on 19 Jan 2024 by MK */}
                          {/* <FormControl>
                            <NewCssTextField
                              onChange={HandleFeature}
                              value={Feature}
                              id="equipmentfeature"
                              label="Equipment Feature"
                              variant="filled"
                              className="popup-input"
                            />
                          </FormControl> */}
                        {/* </FormControl> */}

                        <FormControl fullWidth className="form-fields">
                          <NewCssTextField
                            id="outlined-multiline-static"
                            label="Equipment Description"
                            //placeholder={"Max. 400 characters"}
                            multiline
                            // maxRows={4}
                            rows={4}
                            fullWidth
                            variant="filled"
                            onChange={HandleDescription}
                            value={Description}
                            className="popup-input"
                          />
                        </FormControl>
                        <span>
                          <span className="characters-p">
                            Characters Left&nbsp;
                          </span>
                          <span style={{ color: "#faa61a" }}>
                            {pendingDiscriptionChar}
                          </span>
                        </span>

                      </>
                    )}


                    {/* {EquipTypeSale == 'on' && (
                      <>
                        <br></br>
                        <FormControl
                          sx={{ width: '45%' }}
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Year
                          </label>
                          <Select className="select-dropdwn"
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select"
                            value={Year}
                            label="Year"
                            onChange={HandleYear}
                            sx={{
                              "& .MuiSelect-select": {
                                color: "#2D2D2D !important",
                                fontFamily: "GothamBold !important",
                              },
                            }}
                            style={{
                              backgroundColor: "#fff",
                              border: "2px solid #FAA61A",
                              borderRadius: "10px",
                            }}
                          >
                            <MenuItem value="">Select Year</MenuItem>
                            {yearArr.map((yearitem, index) => (
                              <MenuItem value={yearitem}>{yearitem}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Equipment Feature
                          </label>

                          <CssTextField
                            onChange={HandleFeature}
                            value={Feature}
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Equipment Feature"
                          />


                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Mileage
                          </label>
                          <CssTextField
                            id="outlined-disabled"
                            // className="popup-input"
                            //label="Mileage"
                            onChange={HandleMileage}
                            value={Mileage}
                            variant="outlined"
                            placeholder="xx mileage"
                            inputProps={{
                              maxLength: 7,
                            }}
                          />

                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Hours
                          </label>

                          <CssTextField
                            id="max-transaport-range"
                            //label="Hours"
                            placeholder={"xx hours"}
                            variant="outlined"
                            onChange={HandleHours}
                            value={Hours}
                            inputProps={{
                              maxLength: 7,
                            }}
                          />
                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Equipment Description
                          </label>

                          <CssTextField
                            id="outlined-multiline-static"
                            //label="Equipment Description"
                            multiline
                            rows={4}
                            fullWidth
                            placeholder='Max: 300 Words'
                            // variant="filled"
                            onChange={HandleDescription}
                            value={Description}
                          />
                          <Box className="characters-p" sx={{ marginTop: '5px', fontFamily: 'Gotham !important', fontWeight: '300', fontSize: '12px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                            Characters Left :&nbsp;
                            <span style={{ color: "#fff" }}>
                              {pendingDiscriptionChar}
                            </span>
                          </Box>
                        </FormControl>

                        <FormControl
                          fullWidth
                          className=""
                          sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row !important', gap: '8px' }}
                        >
                          <FormLabel className="" sx={{ color: '#fff !important', fontFamily: 'Gotham !important' }}>
                            Review the

                          </FormLabel>
                          <Link href="/terms_and_conditions" target="_blank" className='' sx={{ color: '#faa61a !important', textDecorationColor: '#faa61a !important' }}>
                            <Typography>
                              Terms and Conditions
                            </Typography>
                          </Link>
                        </FormControl>

                      </>
                    )} */}

                    {/* Equipment Photos Section */}
                    {EquipTypeRent == 'on' && (
                      <>
                        <Typography variant="h5" style={{ color: "#faa61a" }}>
                          EQUIPMENT PHOTOS
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#fff !important" }}>Let's show that you've got</Typography>

                        <Box className="img-box">
                          <Box className="img-box-grid">
                            {file.map((image, i) => (
                              <Box
                                className="img-box-item"
                                onClickCapture={() => {
                                  setThumbimage(i);
                                }}
                                key={i}
                              >
                                <img
                                  className="main-img-box-item"
                                  src={image}
                                  alt=""
                                />
                                <IconButton
                                  className="cross-btn-2"
                                  onClickCapture={() => {
                                    removeImage(image, i);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>

                                {Thumbimage == i && (
                                  <img className="img-check" src={Check} alt="" />
                                )}
                              </Box>
                            ))}
                          </Box>
                        </Box>

                        <FormControl fullWidth className="form-fields">
                          <Button
                            variant="contained"
                            component="label"
                            className="upload-btn"
                            sx={{ fontSize: "25px" }}
                          >
                            Choose
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              multiple={true}
                              onChange={handleImageChange}
                              hidden
                              accept="image/jpg, image/jpeg, image/png"
                            />
                          </Button>
                        </FormControl>
                        <Typography
                          variant="h6"
                          component="h6"
                          className="service-item__content-para"
                          sx={{ fontWeight: "bold", color: "#fff !important" }}
                        >
                          PHOTO TIPS
                        </Typography>
                        <hr className="yellow-line" />
                        <Box className="photo-tips-main editlisting-photo-tips" sx={{ mb: '2rem' }}>
                          <Box className="photo-tips-main-item">
                            <Typography
                              variant="h6"
                              component="h6"
                              className="service-item__content-para"
                              sx={{ fontWeight: "bold", color: "#fff !important" }}
                            >
                              Horizontal Orientation
                            </Typography>
                            <img src={Suggest1} className="photo-tips" alt="" />
                          </Box>
                          <Box className="photo-tips-main-item">
                            <Typography
                              variant="h6"
                              component="h6"
                              className="service-item__content-para"
                              sx={{ fontWeight: "bold", color: "#fff !important" }}
                            >
                              Well Lit
                            </Typography>
                            <img src={Suggest2} className="photo-tips" alt="" />
                          </Box>
                          <Box className="photo-tips-main-item">
                            <Typography
                              variant="h6"
                              component="h6"
                              className="service-item__content-para"
                              sx={{ fontWeight: "bold", color: "#fff !important" }}
                            >
                              No Graphics/Text
                            </Typography>
                            <img src={Suggest3} className="photo-tips" alt="" />
                          </Box>
                          <Box className="photo-tips-main-item">
                            <Typography
                              variant="h6"
                              component="h6"
                              className="service-item__content-para"
                              sx={{ fontWeight: "bold", color: "#fff !important" }}
                            >
                              Composition/Angle
                            </Typography>
                            <img src={Suggest4} className="photo-tips" alt="" />
                          </Box>
                        </Box>
                      </>
                    )}

                    {/* Equipment Pricing & Quantity Section */}
                    <Typography variant="h5" style={{ color: "#faa61a", marginTop: "1rem" }}>
                      EQUIPMENT PRICING
                    </Typography>

                    {EquipTypeRent == 'on' && (
                      <>
                        <Typography sx={{ color: "#fff !important", marginTop: "1rem" }}>
                          Set your rental prices
                        </Typography>

                        <FormControl
                          fullWidth
                          className="form-fields form-fields-radio"
                        >

                          <FormControl>
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per hour"
                              onChange={PriceperHour}
                              value={priceperhour}
                              variant="filled"
                              placeholder=""
                            />
                            {/* <NewCssNumericInput
                          prefix={'$'}
                          name="example"
                          precision={0}
                          decimalChar="."
                          thousandChar=","
                          label="Price per hour"
                          onChange={PriceperHour}
                          value={priceperhour}
                          variant="filled"
                          className="popup-input"
                          inputProps={{
                            maxLength: 10,
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                        /> */}
                          </FormControl>
                          <FormControl>
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per day"
                              onChange={PriceperDay}
                              value={priceperday}
                              variant="filled"
                              placeholder=""
                            />
                            {/* <NewCssNumericInput
                          prefix={'$'}
                          name="example"
                          precision={0}
                          decimalChar="."
                          thousandChar=","
                          label="Price per day"
                          onChange={PriceperDay}
                          value={priceperday}
                          variant="filled"
                          className="popup-input"
                          inputProps={{
                            maxLength: 10,
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                        /> */}
                          </FormControl>
                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields form-fields-radio"
                        >
                          <FormControl>
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per week"
                              onChange={PriceperWeek}
                              value={priceperweek}
                              variant="filled"
                              placeholder=""
                            />
                            {/* <NewCssNumericInput
                          prefix={'$'}
                          name="example"
                          precision={0}
                          decimalChar="."
                          thousandChar=","
                          label="Price per week"
                          onChange={PriceperWeek}
                          value={priceperweek}
                          variant="filled"
                          className="popup-input"
                          inputProps={{
                            maxLength: 10,
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                        /> */}
                          </FormControl>
                          <FormControl>
                            <TextField
                              id="outlined-disabled"
                              className="popup-input"
                              label="Price per month"
                              onChange={PriceperMonth}
                              value={pricepermonth}
                              variant="filled"
                              placeholder=""
                            />
                            {/* <NewCssNumericInput
                          prefix={'$'}
                          name="example"
                          precision={0}
                          decimalChar="."
                          thousandChar=","
                          label="Price per month"
                          onChange={PriceperMonth}
                          value={pricepermonth}
                          variant="filled"
                          className="popup-input"
                          inputProps={{
                            maxLength: 10,
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                        /> */}
                          </FormControl>
                        </FormControl>
                        <FormControl fullWidth className="form-fields">
                          <FormControl>
                            <NewCssTextField
                              //disabled
                              className="popup-input"
                              id="hours-include"
                              label="Hours included per day"
                              placeholder=""
                              // defaultValue="24"
                              onChange={HourInclude}
                              value={hourinclude ? hourinclude : ""}
                              variant="filled"
                              inputProps={{
                                maxLength: 2,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                disableUnderline: true
                              }}
                            />
                          </FormControl>
                        </FormControl>
                      </>
                    )}

                    {/* {EquipTypeSale == 'on' && (
                      <>

                        <Typography className="" sx={{ color: '#fff' }} >
                          How much do you want for this?
                        </Typography>
                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Asking price
                          </label>
                          <CssTextField
                            id="outlined-disabled"
                            onChange={HandleSalePrice}
                            value={SalePrice}
                            placeholder="$"
                            inputProps={{
                              maxLength: 8,
                            }}
                          />
                        </FormControl>
                        <FormControl className="">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onClick={HandleOBO}
                            className="radio-grp list-eqp-page"
                          >
                            <Box >
                              <FormControlLabel
                                checked={Obo == 'on'}
                                value={Obo}
                                control={<Radio />}
                                label={
                                  <Box>
                                    <Typography sx={{ gap: '8px' }}>
                                      <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                        OBO:
                                      </span>
                                      <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                                        Or Best Offers.
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                className="radio-grp"
                              />
                            </Box>

                          </RadioGroup>
                        </FormControl>

                        <FormControl className="">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onClick={HandleRPO}
                            className="radio-grp list-eqp-page"
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                              <FormControlLabel
                                checked={Rpo == 'on'}
                                value={Rpo}
                                control={<Radio />}
                                label={
                                  <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                    <Typography sx={{ gap: '8px' }}>
                                      <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                        RPO Available:
                                      </span>
                                      <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                                        The owner may offer this as a rental with purchase option.
                                      </span>
                                    </Typography>
                                  </Box>
                                }
                                className="radio-grp"
                              />

                            </Box>
                          </RadioGroup>
                        </FormControl>
                      </>
                    )} */}

                    {/* Transport Pricing & Space Section */}
                    {EquipTypeRent == 'on' && (
                      <>
                        <Typography variant="h5" style={{ color: "#faa61a", marginTop: "1rem" }}>
                          TRANSPORTATION
                        </Typography>

                        <Typography
                          className="service-item__content-para"
                          sx={{ color: "#fff !important", margin: "0.5rem 0" }}
                        >
                          Select transport options
                        </Typography>
                        <FormControl className="form-fields form-fields-radio">
                          <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#fff !important" }}>
                            Renter pick up and return?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={Rentpickupreturn}
                            className="radio-grp list-epq-radio-grp"
                          >
                            <FormControlLabel
                              checked={rentpickupreturn == "yes"}
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              checked={rentpickupreturn == "no"}
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl className="form-fields form-fields-radio">
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            className="radio-label"
                          >
                            Owner delivery and return?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={HandleOwnerDeliveryReturn}
                            className="radio-grp list-epq-radio-grp"
                          >
                            <FormControlLabel
                              checked={ownerdeliveryreturn == "yes"}
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              checked={ownerdeliveryreturn == "no"}
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        {/* by GD for show hide EQUIPMENT TRANSPORT SPECIFICATIONS  */}
                        {rentpickupreturn == "yes" && (
                          <> 
                            <hr className="yellow-line" />
                            <Typography
                              variant="h6"
                              component="h6"
                              sx={{ color: "#000", marginTop: "0.5rem" }}
                            >
                              EQUIPMENT TRANSPORT SPECIFICATIONS
                            </Typography>
                      
                            <FormControl
                              fullWidth
                              className="form-fields form-fields-radio"
                            >
                              <FormControl>
                                <NewCssTextField
                                  id="height"
                                  label="Height"
                                  variant="filled"
                                  onChange={EqupHeight}
                                  value={equpheight}
                                  className="popup-input"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="ft"
                                name="radio-buttons-group"
                                onChange={EqupHeightRadio}
                                className="radio-grp list-epq-radio-grp"
                              >
                                <FormControlLabel
                                  checked={equpHeightRadio == "ft"}
                                  value="ft"
                                  control={<Radio />}
                                  label="feet"
                                />
                                <FormControlLabel
                                  checked={equpHeightRadio == "in"}
                                  value="in"
                                  control={<Radio />}
                                  label="Inches"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl
                              fullWidth
                              className="form-fields form-fields-radio"
                            >
                              <FormControl>
                                <NewCssTextField
                                  id="width"
                                  label="Width"
                                  variant="filled"
                                  onChange={EqupWidth}
                                  value={equpwidth}
                                  className="popup-input"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="ft"
                                name="radio-buttons-group"
                                className="radio-grp list-epq-radio-grp"
                                onChange={EqupWidthRadio}
                              >
                                <FormControlLabel
                                  checked={equpWidthRadio == "ft"}
                                  value="ft"
                                  control={<Radio />}
                                  label="feet"
                                />
                                <FormControlLabel
                                  checked={equpWidthRadio == "in"}
                                  value="in"
                                  control={<Radio />}
                                  label="Inches"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl
                              fullWidth
                              className="form-fields form-fields-radio"
                            >
                              <FormControl>
                                <NewCssTextField
                                  id="length"
                                  label="Length"
                                  variant="filled"
                                  onChange={EqupLength}
                                  value={equplength}
                                  className="popup-input"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="ft"
                                name="radio-buttons-group"
                                className="radio-grp list-epq-radio-grp"
                                onChange={EqupLengthRadio}
                              >
                                <FormControlLabel
                                  checked={equpLengthRadio == "ft"}
                                  value="ft"
                                  control={<Radio />}
                                  label="feet"
                                />
                                <FormControlLabel
                                  checked={equpLengthRadio == "in"}
                                  value="in"
                                  control={<Radio />}
                                  label="Inches"
                                />
                              </RadioGroup>
                            </FormControl>
                            <FormControl
                              fullWidth
                              className="form-fields form-fields-radio"
                            >
                              <FormControl>
                                <NewCssTextField
                                  id="weight"
                                  label="Weight"
                                  variant="filled"
                                  onChange={EqupWeight}
                                  value={equpweight}
                                  className="popup-input"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </FormControl>
                              <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#fff !important" }}>
                                lbs.
                              </FormLabel>
                            </FormControl>
                          </>
                        )}
                        {/* end by GD */}
                        {ownerdeliveryreturn == "yes" && (
                          <>
                            <hr className="yellow-line" />
                            <Typography variant="h6" component="h6" sx={{ color: "#2d2d2d !important" }}>
                              TRANSPORTATION RATES
                            </Typography>

                            <FormControl
                              fullWidth
                              className="form-fields form-fields-radio"
                            >
                              <TextField
                                id="outlined-disabled"
                                className="popup-input"
                                label="Min. transport charges (for round trip)"
                                onChange={HandleMinTransportChrg}
                                value={mintransportchrg}
                                variant="filled"
                                placeholder=""
                              />
                              {/* <NewCssNumericInput
                            prefix={'$'}
                            name="example"
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            label="Min. transport charges (for round trip)"
                            onChange={HandleMinTransportChrg}
                            value={mintransportchrg}
                            variant="filled"
                            className="popup-input"
                            inputProps={{
                              maxLength: 10,
                            }}
                          /> */}
                              <TextField
                                id="outlined-disabled"
                                className="popup-input"
                                label="Per-mile charge begins after"
                                onChange={PerMile}
                                value={permile}
                                variant="filled"
                                placeholder="0"
                              />
                              {/* <NewCssNumericInput
                            //  prefix={'$'}
                            name="example"
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            label="Per-mile charge begins after"
                            onChange={PerMileDeliverChrg}
                            value={permiledeliverchrg}
                            variant="filled"
                            className="popup-input"
                            inputProps={{
                              maxLength: 10,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          /> */}

                            </FormControl>

                            <FormControl
                              fullWidth
                              className="form-fields form-fields-radio"
                            >
                              <TextField
                                id="outlined-disabled"
                                className="popup-input"
                                label="Per mile rate"
                                onChange={PerMileDeliverChrg}
                                value={permiledeliverchrg}
                                variant="filled"
                                placeholder=""
                              />
                              {/* <NewCssNumericInput
                            prefix={'$'}
                            name="example"
                            precision={0}
                            decimalChar="."
                            thousandChar=","
                            label="Per mile rate"
                            onChange={PerMile}
                            value={permile}
                            variant="filled"
                            className="popup-input"
                            inputProps={{
                              maxLength: 10,
                            }}
                          /> */}

                              <NewCssTextField
                                id="max-transaport-range"
                                label="Maximum transport range"
                                variant="filled"
                                onChange={MaximumTransportRange}
                                value={maximumtransportrange}
                                className="popup-input"
                              />
                            </FormControl>
                          </>
                        )}

                        <hr className="yellow-line" />
                        <FormControl className="form-fields form-fields-radio">
                          <FormLabel id="demo-radio-buttons-group-label">
                            Transport available on Saturday?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={AvailableSaturday}
                            className="radio-grp list-epq-radio-grp"
                          >
                            <FormControlLabel
                              checked={availableSaturday == "yes"}
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              checked={availableSaturday == "no"}
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <FormControl className="form-fields form-fields-radio">
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            className="radio-label"
                          >
                            Transport available on Sunday?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={AvailableSunday}
                            className="radio-grp list-epq-radio-grp"
                          >
                            <FormControlLabel
                              checked={availableSunday == "yes"}
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              checked={availableSunday == "no"}
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>


                        {/* Rental Instruction Section */}
                        <Box>
                          <Typography
                            sx={{
                              margin: "0.5rem 0",
                              fontFamily: "GothamBold !important",
                              color: "#fff !important",
                            }}
                          >
                            Damage and Liability Coverage
                          </Typography>

                          {DamageLiability && (
                            <Box>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                className="radio-label"
                                style={{ color: "#fff !important" }}
                              >
                                The information you provide below will be made available to your renter after the booking is secured.
                              </FormLabel>

                              <Box>
                                <Link
                                  sx={{
                                    color: "#faa61a !important",
                                    textDecorationColor: "#faa61a !important",
                                    fontSize: "clamp(14px, 3vw, 16px) !important",
                                    textDecoration: "underline",
                                    marginTop: "0.5rem",
                                  }}
                                  href="/terms_and_conditions"
                                  target="_blank"
                                >
                                  See terms and conditions.
                                </Link>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: "#fff !important",
                              fontFamily: "GothamBold !important",
                              margin: "0.5rem 0",
                            }}
                          >
                            Maintenance and Service
                          </Typography>

                          {MaintenceService && (
                            <Box>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                className="radio-label"
                                style={{ color: "#fff !important" }}
                              >
                                Include any special instructions for picking up and returning this equipment ( i.e. where to find the equipment, where the keys are located, etc.)
                              </FormLabel>
                              <FormControl fullWidth className="form-fields">
                                <NewCssTextField
                                  id="outlined-multiline-static"
                                  multiline
                                  //maxRows={2}
                                  rows={2}
                                  fullWidth
                                  variant="filled"
                                  label="Instructions"
                                  onChange={HandleMaintenceServiceText}
                                  value={MaintenceServiceText}
                                  className="popup-input"
                                />
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            className="radio-label"
                            style={{
                              cursor: "pointer",
                              color: "#fff !important",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff !important",
                                fontFamily: "GothamBold !important",
                              }}
                            >
                              Welcome Message
                            </Typography>
                          </FormLabel>
                          {WelcomeMessage && (
                            <Box>
                              <FormControl fullWidth className="form-fields">
                                <NewCssTextField
                                  id="outlined-multiline-static"
                                  multiline
                                  //maxRows={2}
                                  rows={2}
                                  fullWidth
                                  variant="filled"
                                  label="Message"
                                  onChange={HandleWelcomeMessageText}
                                  value={WelcomeMessageText}
                                  className="popup-input"
                                />
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                        <Box>
                          <FormLabel
                            id="demo-radio-buttons-group-label"
                            className="radio-label"
                            style={{
                              cursor: "pointer",
                              fontFamily: "GothamBold !important",
                              fontWeight: "900",
                              color: "#fff !important",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#fff !important",
                                fontFamily: "GothamBold !important",
                              }}
                            >
                              Operating Instructions
                            </Typography>
                          </FormLabel>

                          {OperatingInstruction && (
                            <Box>
                              <FormLabel
                                id="demo-radio-buttons-group-label"
                                className="radio-label"
                                style={{ color: "#faa61a" }}
                              >
                                Include any information that might be helpful to your renter to safely and efficiently operate this equipment.
                              </FormLabel>
                              <FormControl fullWidth className="form-fields">
                                <NewCssTextField
                                  id="outlined-multiline-static"
                                  multiline
                                  // maxRows={4}
                                  rows={4}
                                  fullWidth
                                  variant="filled"
                                  label="Instructions"
                                  onChange={HandleOperatingInstructionText}
                                  value={OperatingInstructionText}
                                  className="popup-input"
                                />
                              </FormControl>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}

                    <span style={{ display: EquipTypeRent == 'off' ? "none" : "block" }}>
                      <Typography variant="h5" style={{ color: "#faa61a" }}>
                        AVAILABILITY CALENDAR 
                      </Typography>

                      <Typography sx={{ color: "#fff !important", margin: "1rem 0" }}>
                        Select the dates below you want to block out making the
                        equipment unavailable to rent for those dates.
                      </Typography>

                      {/* Availablity Calendar Section */}
                      <Box className="myot-cal">
                        <Calendar
                          locale={myCustomLocale}
                          value={selectedDates}
                          onChange={(event) => {
                            selectDateHandle(event);
                          }}
                          minimumDate={minimumDate}
                          calendarClassName="responsive-calendar"
                          calendarTodayClassName="custom-today-day"
                        />
                      </Box>
                      
                    </span>

                    {/* Equipment Location Section */}
                    {EquipTypeRent == 'on' && (
                      <Box sx={{ marginBottom: '2rem'}}>
                        <Typography variant="h5" style={{ color: "#faa61a", marginTop: "1rem" }}>
                          EQUIPMENT LOCATION
                        </Typography>
                        <Typography sx={{ color: "#fff !important", margin: "1rem 0" }}>
                          Tell us where this equipment is located
                        </Typography>
                        {EquipAllDatapopup && (
                          <GoogleMapLocation
                            onPress={(center) => {
                              getFullAddress(center);
                            }}
                            inputRefs={inRef}
                            searchRef={searchRef}
                            equipLocation={EquipLocation}
                            equipAddress={EquipAddress}
                          />
                        )}
                      </Box>
                    )}

                  </Box>

                  <Box className="updt-btn-div">
                    <Button
                      className="signin-btn updt-btn"
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                      onClickCapture={() => {
                        EditUpdateEquipData();
                      }}
                    >
                      Update
                    </Button>
                  </Box>

                  <Box className="scroll-arrow-div">
                    <KeyboardArrowDownIcon className="scroll-arrow-icon" />
                  </Box>

                  <Box className="scroll-prompt" id="js_scrollPrompt">
                    <Box className="scroll-prompt-shape"></Box>
                  </Box>

                </Box>
              </Box>
            </Box>
          </Box>


        </Box>
      </Modal>


      {/* Edit Sellit Equipment modal */}
      <Modal
        keepMounted
        open={SellitPopup}
        onClose={() => setSellitPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setSellitPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}>
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  {/* by GD for showing diff headers acc. to SaleitpopupType */}
                  {
                    SaleitpopupType == 'both'
                      ? (<>Complete the following fields to list item for sale</>)
                      : (<> Edit {SaleitpopupType == "details" ? (<>Equipment Details</>) : (<>Equipment Pricing</>)}</>)
                  }
                  {/* end by GD */}
                  {/* Edit {SaleitpopupType == "details" ? (<>Equipment Details</>) : (<>Equipment Pricing</>)} */}
                </Typography>
                {SaleitpopupType == 'both' &&
                  <FormControl className="">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      // onClick={HandleSale}
                      className="radio-grp list-eqp-page"
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <FormControlLabel
                          checked={EquipTypeSale == 'on'}
                          value={EquipTypeSale}
                          control={<CheckBox />}
                          label={
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                              <Typography sx={{ gap: '8px' }}>
                                <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                  For Sale
                                </span>
                              </Typography>
                            </Box>
                          }
                          className="radio-grp"
                        />

                      </Box>
                    </RadioGroup>
                  </FormControl>
                }
                <Box>
                  {loadings && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loadings}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  {/* by GD added || SaleitpopupType == "both" */}
                  {(SaleitpopupType == "details" || SaleitpopupType == "both") && (
                    <>

                      <FormControl
                        sx={{ width: '45%' }}
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">
                          Year
                        </label>
                        <Select className="select-dropdwn"
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select"
                          value={Year}
                          label="Year"
                          onChange={HandleYear} 
                          displayEmpty
                          sx={{
                            "& .MuiSelect-select": {
                              color: "#2D2D2D !important",
                              fontFamily: "GothamBold !important",
                            },
                          }}
                          style={{
                            backgroundColor: "#fff",
                            border: "2px solid #FAA61A",
                            borderRadius: "10px",
                          }}
                        >
                          <MenuItem value="">Select Year</MenuItem>
                          {yearArr.map((yearitem, index) => (
                            <MenuItem value={yearitem} key={index}>{yearitem}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    {/* by GD 11Dec for category , make&model , listing */}
                    { ListEquipType == 'sale' && (
                        <>
                          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', alignItems: 'center' }}>
                            <FormControl
                              sx={{ width: '100%' }}
                              className="form-fields list-eqp-form-fields"
                            >
                              <label className="list-eqp-input-label">
                                Category
                              </label>
                              <Select className="select-dropdwn"
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select"
                                value={category}
                                label="Category"
                                onChange={changeCategory}
                                sx={{
                                  "& .MuiSelect-select": {
                                    color: "#2D2D2D !important",
                                    fontFamily: "GothamBold !important",
                                  },
                                }}
                                style={{
                                  backgroundColor: "#fff",
                                  border: "2px solid #FAA61A",
                                  borderRadius: "10px",
                                }}
                              >
                                <MenuItem value="">Select Here</MenuItem>
                                {AllCategories.map((item, index) => (
                                  <MenuItem value={item} key={index}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            
                            <FormControl>
                              <label className="list-eqp-input-label">
                                Listing Title
                              </label>
                              <CssTextField
                                onChange={HandlelistingTitle}
                                id="listing-title"
                                // label="Listing Title"
                                variant="outlined"
                                value={ListingTitle}
                                // className="popup-input"
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                  maxLength: 150,
                                }}
                              />
                            </FormControl>
                          </Box>
                          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', alignItems: 'center', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                            <FormControl>
                              <label className="list-eqp-input-label">
                                Make & Model
                              </label>
                              <CssTextField
                                onChange={Handlemakemodel}
                                value={Makemodel}
                                id="make-model"
                                // label="Make & Model"
                                variant="outlined"
                                // className="popup-input"
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                  maxLength: 150,
                                }}
                              />
                            </FormControl>
                          </Box>
                        </>
                      )}
                 
                    {/* end by GD */}
                    {/* Commented on 19 Jan 2024 by MK */}
                      {/* <FormControl
                        fullWidth
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">
                          Equipment Feature
                        </label>

                        <CssTextField
                          onChange={HandleFeature}
                          value={Feature}
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Equipment Feature"
                          inputProps={{
                            maxLength: 300,
                          }}
                        />


                      </FormControl> */}

                      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', alignItems: 'center' }}>
                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Mileage
                          </label>
                          <CssTextField
                            id="outlined-disabled"
                            // className="popup-input"
                            //label="Mileage"
                            onChange={HandleMileage}
                            value={Mileage ? Number(Mileage).toLocaleString() : Mileage}
                            variant="outlined"
                            placeholder="xx mileage"
                            inputProps={{
                              maxLength: 12,
                            }}
                          />

                        </FormControl>

                        <FormControl
                          fullWidth
                          className="form-fields list-eqp-form-fields"
                        >
                          <label className="list-eqp-input-label">
                            Hours
                          </label>

                          <CssTextField
                            id="max-transaport-range"
                            //label="Hours"
                            placeholder={"xx hours"}
                            variant="outlined"
                            onChange={HandleHours}
                            value={!isNaN(parseFloat(Hours)) ? Hours : ''}
                            inputProps={{
                              maxLength: 12,
                            }}
                          />
                        </FormControl>
                      </Box>

                      <FormControl
                        fullWidth
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">
                          Equipment Description
                        </label>

                        <CssTextField
                          id="outlined-multiline-static"
                          //label="Equipment Description"
                          multiline
                          rows={4}
                          fullWidth
                          placeholder='Max: 300 Words'
                          // variant="filled"
                          onChange={HandleDescription}
                          value={Description}
                        />
                        <Box className="characters-p" sx={{ marginTop: '5px', fontFamily: 'Gotham !important', fontWeight: '300', fontSize: '12px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                          Characters Left&nbsp;
                          <span style={{ color: "#fff" }}>
                            {pendingDiscriptionChar}
                          </span>
                        </Box>
                      </FormControl>

                      <FormControl
                        fullWidth
                        className=""
                        sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row !important', gap: '8px' }}
                      >
                        <FormLabel className="" sx={{ color: '#fff !important', fontFamily: 'Gotham !important' }}>
                          Review the

                        </FormLabel>
                        <Link href="/terms_and_conditions" target="_blank" className='' sx={{ color: '#faa61a !important', textDecorationColor: '#faa61a !important' }}>
                          <Typography>
                            Terms and Conditions
                          </Typography>
                        </Link>
                      </FormControl>

                    </>
                  )}

                  {/* <Typography variant="h5" style={{ color: "#faa61a" }}>
                        Pricing
                      </Typography> */}
                  {/* by GD added || SaleitpopupType == "both" */}
                  {(SaleitpopupType == "pricing" || SaleitpopupType == "both") && (
                    <>
                      <Typography className="" sx={{ color: '#fff', marginTop: '1rem' }}>
                        How much do you want for this?
                      </Typography>
                      <FormControl
                        fullWidth
                        className="form-fields list-eqp-form-fields"
                      >
                        <label className="list-eqp-input-label">
                          Asking price
                        </label>
                        <CssTextField
                          id="outlined-disabled"
                          onChange={HandleSalePrice}
                          value={SalePrice}
                          placeholder="$"
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </FormControl>

                      {/* {SaleitpopupType == "pricing" &&
                        <FormControl
                          fullWidth
                          className=""
                          sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row !important', gap: '8px' }}
                        >
                          <FormLabel className="" sx={{ color: '#fff !important', fontFamily: 'Gotham !important' }}>
                            Review the

                          </FormLabel>
                          <Link href="/terms_and_conditions" target="_blank" className='' sx={{ color: '#faa61a !important', textDecorationColor: '#faa61a !important' }}>
                            <Typography>
                              Terms and Conditions
                            </Typography>
                          </Link>
                        </FormControl>
                      } */}



                      <FormControl className="">

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Checkbox
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            defaultChecked={Obo == "on" ? true : false}
                            onChange={HandleOBO}
                            disableRipple
                            color="default"

                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />
                          <Box>
                            <Typography sx={{ gap: "8px" }}>
                              <span
                                className="radio-span-yellow"
                                style={{
                                  fontFamily: "",
                                  color: "#faa61a !important",
                                }}
                              >
                                OBO:
                              </span>
                              <span
                                className="radio-span-white"
                                style={{
                                  fontFamily: "",
                                  color: "#fff !important",
                                }}
                              >
                                Or Best Offers.
                              </span>
                            </Typography>
                          </Box>


                        </Box>


                      </FormControl>

                      <FormControl className="">

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >

                          <Checkbox
                            sx={{
                              "&:hover": { bgcolor: "transparent" },
                            }}
                            defaultChecked={Rpo == "on" ? true : false}
                            onChange={HandleRPO}
                            disableRipple
                            color="default"
                            inputProps={{ "aria-label": "Checkbox demo" }}
                          />

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography sx={{ gap: "8px" }}>
                              <span
                                className="radio-span-yellow"
                                style={{
                                  fontFamily: "",
                                  color: "#faa61a !important",
                                }}
                              >
                                RPO Available:
                              </span>
                              <span
                                className="radio-span-white"
                                style={{
                                  fontFamily: "",
                                  color: "#fff !important",
                                }}
                              >
                                The owner may offer this as a rental with
                                purchase option.
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        {/* </RadioGroup> */}
                      </FormControl>




                      {/* <FormControl className="">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onClick={HandleOBO}
                          className="radio-grp list-eqp-page"
                        >

                      
                      { SaleitpopupType == "pricing" && 
                        <FormControl
                        fullWidth
                        className=""
                        sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row !important', gap: '8px' }}
                      >
                        <FormLabel className="" sx={{ color: '#fff !important', fontFamily: 'Gotham !important' }}>
                          Review the

                        </FormLabel>
                        <Link href="/terms_and_conditions" target="_blank" className='' sx={{ color: '#faa61a !important', textDecorationColor: '#faa61a !important' }}>
                          <Typography>
                            Terms and Conditions
                          </Typography>
                        </Link>
                      </FormControl>
                      }

                     
                          <Box >
                            <FormControlLabel
                              checked={Obo == 'on'}
                              value={Obo}
                              control={<Radio />}
                              label={
                                <Box>
                                  <Typography sx={{ gap: '8px' }}>
                                    <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                      OBO:
                                    </span>
                                    <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                                      Or Best Offers.
                                    </span>
                                  </Typography>
                                </Box>
                              }
                              className="radio-grp"
                            />
                          </Box>

                        </RadioGroup>
                      </FormControl>

                      <FormControl className="">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          onClick={HandleRPO}
                          className="radio-grp list-eqp-page"
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <FormControlLabel
                              checked={Rpo == 'on'}
                              value={Rpo}
                              control={<Radio />}
                              label={
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                                  <Typography sx={{ gap: '8px' }}>
                                    <span className='radio-span-yellow' style={{ fontFamily: '', color: '#faa61a !important' }}>
                                      RPO Available:
                                    </span>
                                    <span className='radio-span-white' style={{ fontFamily: '', color: '#fff !important' }}>
                                      The owner may offer this as a rental with purchase option.
                                    </span>
                                  </Typography>
                                </Box>
                              }
                              className="radio-grp"
                            />

                          </Box>
                        </RadioGroup>
                      </FormControl>
                     */}
                    </>
                  )}

                  <Button
                    className="signin-btn update-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      updateSalesEquip();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* by gd for Delete an equipment */}
      {/* after completion */}
      <Modal
        keepMounted
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
            padding: "0px",
          }}>

          <>
            <Box className="login__main">
              <Box className="login-main-inner">
                <Box className="login-fields-div">
                  <Box className="after-msg">
                    <Box className="after-msg-inner">
                      <Typography
                        variant="h4"
                        className="welcome-heading"
                        sx={{ textAlign: "center", color: "#fff" }}
                      >
                      </Typography>

                      <Typography className="second-para" sx={{ fontSize: '20px !important',  }}>
                        Are you sure you want to delete this equipment?
                      </Typography>

                      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', alignItems: 'center', gap: '1rem',  }}>                        
                        <Button onClick={() => setDeletePopup(false)} sx={{ width: '100%', border: '2px solid #faa61a', color: '#fff', textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Cancel</Button>
                        <Button onClick={() => deleteEquipment()} sx={{ width: '100%', border:'2px solid #faa61a', color: '#fff',textTransform: 'capitalize', fontFamily: 'GothamBold !important' }}>Ok</Button>
                      </Box>
                      {/* <Box
                        className="close"
                        onClick={() => deleteEquipment()}
                      >
                        <Box className="close-content">
                          <Typography sx={{ color: '#faa61a !important', fontFamily: 'GothamBold !important', fontSize: '18px !important' }}>Ok</Typography>
                        </Box>
                      </Box>

                      <Box
                        className="close"
                        onClick={() => setDeletePopup(false)}
                      >
                        <Box className="close-content">
                          <Typography sx={{ color: '#faa61a !important', fontFamily: 'GothamBold !important', fontSize: '18px !important' }}>Cancel</Typography>
                        </Box>
                      </Box> */}
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
    </>
  );
};
export default EditEquipment;