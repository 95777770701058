import React, { useCallback, useEffect, useState, useRef } from 'react'
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Backdrop,
  Link,
  Typography,
  Button,
  TextField,
  Modal,
  useMediaQuery,
  IconButton,
  TextareaAutosize,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close'

import { useDispatch, useSelector } from 'react-redux';
import { setalertMessagetype, setalertMessage, setSessionExpire, setNotificationCount, setNotificationList, setNotificationListCount } from 'redux/redux-slice';

import { IApiResponseRawData, IStoreValue } from 'utils/types';
import moment from 'moment';
import './newstyles.scss'
import './styles.scss';

import momentTimeZone from "moment-timezone";

import { convertTimeZoneShortName } from "utils/commonFxns";

import OrderDetailComponent from 'components/common/OrderDetailsComponent';

import CountdownTimer from 'components/common/CountDownTimer';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#b8b9bbfc', //background.paper
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4
}
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const { scrollX, scrollY } = window;
const ChatWindow = ({ ChatData = null, NewMsg = null, MessageAdd = null }) => {
  const dispatch = useDispatch()
  const messagesEndRef = useRef(null)

  const matchesMobileX = useMediaQuery('(max-width:425px)')

  const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
  const UserId = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser)
  const Noticount = useSelector((state: IStoreValue) => state.equipmentReducer.NotificationCount)
  // const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)
  const [loading, setloading] = React.useState(false);
  const [Chatloading, setChatloading] = React.useState(false);
  const [ChatResult, setChatResult] = React.useState([]);
  const [TotalMsg, setTotalMsg] = React.useState(0);
  const [ChatMessage, setChatMessage] = React.useState('');
  const [OrderRequestPopup, setOrderRequestPopup] = React.useState(false);
  const [cancelOrderRequestPopup, setCancelOrderRequestPopup] = useState(false);

  const [OrderRequestClick, setOrderRequestClick] = React.useState(null);

  const [orderDetailPopup, setOrderDetailPopup] = useState(false);

  const [orderDetailPopupData, setOrderDetailPopupData] = useState([]);
  const [paymentExpiredPopup, setPaymentExpiredPopup] = useState(false);
  const [requestExpireMessage, setRequestExpireMessage] = useState("");

  const [orderRequestId, setOrderRequestId] = useState("");

  // Get All data My Office Trailer
  const scrollToBottom = () => {
    var element = document.querySelector('.chats');
    element.scroll({ top: element.scrollHeight, behavior: "smooth" })
  }

  const LoadChat = async (ChatData) => {
    try {
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const ChatResult = await EquipmentAPIs.GetChat(
        {
          user_id: ChatData['senderID'].toString(),
          equip_id: ChatData['equipId'].toString(),
          equipment_user: ChatData['reciverID'].toString(),
          page: 0,
        },
        headers
      );

      if (ChatResult['type'] == "RXSUCCESS") {
        let chat = ChatResult['data']
        chat = chat.reverse();

        chat.map((item) => {
          // console.log("created at==",item.created_at)
          if (
            item.recever_id != UserId &&
            item.message.includes("would like to") &&
            item.message_type == 'request'
          ) {
            item.message = `Your${item.message.includes("extend") ? " extend a" : ""
              } rental request has been submitted for approval.`;
          }
          return item;
        });

        chat = chat.filter(
          (item) =>
            !(
              item.recever_id != UserId &&
              item.message.includes("Your request")
            )
        );

        setTotalMsg(ChatResult['total'])
        setChatResult(chat)
        setTimeout(() => {
          scrollToBottom()
        }, 300);

      }
      else if (ChatResult["status"] == false && ChatResult["slug"] == "Session Expired") {
        dispatch(setSessionExpire(true));
        return false
      }
      else {
        dispatch(setalertMessage(ChatResult["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }

    } catch (err) {
      console.log(err);

    }
  };

  // Add new message chat
  const AddChat = async () => {
    try {
      let Message = ChatMessage.trim();

      setChatloading(true)
      if (Message == '' || Message.length == 0) {
        setChatloading(false)
        dispatch(setalertMessage('Enter message.'));
        dispatch(setalertMessagetype("error"));
        return false;
      }
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const ChatResult = await EquipmentAPIs.SendChatMsg(
        {
          sender_id: UserId.toString(),
          sender_type: "equipment",
          recever_id: ChatData['reciverID'].toString(),
          message: Message,
          equip_id: ChatData['equipId'].toString(),
        },
        headers
      );

      if (ChatResult['type'] == "RXSUCCESS") {
        //LoadChat(ChatData)
        setChatloading(false);
        setChatMessage('')
        MessageAdd(true)
      }
      else if (ChatResult["status"] == false && ChatResult["slug"] == "Session Expired") {
        setChatloading(false)
        dispatch(setSessionExpire(true));
        return false
      }
      else {
        setChatloading(false)
        dispatch(setalertMessage(ChatResult["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }

    } catch (err) {
      console.log(err);
      setChatloading(false);
    }
  };

  const NotificationsUpdate = async () => {
    try {
      //setChatloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdateNotification(
        {
          user_id: UserId.toString(),
        },
        headers
      );
      if (Result['type'] == "RXSUCCESS") {
        //setChatloading(false);
        let old_notification = Noticount;
        if (old_notification > 0) {
          dispatch(setNotificationCount(old_notification - 1))
        }
      }

      else {
        dispatch(setalertMessage(Result['message']));
        dispatch(setalertMessagetype('error'));
        //setChatloading(false);
        return false
      }

    } catch (err) {
      console.log(err);
      //setChatloading(false);
    }
  };

  // Update order request  
  const UpdateOrderRequest = async (request_id, status) => {
    try {
      setloading(true)
      setOrderRequestPopup(false);

      // setRequestId(request_id)
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.UpdateRequest(
        {
          order_request_id: request_id,
          status: status.toString(),
        },
        headers
      );

      if (Result['type'] == "RXSUCCESS") {
        // setRequestStatus(status.toString())
        MessageAdd(true)
        setloading(false)
      }
      // else if (Result["status"] == false && Result["slug"] == "Session Expired") {
      //   setChatloading(false)
      //   dispatch(setSessionExpire(true));
      //   return false
      // }
      else {
        // setRequestStatus('')
        setloading(false)
        if (Result['statusCode']) {
          setPaymentExpiredPopup(true);
        } else {
          setPaymentExpiredPopup(true);
          setRequestExpireMessage(Result["message"]);
        }
        // setChatloading(false)
        // dispatch(setalertMessage(Result["message"]));
        // dispatch(setalertMessagetype("error"));
        // return false;
      }

    } catch (err) {
      // setRequestStatus('')
      setloading(false)
      console.log(err);
      //setChatloading(false);
    }
  };

  const HandleRequest = async (requestId, status, type) => {
    setOrderRequestClick({
      'request_id': requestId,
      'request_status': status,
      'request_type': type,
    })
    setOrderRequestPopup(true);
  }

  const UpdateRequest = async () => {
    let req_Obj = OrderRequestClick;
    // setloading(true)
    UpdateOrderRequest(req_Obj.request_id, req_Obj.request_status)
  }

  const getOrderDetails = async (orderId, type, non) => {
    try {
      const params = {
        order_id: orderId + "",
        type: type,
        non_request: non,
      };
      // console.log("get order detail params",params);
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.getOrderDetails(params, headers);
      if (response.type == "RXSUCCESS") {
        // console.log(response.data[0]);
        if (type === "request_id" || type === "owner") {
          // console.log("equipement details request",response.data[0]);
          const userId = response.data[0]?.["user_id"];
          const equipId = response.data[0]?.["id"];
          if (userId && equipId) {
            window.open(`/myRentalYard/${userId}/${equipId}`, '_blank');
          }
        } else {
          //order details request
          setOrderDetailPopup(true);
          setOrderDetailPopupData(response.data);
        }
      } else {
        throw new Error(response.message);
      }

    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    if (ChatData && ChatData != null && ChatData['senderID'] != null) {
      LoadChat(ChatData)
      // commented by GD due to scrolling on top error every time user hit a chat notification
      // window.scrollTo(0, 100);
      if (NewMsg && NewMsg != null) {
        if (ChatData['equipId'] == NewMsg['equipId'] && ChatData['senderID'] == NewMsg['reciverID'] && ChatData['reciverID'] == NewMsg['senderID']) {
          let old_count = Noticount;
          if (old_count > 0) {
            NotificationsUpdate();
            dispatch(setNotificationCount(old_count - 1))
          }
        }
      }
    }

  }, [ChatData, NewMsg]);


  const cancelOrderByRenter = async () => {
    try {
      setCancelOrderRequestPopup(false)
      setloading(true);
      const data = {
        order_id: orderRequestId?.toString(),
        user_id: UserId.toString(),
        reason: "Cancelled by renter",
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrder(
        data,
        headers
      );
      if (Result.type === "RXSUCCESS") {
        dispatch(setalertMessage("Order cancelled successfully."));
        dispatch(setalertMessagetype("success"));
        MessageAdd(true)
      } else {
        setRequestExpireMessage(Result?.message)
        setPaymentExpiredPopup(true);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setloading(false)
    }
  }

  const RenterTimerComp = ({ msgobj, setOrderRequestId }) => {
    const shouldShow = msgobj["expire_at"] && msgobj["order_request_status"] === "0";
    return (
      <div>
        {shouldShow && (
          <>
            <Box
              className="chat-btn-grp"
              sx={{
                marginTop: "20px !important",
              }}
            >
              <Button
                sx={{
                  width: "60%",
                  fontWeight: 700,
                  padding: "0px 0px !important",
                  backgroundColor: "rgb(161, 1, 1) !important",
                  borderRadius: "5px"
                }}
                type="button"
                variant="contained"
                onClick={() => {
                  setOrderRequestId(msgobj["renter_order_id"])
                  setCancelOrderRequestPopup(true)
                }}
              >
                Cancel Request
              </Button>
            </Box>

            <div className="notification-timer-div" style={{ lineHeight: "15px" }}>
              <span>Note: </span>
              <span>{msgobj["rfn"].charAt(0).toUpperCase() + msgobj["rfn"].slice(1)} has</span>
              <CountdownTimer expirationDateTime={msgobj["expire_at"]} color='#fff' />
              <span>&nbsp;to accept this offer before it is withdrawn automatically</span>
            </div>
          </>
        )}
      </div>
    );
  };


  return (
    <>
      <Box className="rentyrd-grid-item rentyrd-right">
        <Box className="rentyrd-left-float">
          <Box className="equipment-details-heading">
            <Typography
              sx={{
                color: "#fff",
                textAlign: "center",
                fontSize: "clamp(16px, 3vw , 25px)",
                fontFamily: "GothamBold !important",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {ChatData?.EquipMake}
            </Typography>
          </Box>
          <Box className="chat-name">
            <Typography>Message from {ChatData?.reciverName}</Typography>
          </Box>
          <Box className="chat-box">
            {/* {Chatloading && (              
              <div>
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={Chatloading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>             
            )} */}

            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>

            <Box className="chats">
              <Box className="chats-inner">
                {TotalMsg > 0 ? (
                  <>
                    {ChatResult.map((msgobj, indx) => (
                      <>
                        <span ref={messagesEndRef}>
                          {msgobj["sender_id"] == ChatData["senderID"] ? (
                            <>
                              <Box className="chat-me">
                                <Typography>
                                  {/* Added on 18 Jan 2024 MK */}
                                  {/* {(msgobj["message"].includes("would like to")) ? ( */}
                                  {/* condition changed by GD 18 march 2024 and cond also applied on load chat api */}
                                  {msgobj["message_type"] == "request" &&
                                    msgobj["message"].includes(
                                      "would like to"
                                    ) ? (
                                    <>
                                      <Box>
                                        Your{" "}
                                        {msgobj["message"].includes("extend")
                                          ? " extend a"
                                          : ""}{" "}
                                        rental request has been submitted for
                                        approval
                                      </Box>
                                      <RenterTimerComp msgobj={msgobj} setOrderRequestId={setOrderRequestId} />
                                    </>
                                  ) : (
                                    <>
                                      <Box>
                                        {msgobj["message"]}
                                      </Box>
                                      <RenterTimerComp msgobj={msgobj} setOrderRequestId={setOrderRequestId} />
                                    </>
                                  )}

                                  {msgobj["order_id"] != null && (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"flex-end"}
                                      onClick={() =>
                                        getOrderDetails(
                                          msgobj["order_id"],
                                          "owner",
                                          "non_request"
                                        )
                                      }
                                    >
                                      <Link
                                        sx={{
                                          fontSize: "clamp(10px, 2vw, 18px)",
                                          color: "black",
                                          textDecorationColor: "black",
                                          padding: "0.5rem 0",
                                          textAlign: "right",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Click here to advance this order.
                                      </Link>
                                    </Box>
                                  )}

                                  {/* Added on 18 Jan 2024 MK */}
                                  {/* {msgobj["message"]} */}
                                  <span>
                                    {/* {moment(msgobj["created_at"]).format("MM-DD-YYYY") ==
                                      moment(new Date()).format("MM-DD-YYYY")
                                      ? moment(msgobj["created_at"]).format("hh:mm a")
                                      : moment(msgobj["created_at"]).format("hh:mm a MM-DD-YYYY")} */}
                                    {/* above line is commented and next line added by pp on 25 Apr 2024 due to timezone changes*/}
                                    {msgobj["sender_timezone"]
                                      ? convertTimeZoneShortName(
                                        momentTimeZone
                                          .tz(
                                            msgobj["created_at"],
                                            msgobj["sender_timezone"]
                                          )
                                          .format("hh:mm A (z) MM-DD-YY")
                                      )
                                      : moment(msgobj["created_at"]).format(
                                        "hh:mm A (UTC) MM-DD-YY"
                                      )}
                                  </span>
                                </Typography>
                              </Box>
                            </>
                          ) : (
                            <Box className="chat-not-me">
                              <Typography>
                                {msgobj["message"]}
                                <span>
                                  {msgobj["order_id"] != null && (
                                    <Box
                                      display={"flex"}
                                      justifyContent={"flex-end"}
                                      onClick={() =>
                                        getOrderDetails(
                                          msgobj["order_id"],
                                          "",
                                          ""
                                        )
                                      }
                                    >
                                      <Link
                                        sx={{
                                          fontSize: "clamp(10px, 2vw, 18px)",
                                          color: "blue",
                                          textDecorationColor: "blue",
                                          padding: "0.5rem 0",
                                          textAlign: "right",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Click Here for Order Details
                                      </Link>
                                    </Box>
                                  )}

                                  {msgobj["order_request_id"] != undefined &&
                                    msgobj["order_request_id"] != null && (
                                      <>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"flex-end"}
                                        >
                                          <Link
                                            href={
                                              "/order-request/rental-summary/" +
                                              msgobj["order_request_id"] +
                                              "/" +
                                              UserId
                                            }
                                            target="_blank"
                                            sx={{
                                              fontSize:
                                                "clamp(10px, 2vw, 18px)",
                                              color: "blue",
                                              textDecorationColor: "blue",
                                              padding: "0.5rem 0",
                                              textAlign: "right",
                                            }}
                                          >
                                            Click Here for Details
                                          </Link>
                                        </Box>

                                        {msgobj["order_request_status"] !=
                                          null &&
                                          msgobj["order_request_status"] ==
                                          "1" && (
                                            <Box
                                              display={"flex"}
                                              justifyContent={"flex-end"}
                                              onClick={() =>
                                                getOrderDetails(
                                                  msgobj["order_request_id"],
                                                  "owner",
                                                  ""
                                                )
                                              }
                                            >
                                              <Link
                                                sx={{
                                                  fontSize:
                                                    "clamp(10px, 2vw, 18px)",
                                                  color: "blue",
                                                  textDecorationColor: "blue",
                                                  padding: "0.5rem 0",
                                                  textAlign: "right",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Click here to advance this
                                                order.
                                              </Link>
                                            </Box>
                                          )}

                                        {msgobj["order_request_status"] ==
                                          "0" ? (
                                          <>
                                            <Box
                                              className="chat-btn-grp"
                                              sx={{
                                                marginTop: "20px !important",
                                              }}
                                            >
                                              <Button
                                                className="reject-btn"
                                                type="button"
                                                variant="contained"
                                                onClick={(e) => {
                                                  HandleRequest(
                                                    msgobj["order_request_id"],
                                                    "2",
                                                    "Reject"
                                                  );
                                                  //UpdateOrderRequest(msgobj['order_request_id'], "2")
                                                }}
                                              >
                                                Reject
                                              </Button>
                                              <Button
                                                className="accept-btn"
                                                type="button"
                                                variant="contained"
                                                onClick={(e) => {
                                                  HandleRequest(
                                                    msgobj["order_request_id"],
                                                    "1",
                                                    "Accept"
                                                  );
                                                  //UpdateOrderRequest(msgobj['order_request_id'], "1")
                                                }}
                                              >
                                                Accept
                                              </Button>
                                            </Box>
                                            {msgobj["expire_at"] && <div className="notification-timer-div">
                                              <span>Note: </span>
                                              <span>
                                                The rental request will automatically cancel if not accepted in next
                                              </span>
                                              <CountdownTimer expirationDateTime={msgobj["expire_at"]} />
                                              {/* <span>
                                                &nbsp;
                                                {msgobj["sender_timezone"]
                                                  ? momentTimeZone
                                                      .tz(
                                                        msgobj["expire_at"],
                                                        msgobj[
                                                          "sender_timezone"
                                                        ]
                                                      )
                                                      .format("MM/DD/YY")
                                                  : moment(
                                                      msgobj["expire_at"]
                                                    ).format("MM/DD/YY")}
                                              </span>
                                              <span> at </span>
                                              <span>
                                                {msgobj["sender_timezone"]
                                                  ? momentTimeZone
                                                      .tz(
                                                        msgobj["expire_at"],
                                                        msgobj[
                                                          "sender_timezone"
                                                        ]
                                                      )
                                                      .format("hh:mm A")
                                                  : moment(
                                                      msgobj["expire_at"]
                                                    ).format("hh:mm A")}
                                              </span> */}
                                            </div>
                                            }
                                          </>
                                        ) : (
                                          <>
                                            <Box className="chat-btn-grp">
                                              {msgobj["order_request_status"] ==
                                                "1" ? (
                                                <Button
                                                  className="accepted-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{ width: "65%" }}
                                                >
                                                  Accepted
                                                </Button>
                                              ) : msgobj[
                                                "order_request_status"
                                              ] == "2" ? (
                                                <Button
                                                  className="rejected-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{ width: "65%" }}
                                                >
                                                  Rejected
                                                </Button>
                                              ) : msgobj[
                                                "order_request_status"
                                              ] == "4" ? (
                                                <Button
                                                  className="rejected-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{ width: "100" }}
                                                >
                                                  This rental request has expired
                                                </Button>) : (
                                                <Button
                                                  className="rejected-btn"
                                                  type="button"
                                                  variant="contained"
                                                  sx={{ width: "80%" }}
                                                >
                                                  Cancelled by Renter
                                                </Button>
                                              )}
                                            </Box>
                                          </>
                                        )}
                                        <Box
                                          sx={{
                                            fontSize: "clamp(9px, 2vw, 15px)",
                                            color: "#111111",
                                            display: "flex",
                                            flexDirection: "column",
                                            flexWrap: "wrap",
                                            marginBottom: "10px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Message renter directly within this
                                          chat.
                                        </Box>
                                      </>
                                    )}
                                  {/* 
                                  // moment(msgobj["created_at"]).format("MM-DD-YYYY") ==
                                  //   moment(new Date()).format("MM-DD-YYYY")
                                  //   ? moment(msgobj["created_at"]).format("hh:mm a")
                                    // : moment(msgobj["created_at"]).format("hh:mm a MM-DD-YYYY")
                                   */}
                                  {/* next line added by pp on 25 apr 2024 due to timezone change and upper lines commented */}
                                  {msgobj["sender_timezone"]
                                    ? convertTimeZoneShortName(
                                      momentTimeZone
                                        .tz(
                                          msgobj["created_at"],
                                          msgobj["sender_timezone"]
                                        )
                                        .format("hh:mm A (z) MM-DD-YY")
                                    )
                                    : moment(msgobj["created_at"]).format(
                                      "hh:mm A (UTC) MM-DD-YY"
                                    )}
                                </span>
                              </Typography>
                            </Box>
                          )}
                        </span>
                      </>
                    ))}
                  </>
                ) : (
                  <Typography
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      fontSize: "clamp(16px, 3vw , 25px)",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    No message
                  </Typography>
                )}
              </Box>
            </Box>

            <Box className="chat-msg-box">
              {/* <TextField
                className="type-msg"
                placeholder="Message here"
                value={ChatMessage}
                //onChange={SubmitChatMessage}
                onChange={(e) => {
                  setChatMessage(e.target.value)
                }}
                autoFocus
                onKeyPress={event => {
                  if(Chatloading) return;
                  if (event.key === 'Enter') {
                    AddChat()
                  }
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#2e2e2e",
                    background: "#B8B9BB",
                    fontFamily: "GothamBold !important",
                    fontSize: "clamp(14px, 3vw, 18px) !important",
                  },
                  "& label.Mui-focused": {
                    color: "#2e2e2e",
                    fontWeight: "bold",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "#2e2e2e",
                      border: "none !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "none !important",
                    },
                  },
                }}
              /> */}
              <TextareaAutosize
                value={ChatMessage}
                //onChange={SubmitChatMessage}
                onChange={(e) => {
                  setChatMessage(e.target.value);
                }}
                autoFocus
                onKeyDown={(event) => {
                  if (Chatloading) return;
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault();
                    AddChat();
                  }
                }}
                aria-label="empty textarea"
                placeholder="Message here"
                minRows={2}
                style={{
                  width: "97%",
                  resize: "none",
                  color: "#2e2e2e",
                  background: "#B8B9BB",
                  fontFamily: "GothamBold !important",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  fontSize: "18px",
                  padding: "18px 0px 0px 16px",
                  maxHeight: "42px",
                }}
              />
              {Chatloading ? (
                <CircularProgress />
              ) : (
                <Button className="send-btn" onClick={AddChat}>
                  Send
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        keepMounted
        open={OrderRequestPopup}
        onClose={() => setOrderRequestPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup"
          sx={{
            ...style,
            width: matchesMobileX ? "calc(100vw - 100px)" : 400,
            padding: matchesMobileX ? "20px" : 4,
            bgcolor: "#2d2d2d",
            borderRadius: "10px",
          }}
        >
          <IconButton
            onClick={() => setOrderRequestPopup(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            className="download-text"
            id="keep-mounted-modal-title"
            component="p"
            sx={{
              textAlign: "center",
              color: "#fff !important",
              fontSize: "20px !important",
              fontFamily: "GothamBold !important",
            }}
          >
            {OrderRequestPopup && OrderRequestClick.request_type == "Reject" ? (
              <>Are you sure you want to REJECT?</>
            ) : (
              <>Are you sure you want to ACCEPT?</>
            )}
          </Typography>

          <Box className="chat-not-me">
            <Typography>
              <span>
                <Box className="chat-btn-grp">
                  <Button
                    className="reject-btn"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setOrderRequestPopup(false);
                    }}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="accept-btn"
                    type="button"
                    variant="contained"
                    onClick={UpdateRequest}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    YES
                  </Button>
                </Box>
              </span>
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* cancel order confirmation */}
      <Modal
        keepMounted
        open={cancelOrderRequestPopup}
        onClose={() => setCancelOrderRequestPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popup"
          sx={{
            ...style,
            width: matchesMobileX ? "calc(100vw - 100px)" : 400,
            padding: matchesMobileX ? "20px" : 4,
            bgcolor: "#2d2d2d",
            borderRadius: "10px",
          }}
        >
          <IconButton
            onClick={() => setCancelOrderRequestPopup(false)}
            sx={{ position: "absolute", right: 0, top: 0, color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            className="download-text"
            id="keep-mounted-modal-title"
            component="p"
            sx={{
              textAlign: "center",
              color: "#fff !important",
              fontSize: "20px !important",
              fontFamily: "GothamBold !important",
            }}
          >
            Are you sure you want to CANCEL?
          </Typography>

          <Box className="chat-not-me">
            <Typography>
              <span>
                <Box className="chat-btn-grp">
                  <Button
                    className="reject-btn"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      setCancelOrderRequestPopup(false);
                    }}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="accept-btn"
                    type="button"
                    variant="contained"
                    onClick={cancelOrderByRenter}
                    sx={{
                      fontSize: "16px !important",
                      fontFamily: "GothamBold !important",
                    }}
                  >
                    YES
                  </Button>
                </Box>
              </span>
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* Order Detail Popup Section */}
      <Modal
        keepMounted
        open={orderDetailPopup}
        onClose={() => setOrderDetailPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form popup-cmmn pupup-scroll edit-eqp-inner">
                <span onClick={() => setOrderDetailPopup(false)}>
                  <CloseIcon className="modal-popup" />
                </span>
                <Typography variant="h5">
                  <span style={{ color: "#faa61a" }}>Order</span>Detail
                </Typography>

                <Box className="extend-rental-od">
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                  {orderDetailPopupData.length && (
                    <OrderDetailComponent orderObject={orderDetailPopupData} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Box>
        <Dialog
          open={paymentExpiredPopup}
          // open={true}
          aria-labelledby="draggable-dialog-title"
        >
          <Box className="session-expire">
            <DialogContent>
              <DialogTitle
                style={{
                  color: "#fff",
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "-32px",
                }}
              >
                Error
              </DialogTitle>
              <DialogContentText style={{ color: "#fff", fontSize: "18px" }}>
                {requestExpireMessage ? requestExpireMessage : "This Rental request is no longer valid."}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className=""
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#de8d14 !important",
                  color: "#fff !important",
                  padding: "6px 50px 6px 50px",
                  borderRadius: "7px",
                  fontFamily: "GothamBold !important",
                  fontSize: "20px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // UpdateOrderRequest(OrderRequestClick?.request_id, "2");
                  MessageAdd(true)
                  setPaymentExpiredPopup(false);
                  console.log("order request expired");
                }}
              >
                OKAY
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </>
  );
}

export default ChatWindow