//https://javascript.plainenglish.io/push-notifications-with-react-and-firebase-8f7cf9372ac7
import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
const firebaseConfig = {   
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    messagingSenderId: process.env.REACT_APP_SENDERID,
    appId: process.env.REACT_APP_APPID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
       
      return window.navigator.serviceWorker
        .getRegistration('/firebase-push-notification-scope')
        .then((serviceWorker) => {           
          if (serviceWorker) return serviceWorker;
          return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            scope: '/firebase-push-notification-scope',
          });
        });
    }
    throw new Error('The browser doesn`t support service worker.');
  };
  
  export const getFirebaseToken = () =>
    getOrRegisterServiceWorker()
      .then((serviceWorkerRegistration) =>
        getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY, serviceWorkerRegistration }));
        


export const onMessageListener = () =>
    new Promise((resolve) => {      
    onMessage(messaging, (payload) => {
       console.log('payloads',payload);
      resolve(payload);
    });
});

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) =>  
   resolve(payload)));
   
   