import React, { useEffect, } from "react";
import MainLayout from '../../layouts/MainLayout'
import { Box, Container, Typography, Link } from '@mui/material'
import './styles.scss'
import HandsImg from '../../assets/images/hand_holding_cellphone.png'
import Playstore from '../../assets/images/download_googleplay.png'
import AppStore from '../../assets/images/download_appstore.png'
//import AppQR from '../../assets/images/app_stpre_code.png'
import QRCode from "react-qr-code";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import { useDispatch, useSelector } from "react-redux";

const DownloadPage = () => {
    const [loading, setloading] = React.useState(false);
    const OnelinkQaCode = useSelector((state: IStoreValue) => state.equipmentReducer.qrCodeValue);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MainLayout>
                <Box className="app-download">
                    <Container>
                        <Box className="app-download-main">
                            <Typography className="app-download-heading" variant="h1">
                                Download the App
                            </Typography>
                            <Typography className="app-download-subheading">
                                A Rental Yard that brings equipment owners and renters together...
                                all in your back pocket.
                            </Typography>
                            <Box className="app-download-grid">
                                <Box className="app-download-grid-item-left">
                                    <img src={HandsImg} alt="" />
                                </Box>
                                <Box className="app-download-grid-item-right">
                                    <Box className="app-download-grid-item-right-grid">
                                        <Box className="app-download-grid-item-right-grid-item-left">
                                            <Link
                                                className="nav-link desktop-visible"
                                                target="_blank"
                                                href="https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874"
                                            >
                                                <img src={AppStore} alt="" />
                                            </Link>
                                            <Link
                                                className="nav-link desktop-visible"
                                                target="_blank"
                                                href="https://play.google.com/store/apps/details?id=com.goequipeme"
                                            >
                                                <img src={Playstore} alt="" />
                                            </Link>
                                        </Box>
                                        <Box className="app-download-grid-item-right-grid-item-right">
                                            {/* <img src={AppQR} alt="" /> */}

                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "170px", border :"4px solid #fff" }}
                                                value={OnelinkQaCode}
                                                viewBox={`0 0 256 256`}
                                            />

                                        </Box>
                                    </Box>
                                    <Typography className="app-download-p">
                                        Available for Android and iPhone
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </MainLayout>
        </>
    )
}
export default DownloadPage