import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Box, CircularProgress,  Typography, Backdrop, } from '@mui/material';
import ProfilePic from './../../assets/images/dummy-profile-pic.jpeg';

import './styles.scss'

const Userinfo = ({ userData }) => {
    const [loading, setloading] = React.useState(false);
    const [UserInfoData, setUserInfoData] = React.useState(null);

    useEffect(() => {
        if (userData && userData != null) {
            setUserInfoData(userData)           
        }
    }, [userData]);

    return (
        <>
            {loading && (
                <div>
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            )}

            {UserInfoData != null && (
            <Box className="rentyrd-dates renter-modal">
                <Box className="rentyrd-dates-item rental-dates renter-imag-box">
                    <img src={UserInfoData['profile_pic'] ? UserInfoData['profile_pic'] : ProfilePic}/>
                </Box>
                <Box className="rentyrd-dates-item rental-dates renter-text-box">
                    <Typography className='renter-text-p'
                    sx={{ color: "#fff"}}
                    >
                        <span>Name: {UserInfoData.first_name.charAt(0).toUpperCase() + UserInfoData.first_name.slice(1)} {UserInfoData.last_name}</span>
                    </Typography>
                    <Typography className='renter-text-p'
                    sx={{ color: "#fff"}}>
                        <span>Email: {UserInfoData.email}</span>
                    </Typography>
                    <Typography className='renter-text-p'
                    sx={{ color: "#fff"}}>
                        <span>Contact No: {UserInfoData.phone_no}</span>
                    </Typography>
                </Box>
            </Box>
    )}
        </>
    );
}

export default Userinfo