import {
  Box,
  CircularProgress,
  Backdrop,
  Link,
  Typography,
  Button,
  TextField,
  Modal,
  useMediaQuery,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import ProfilePic from "../../assets/images/dummy-profile-pic.jpeg";
import ExtendedOrderDetail from "./Extendorderdetail";
import { EquipmentAPIs } from "../../service/api-service";
function OrderDetailComponent({ orderObject }) {
  const [OrderDetailPopData, setOrderDetailPopData] = useState({
    thumbnail: "",
    id: "",
    make: "",
    model: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    profile_pic: "",
    first_name: "",
    order_status: "",
    order_from: "",
    rental_price: 0,
    minimum_delivery_fees: 0,
    security_deposit_refund_txt: "",
    security_deposit: 0,
    grand_total_txt: "",
    grand_total: 0,
    protection_fee: 0,
    transport_fee: 0,
    admin_fee: 0,
    side: "owner",
  });
  const [ActiveOrder, setActiveOrder] = useState([]);
  const [UserInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const getEquipementDetails = async (orderObj) => {
    try {
      setLoading(true);
      const response = await EquipmentAPIs.viewEquipment({
        id: orderObj["equip_id"],
      });
      let EquipData = {};
      if (response.data.rowCount && response.data.rows?.[0]?.[0]) {
        EquipData = { ...response.data.rows[0][0] };
      }
      // Extract extended orders and set active order list
      if (orderObj["order_extend"]) {
        setActiveOrder(orderObj["order_extend"]);
      }

      // Prepare user information for display
      let user_info = {
        profile_pic: orderObj["profile_pic"]
          ? orderObj["profile_pic"]
          : ProfilePic,
        first_name: orderObj["first_name"] ? orderObj["first_name"] : "NA",
      };
      setUserInfo(user_info);

      // Calculate refunded protection, security deposit, protection fee, transport fee, admin fee, and grand total
      let refunded_protection =
        orderObj["refunded_protection"] != undefined ||
        orderObj["refunded_protection"] != ""
          ? orderObj["refunded_protection"]
          : "";

      let security =
        orderObj["returnd_deposit"] != undefined ||
        orderObj["returnd_deposit"] != null
          ? orderObj["security_deposit"] - orderObj["returnd_deposit"]
          : orderObj["security_deposit"];
      let protection_fee =
        orderObj["protection_fees_refunded"] == "1"
          ? orderObj["equipment_protection_fees"] -
            (refunded_protection == ""
              ? orderObj["equipment_protection_fees"]
              : refunded_protection)
          : orderObj["equipment_protection_fees"];
      let transaport =
        orderObj["delivery_charges"] * 2 + orderObj["minimum_delivery_fees"];

      let adminfee =
        orderObj["admin_commission"] +
        (orderObj["minimum_delivery_fees"] != undefined &&
          orderObj["minimum_delivery_fees"] != null &&
          orderObj["minimum_delivery_fees"] != "0" &&
          orderObj["delivery_address"] != "" &&
          parseFloat(orderObj["admin_transport_commission"]));

      let grandTotal =
        orderObj["protection_fees_refunded"] != "1"
          ? orderObj["admin_commission"] != null
            ? orderObj["total_price"]
            : orderObj["total_price"]
          : orderObj["admin_commission"] != null
          ? orderObj["total_price"] -
            (orderObj["order_status"] == "order_canceled"
              ? orderObj["security_deposit"]
              : 0)
          : orderObj["total_price"];

      // Set data for order detail popup
      setOrderDetailPopData({
        thumbnail: EquipData["thumbnail"],
        id: "Order ID #" + orderObj["id"],
        make: EquipData["make"] ? EquipData["make"] : "NA",
        model: EquipData["model"] ? EquipData["model"] : "",
        start_date: orderObj["start_date"],
        start_time: orderObj["start_time"],
        end_date: orderObj["end_date"],
        end_time: orderObj["end_time"],
        profile_pic: orderObj["profile_pic"]
          ? orderObj["profile_pic"]
          : ProfilePic,
        first_name: orderObj["first_name"] ? orderObj["first_name"] : "NA",
        order_status: orderObj["order_status"],
        order_from: orderObj["delivery_address"] == "" ? "Renter" : "Owner",
        rental_price: orderObj["price"],
        minimum_delivery_fees: orderObj["minimum_delivery_fees"],
        security_deposit_refund_txt:
          orderObj["returnd_deposit"] != undefined ||
          orderObj["returnd_deposit"] != null
            ? "(Refunded" +
              "-$" +
              new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(orderObj["returnd_deposit"]) +
              ")"
            : "",
        security_deposit: security,
        grand_total_txt:
          orderObj["order_status"] == "order_canceled" ? "(Refunded)" : "",
        grand_total: grandTotal,
        protection_fee: protection_fee,
        transport_fee: transaport,
        admin_fee: adminfee,
        side: "owner",
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderObject.length) {
      const orderObj = orderObject[0];
      getEquipementDetails(orderObj);
    }
  }, [orderObject]);

  return (
    <>
      {loading && (
        <Box>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer - 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box className="vehicle-div">
        <Box className="vehicle-img">
          <img
            src={OrderDetailPopData["thumbnail"]}
            alt=""
            style={{ borderRadius: "0px !important" }}
          />
        </Box>
        <Box sx={{ fontWeight: "bold" }}>
          <Typography
            title={
              OrderDetailPopData.make
                ? OrderDetailPopData.make
                : "Not Available"
            }
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {OrderDetailPopData.make
              ? OrderDetailPopData.make
              : "Not Available"}
          </Typography>
          <Typography style={{ color: "#faa61a" }}>
            {OrderDetailPopData.model
              ? OrderDetailPopData.model
              : "Not Available"}
          </Typography>
          <Typography>{OrderDetailPopData.id}</Typography>
        </Box>
      </Box>

      <Box className="owner-div owner-div-new edit-eq-owner">
        <Box className="owner-edit-dates">
          <Typography>Rental Dates</Typography>
          <Typography className="modal-dates-grid">
            <span>Start:</span>
            <span>
              {moment(OrderDetailPopData.start_date, ["YYYY-MM-DD"]).format(
                "MM/DD"
              )}{" "}
              {moment(OrderDetailPopData.start_time, ["HH:mm:ii"]).format(
                "hh:mm a"
              )}
            </span>
          </Typography>
          <Typography className="modal-dates-grid">
            <span>End:</span>
            <span>
              {moment(OrderDetailPopData.end_date, ["YYYY-MM-DD"]).format(
                "MM/DD"
              )}{" "}
              {moment(OrderDetailPopData.end_time, ["HH:mm:ii"]).format(
                "hh:mm a"
              )}
            </span>
          </Typography>
        </Box>
        <Box sx={{ fontWeight: "bold" }} className="owner-img-grid">
          <Box className="owner-img">
            <img
              src={OrderDetailPopData.profile_pic}
              alt=""
              style={{ width: "20%" }}
            />
          </Box>

          <Box className="ro-name">
            <Typography sx={{ display: "flex", flexDirection: "column" }}>
              RENTER :
              <span className="gem-ro-name">
                {OrderDetailPopData.first_name.charAt(0).toUpperCase() +
                  OrderDetailPopData.first_name.slice(1)}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>

      <hr className="yellow-line" />
      <Box className="equipment-rate-div">
        <Box className="equipment-rate-inner">
          <Box
            className="rate-list-grid"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff !important" }}>
              Status :{" "}
              <span
                style={{
                  color: "#faa61a",
                  textTransform: "uppercase",
                }}
              >
                {OrderDetailPopData.order_status == "completed" ||
                OrderDetailPopData.order_status == "security_settled"
                  ? "COMPLETE"
                  : OrderDetailPopData.order_status == "order_canceled"
                  ? "Cancelled"
                  : OrderDetailPopData.order_status == "payment_complete"
                  ? "pending"
                  : "Active"}
              </span>
            </Typography>
          </Box>
          <Box
            className="rate-list-grid"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff" }}>
              Transport : {OrderDetailPopData.order_from}
            </Typography>
          </Box>
          <Box
            className="rate-list-grid"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff" }}>Rental Price</Typography>
            <Typography
              sx={{ color: "#fff", flex: "1" }}
              className="dash-black-border"
            ></Typography>
            <Typography sx={{ color: "#fff" }}>
              $
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.rental_price)}
            </Typography>
          </Box>
          <Box
            className="rate-list-grid"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff" }}>
              Security Deposit {OrderDetailPopData.security_deposit_refund_txt}
            </Typography>
            <Typography
              sx={{ color: "#fff", flex: "1" }}
              className="dash-black-border"
            ></Typography>
            <Typography sx={{ color: "#fff" }}>
              $
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.security_deposit)}
            </Typography>
          </Box>

          <Box
            className="rate-list-grid"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {OrderDetailPopData.order_from != "Renter" && (
              <>
                <Typography sx={{ color: "#fff" }}>
                  Transport Charges
                </Typography>
                <Typography
                  sx={{ color: "#fff", flex: "1" }}
                  className="dash-black-border"
                ></Typography>
                <Typography sx={{ color: "#fff" }}>
                  $
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(OrderDetailPopData.transport_fee)}
                </Typography>
              </>
            )}
          </Box>
          <Box
            className="rate-list-grid"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff" }}>Admin Fee</Typography>
            <Typography
              sx={{ color: "#fff", flex: "1" }}
              className="dash-black-border"
            ></Typography>
            <Typography sx={{ color: "#fff" }}>
              -$
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.admin_fee)}
            </Typography>
          </Box>
          {OrderDetailPopData.order_status == "order_canceled" && (
            <Box
              className="rate-list-grid"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#faa61a" }}>
                Order has been Cancelled
              </Typography>
            </Box>
          )}

          <Box
            className="rate-list-grid g-total"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff" }}>
              TOTAL EARNED {OrderDetailPopData.grand_total_txt}
            </Typography>
            <Typography
              sx={{ color: "#fff", flex: "1" }}
              className="dash-black-border"
            ></Typography>
            <Typography sx={{ color: "#fff" }}>
              $
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.grand_total)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {ActiveOrder.length > 0 && (
        <ExtendedOrderDetail
          ExtendedOrder={ActiveOrder}
          UserInfo={UserInfo}
          Success={(data) => {
            if (data) {
            }
          }}
        />
      )}
    </>
  );
}

export default OrderDetailComponent;
