import React, { useCallback, useEffect, useState, useRef } from 'react'
import { EquipmentAPIs } from "../../service/api-service";
import { Box, CircularProgress, Link, Typography, Backdrop } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { setalertMessagetype, setalertMessage, setSessionExpire, setNotificationCount, setNotificationList, setNotificationListCount } from 'redux/redux-slice';
import { IApiResponseRawData, IStoreValue } from 'utils/types';

import './styles.scss';

import moment from 'moment';
import './newstyles.scss'
import ChatWindow from '../ChatWindow/ChatWindow'
import gemArrow from 'assets/images/gem-arrow.png'
import OrderDetail from '../OrderDetail/VieworderDetail'
import Img from './../../assets/images/dummy-profile-pic.jpeg';
import ExtendedOrderDetail from '../common/Extendorderdetail';
import { updateChatThread } from 'utils/updateChatThread';

import { useParams } from 'react-router-dom';

import momentTimeZone from 'moment-timezone';

import { convertTimeZoneShortName } from 'utils/commonFxns';

const Notification = ({ OpenChatData = null }) => {
    const dispatch = useDispatch()
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken)
    const userId = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser)
    const AuthProfileData = useSelector((state: IStoreValue) => state.equipmentReducer.AuthProfileData)
    const TotalNotifications = useSelector((state: IStoreValue) => state.equipmentReducer.NotificationCount)
    //Get Notification listing
    const NotificationsList = useSelector((state: IStoreValue) => state.equipmentReducer.NotificationList)
    // Get Notification list count total results
    const NotificationsListCount = useSelector((state: IStoreValue) => state.equipmentReducer.NotificationListCount)

    const [NewMsg, setNewMsg] = React.useState(null);
    const [ActiveNotification, setActiveNotification] = React.useState(null);

    const [loading, setloading] = React.useState(false);
    const [TotalResults, setTotalResults] = React.useState(0);
    const [Page, setPage] = React.useState(0);
    const [NotificationResult, setNotificationResult] = React.useState([]);
    const [NotificationType, setNotificationType] = React.useState(null);

    const [ChatData, setChatData] = React.useState(null);

    const [DisplayMsg, setDisplayMsg] = React.useState(null);

    const [RenterExOrders, setRenterExOrders] = React.useState([]);

    const [OrderDetailPopData, setOrderDetailPopData] = useState({
        "thumbnail": "",
        "id": "",
        "make": "",
        "model": "",
        "start_date": "",
        "start_time": "",
        "end_date": "",
        "end_time": "",
        "profile_pic": "",
        "first_name": "",
        "order_status": "",
        "order_from": "",
        "rental_price": 0,
        "minimum_delivery_fees": 0,
        "security_deposit_refund_txt": "",
        "security_deposit": 0,
        "grand_total_txt": "",
        "grand_total": 0,
        "protection_fee": 0,
        "transport_fee": 0,
        "admin_fee": 0,
        "side": "owner",
        "extend_orders": [],
        "timeZone":"UTC"
    });

    const notificationsObj = useParams()

    const FetchNotifications = async (user_id, Page) => {
        try {
            setloading(true);
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const NotificationData = await EquipmentAPIs.GetNotifications(
                {
                    user_id: user_id.toString(),
                    page: Page
                },
                headers
            );

            if (NotificationData['type'] == "RXSUCCESS") {
                setloading(false);
                setTotalResults(NotificationData.total);
                let msg = [...NotificationsList, ...NotificationData['data']]
                setNotificationResult(msg)

            }
            else if (NotificationData["status"] == false && NotificationData["slug"] == "Session Expired") {
                dispatch(setSessionExpire(true));
                setloading(false);
                return false
            }
            else {
                dispatch(setalertMessage(NotificationData['message']));
                dispatch(setalertMessagetype('error'));
                setloading(false);
                return false
            }

        } catch (err) {
            console.log(err);
            setloading(false);
        }
    };

    const LoadNotificationList = async (user_id, token) => {
        try {
            const headers = { Authorization: `${token}`, request_from: "Web" };
            const NotificationData = await EquipmentAPIs.GetNotifications(
                {
                    user_id: user_id.toString(),
                    page: 0
                },
                headers
            );
            //console.log("NotificationData :" , NotificationData);
            
            if (NotificationData['type'] == "RXSUCCESS") {
                dispatch(setNotificationList(NotificationData.data))
                dispatch(setNotificationListCount(NotificationData.total))

                //console.log('total', NotificationData.data.length);
                //setNotificationResult(NotificationData.data)
            }
            else {
            }
        } catch (err) {
            console.log(err);
        }
    }

    const FetchNotificationcount = async (user_id, token) => {
        try {
            const headers = { Authorization: `${token}`, request_from: "Web" };
            const NotificationData = await EquipmentAPIs.GetNotificationsCount(
                {
                    user_id: user_id.toString(),
                },
                headers
            );
            if (NotificationData['type'] == "RXSUCCESS") {
                let count = NotificationData.data.total_notification;
                if (TotalNotifications != count) {
                    dispatch(setNotificationCount(count))                    
                    //Load notification listing
                    //LoadNotificationList(user_id, token)
                }
            }


        } catch (err) {
            console.log(err);
        }
    };
    // // Update Notification    
    const NotificationsCountUpdate = async (notification_id, count) => {
        try {
            //setloading(true);
            const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
            const Result = await EquipmentAPIs.UpdateUserNotification(
                { "notification_id": notification_id.toString() },
                headers
            );
            if (Result['type'] == "RXSUCCESS") {
                setloading(false);
                // let old_notification = TotalNotifications;
                // if (old_notification > 0) {
                //dispatch(setNotificationCount(old_notification - count))
                //}
                FetchNotificationcount(userId, AuthToken)
            }
            else if (Result["status"] == false && Result["slug"] == "Session Expired") {
                dispatch(setSessionExpire(true));
                setloading(false);
                return false
            }
            else {
                dispatch(setalertMessage(Result['message']));
                dispatch(setalertMessagetype('error'));
                setloading(false);
                return false
            }

        } catch (err) {
            console.log(err);
            setloading(false);
        }
    };

    // Handle pagination click
    const HandlePagination = () => {
        let new_page = Number(Page) + 1;
        setPage(new_page)
        FetchNotifications(userId, new_page)
    }

    const HandleNotifications = async (obj = null) => {
        setNotificationType(obj.type)
        setChatData(null);
        if (obj.notification_status > 0) {
            NotificationsCountUpdate(obj.notification_id, obj.notification_status)
        }

        console.log('obj', obj);

        setDisplayMsg(null)

        if (obj.type == 'chat') {
            let chat_obj = {
                'equipId': obj.equip_id,
                'senderID': obj.recever_id,
                'reciverID': obj.sender_id,
                'reciverName': obj.sfn,
                'EquipMake': obj.make,
            };
            setChatData(chat_obj);
        }

        if (obj.type == 'order') {
            //  console.log('obj', obj);
            // Changes on 16 Jan 2024
            let fname = obj.sfn ? obj.sfn : '';
            let lname = obj.sln ? obj.sln : '';

            let security_depo = 0;
            let grand_total = 0;
            let protection_fees = 0;
            let transport_fees = 0;
            let admin_fee = 0;
            let refunded_protection =
                obj.refunded_protection != undefined ||
                    obj.refunded_protection != ""
                    ? obj.refunded_protection
                    : "";

            let side = 'owner'
            if (obj.order_by == AuthProfileData['user_id']) { // Renter SIde
                side = 'renter';
                //console.log('comming renter side');
                let devilry = obj.delivery_charges * 2;
                let minimun = obj.minimum_delivery_fees;
                let totalMi = devilry + minimun;
                let _changeInTotal = (totalMi / 100) * 10;

                let returnDepo =
                    obj.returnd_deposit != undefined ||
                        obj.returnd_deposit != null
                        ? obj.returnd_deposit
                        : null;


                security_depo = returnDepo == obj.security_deposit
                    ? 0
                    : returnDepo == null
                        ? obj.security_deposit
                        : obj.security_deposit - returnDepo

                transport_fees = obj.delivery_charges * 2 + obj.minimum_delivery_fees;

                protection_fees = obj.protection_fees_refunded == "1"
                    ? obj.equipment_protection_fees -
                    (refunded_protection == ""
                        ? obj.equipment_protection_fees
                        : refunded_protection)
                    : obj.equipment_protection_fees;

                grand_total = obj.protection_fees_refunded != "1"
                    ? obj.admin_commission != null
                        ? obj.total_price +
                        obj.equipment_protection_fees +
                        _changeInTotal +
                        obj.admin_commission
                        // -
                        // (obj.order_status == "completed"
                        //     ? obj.security_deposit
                        //     : 0)
                        : obj.total_price +
                        obj.equipment_protection_fees
                    : obj.admin_commission != null
                        ? obj.total_price -
                        (obj.order_status == "order_canceled"
                            ? obj.security_deposit
                            : 0) +
                        _changeInTotal +
                        obj.admin_commission +
                        (obj.protection_fees_refunded == "1"
                            ? obj.equipment_protection_fees -
                            (refunded_protection == ""
                                ? obj.equipment_protection_fees
                                : refunded_protection)
                            : 0)
                        : obj.total_price;




                admin_fee = 0;

            } else { // Owner SIde

                // console.log('comming owner side');
                let devilry = obj.delivery_charges * 2;
                let minimun = obj.minimum_delivery_fees;
                let totalMi = devilry + minimun;
                let _changeInTotal = (totalMi / 100) * 10;

                let returnDepo =
                    obj.returnd_deposit != undefined ||
                        obj.returnd_deposit != null
                        ? obj.returnd_deposit
                        : null;

                security_depo = returnDepo == obj.security_deposit
                    ? 0
                    : returnDepo == null
                        ? obj.security_deposit
                        : obj.security_deposit - returnDepo

                transport_fees = obj.delivery_charges * 2 + obj.minimum_delivery_fees;

                let refunded_protection =
                    obj.refunded_protection != undefined ||
                        obj.refunded_protection != ""
                        ? obj.refunded_protection
                        : "";

                protection_fees = 0;

                grand_total = obj.total_price

                admin_fee = obj.admin_commission +
                    (obj.minimum_delivery_fees != undefined &&
                        obj.minimum_delivery_fees != null &&
                        obj.minimum_delivery_fees != "0" &&
                        obj.delivery_address != "" &&
                        parseFloat(
                            obj.admin_transport_commission
                        ));

            }

            setChatData(null);

            setRenterExOrders([]);
            let extend_orders = [];


            // check if key exists
            const hasKey = 'order_extend' in obj;
            if (hasKey) {
                extend_orders = obj.order_extend;
                if (extend_orders.length > 0) {
                    setRenterExOrders(extend_orders);
                }
            }

            //added by pp 0n 25 apr 2024 due to timezone changes
            let timeZone = obj.timezone;
            if(!timeZone) {
                const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
                const timeZoneData = await EquipmentAPIs.getEquipTimeZone({equip_id:obj.equip_id} ,headers);
                if(timeZoneData.type === "RXSUCCESS") {
                    timeZone = timeZoneData.data;
                }
            }
            // end by pp
            setOrderDetailPopData({
                "thumbnail": obj.thumbnail ? obj.thumbnail : Img,
                "id": "Order ID #" + obj.order_id,
                "make": obj.make ? obj.make : 'NA',
                "model": obj.model ? obj.model : '',
                "start_date": obj.start_date,
                "start_time": obj.start_time,
                "end_date": obj.end_date,
                "end_time": obj.end_time,
                // "profile_pic": obj.profile_pic ? obj.profile_pic : Img,
                // "first_name": fname + ' ' + lname,
                "profile_pic": obj.sender_profile_pic == undefined || obj.sender_profile_pic == "" ? obj.profile_pic : obj.sender_profile_pic,
                "first_name": obj.sender_profile_pic == "" ? obj.rfn + ' ' + obj.rln : fname + ' ' + lname,
                "order_status": obj.order_status,
                "order_from": obj.delivery_address == "" ? "Renter" : "Owner",
                "rental_price": obj.price,
                "minimum_delivery_fees": (obj.minimum_delivery_fees),
                "security_deposit_refund_txt": obj.returnd_deposit != undefined ||
                    obj.returnd_deposit != null
                    ? "(Refunded" +
                    "-$" +
                    new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(obj.returnd_deposit)
                    +
                    ")"
                    : "",
                "security_deposit": security_depo,
                "grand_total_txt": obj.order_status == "order_canceled" ? "(Refunded)" : "",
                "grand_total": grand_total,
                "protection_fee": protection_fees,
                "transport_fee": transport_fees,
                "admin_fee": admin_fee,
                "side": side,
                "extend_orders": extend_orders,
                "timeZone": timeZone ? convertTimeZoneShortName(momentTimeZone().tz(timeZone).zoneAbbr()): "UTC"
            });

        }

        if (obj.type == 'equip_block_unblock') {
            setDisplayMsg(obj)
        }
    }

    // chat thread update start by pp on 4 apr 2024
    useEffect(() => {
      // added by pp on 5 apr 2024 to perform localstorgae addition and deletion when swith chat window tab 
      if (ActiveNotification) {
        const activeNotificationData = NotificationResult.find(
          (notification) => notification.notification_id === ActiveNotification
        );
        const lastActiveNotification = JSON.parse(
          localStorage.getItem("lastActiveNotification")
        );

        if (
          !lastActiveNotification ||
          Object.entries(lastActiveNotification).length === 0
        ) {
          if (activeNotificationData && activeNotificationData?.type==="chat") {
            localStorage.setItem(
              "lastActiveNotification",
              JSON.stringify({ ...activeNotificationData })
            );
          }
        } else if (
          lastActiveNotification.notification_id !== ActiveNotification
        ) {
          const updateThread = async () => {
            const threadUpdated = await updateChatThread();
            if (threadUpdated && activeNotificationData?.type==="chat") {
              localStorage.setItem(
                "lastActiveNotification",
                JSON.stringify({ ...activeNotificationData })
              );
            }
          };
          updateThread();
        }
      }
    }, [ActiveNotification,NotificationResult]);

    useEffect(() => {
        //added by pp on 8 apr 2024 due to trigger a change when user leave chat thread or close the browser tab
      window.addEventListener("beforeunload", (e) => {
        updateChatThread();
      });
      return () => {
        updateChatThread();
      };
    }, []);
    
    //end by pp

    useEffect(() => {
        if (NotificationsList != null && NotificationsList.length > 0) {
            let msg = [...NotificationsList]
            let msg_Obj = msg[0];

            let newmessage = {
                'id': '',
                'sender_id': msg_Obj['sender_id'],
                'recever_id': msg_Obj['recever_id'],
                'message': msg_Obj['message'],
                'created_at': msg_Obj['created_at'],
                'equip_id': msg_Obj['equip_id'],
                'rfn': msg_Obj['rfn'],
                'rln': msg_Obj['rln'],
                'sfn': msg_Obj['sfn'],
                'sln': msg_Obj['sln'],
            }

            setNotificationResult(msg)
            setNewMsg(newmessage)
            if (NotificationResult != null && ActiveNotification != null) {
                NotificationsCountUpdate(ActiveNotification, 1)
            }      
            
            // Added on 19 Jan 2024 By MK
            // HandleNotifications(msg[0]);
            // setActiveNotification(msg[0]['notification_id']);

            // Added on 20 Feb 2024 by GD due to upper 2 lines of code and redirect from equip detail to owner chat message not working
            let EquipDetailChat = localStorage.getItem("ChatFromEquipDetail");
            if (!EquipDetailChat) {
                HandleNotifications(msg[0]);
                setActiveNotification(msg[0]['notification_id']);
            }
            
        }
        window.scrollTo(0, 0);

    }, [NotificationsList]);


    useEffect(() => {
        // let EquipDetailChat = localStorage.getItem("ChatFromEquipDetail");
        // by PP 26 mar 2024 added next line and commented upper due to redirection from email
        let EquipDetailChat = localStorage.getItem("ChatFromEquipDetail") || JSON.stringify({...notificationsObj}); 
        console.log("EquipDetailChat",EquipDetailChat);
        let chatObj = '';
        if (OpenChatData && OpenChatData != null || EquipDetailChat !== "{}") {
            let chatObjArr = JSON.parse(EquipDetailChat);
            // console.log("chatObjArr: " , chatObjArr);
            // console.log("AuthProfileData: " , AuthProfileData);
            
            if (chatObjArr != null && chatObjArr) {
                // if added by GD 21 Feb 2024 due to redirection without login from equip details page
                if(!chatObjArr['senderID']){
                    // console.log("in chat senderID: if");
                    chatObjArr['senderID'] = AuthProfileData['user_id'];
                }
                // GD end
                chatObj = chatObjArr
            } else {
                chatObj = OpenChatData
            }

            if (chatObj != '' && chatObj != null) {
                setNotificationType("chat")
                setChatData(chatObj);

                //active notification
                let old_list = [...NotificationsList];
                let new_chat = [];
                let remove_indx = null;
                old_list.length > 0 && old_list.map((notification_obj, indx) => {

                    if (notification_obj['type'] == "chat"
                        && chatObj['equipId'] == notification_obj['equip_id']
                        && chatObj['senderID'] == notification_obj['recever_id']
                        && chatObj['reciverID'] == notification_obj['sender_id']) {
                        new_chat.push(notification_obj);
                        setActiveNotification(notification_obj['notification_id'])
                        remove_indx = indx;

                        if (notification_obj['notification_status'] > 0) {
                            NotificationsCountUpdate(notification_obj['notification_id'], notification_obj['notification_status'])
                        }
                    }
                })
                if (remove_indx != null) {
                    old_list.splice(remove_indx, 1);
                    setNotificationResult([...new_chat, ...old_list])
                }
                setTimeout(() => {
                    localStorage.removeItem("ChatFromEquipDetail");
                }, 5000);

            }
        }
        window.scrollTo(0, 0);
    }, [AuthProfileData]);

    return (
        <>
            {loading && (
                <div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            )}

            <Box className="rentyrd-heading">
                <Typography>Notifications</Typography>

            </Box>
            <Box className="rentyrd-grid notify-grid">
                <Box className="rentyrd-grid-item rentyrd-left notify-left">


                    {NotificationsListCount > 0 ? (
                        <>
                            {NotificationResult.map((obj, indx) => (
                                <>
                                    <Box key={indx} className={ActiveNotification == obj.notification_id ? "rentyrd-notify-card rental-box-active" : "rentyrd-notify-card"}
                                    //    commanted on 16 jan 2024
                                       // onClick={() => {
                                        //     // FetchNotificationcount(userId , AuthToken)
                                        //     HandleNotifications(obj)
                                        //     setActiveNotification(obj.notification_id)
                                        // }}

                                        // Added on 16 Jan 2024
                                        onClick={() => {
                                            HandleNotifications(obj);
                                            setActiveNotification(obj.notification_id);

                                            // Update notification_status property to 0
                                            const updatedNotificationResult = NotificationResult.map((item) => {
                                                if (item.notification_id === obj.notification_id) {
                                                    return { ...item, notification_status: 0 };
                                                }
                                                return item;
                                            });
                                            // Set the updated array in your state or wherever you are storing NotificationResult
                                            setNotificationResult(updatedNotificationResult);
                                        }}
                                    >
                                        <Box className="rentyrd-notify-img">
                                            <img
                                                src={obj['thumbnail']}
                                                alt=""
                                            />
                                        </Box>
                                        <Box className="rentyrd-notify-grid">
                                            <Box className="rentyrd-notify-grid-item">
                                                {obj['type'] == 'chat' &&  obj["message_type"] != 'request' && (
                                                    <Typography className="received-from">
                                                        {userId != obj['send_by'] ? (
                                                            <>
                                                             {/* Added on 18 Jan 2024 MK */}
                                                             {((obj["message"].includes("would like to") || obj["message"].includes("has been Approved") || obj["message"].includes("has been rejected")) && (obj["message_type"] == 'request') ) ? (
                                                                    <>                                                                    
                                                                    </>
                                                                ):(
                                                                    <>
                                                                    Received from  {obj['sfn']}
                                                                    </>
                                                                )}
                                                                {/* Added on 18 Jan 2024 MK */}

                                                                {/* Received from  {obj['sfn']} */}
                                                            </>
                                                        ) : (
                                                            <>
                                                           
                                                                {/* Added on 18 Jan 2024 MK */}
                                                                {((obj["message"].includes("would like to") || obj["message"].includes("has been Approved") || obj["message"].includes("has been rejected")) && (obj["message_type"] == 'request')) ? (
                                                                    <>                                                                    
                                                                    </>
                                                                ):(
                                                                    <>
                                                                    Sent To {obj['sfn']}
                                                                    </>
                                                                )}
                                                                {/* Added on 18 Jan 2024 MK */}

                                                            </>
                                                        )}
                                                    </Typography>
                                                )}
                                                <Typography
                                                    sx={{
                                                        display: '-webkit-box',
                                                        overflow: 'hidden',
                                                        WebkitBoxOrient: 'vertical',
                                                        WebkitLineClamp: 2,
                                                    }}
                                                    // title={obj["message"]
                                                    //     ? obj["message"]
                                                    //     : ""}
                                                    title={obj["message"] == "Security Refunded for null"
                                                    ? "Security refunded"
                                                    :
                                                    userId == obj['send_by'] &&
                                                    //this.message(item).includes("Sent") &&
                                                    (obj["message"].includes("would like to") && (obj["message_type"] == 'request'))
                                                    ? `Your ${ obj["message"].includes("extend")?'extend a':''} rental request has been submitted for approval.`
                                                    : (obj["message"].includes("would like to") && (obj["message_type"] == 'request'))
                                                    ? `You have received ${ obj["message"].includes("extend")?'extend':''} a rental request for ${
                                                        obj["make"]
                                                    } from ${obj["sfn"]}.`
                                                    : obj["message"].includes("has been Approved")
                                                    ? `Your ${ obj["message"].includes("extend")?'extend a':''} rental request for ${
                                                        obj["make"]
                                                    } has been approved by ${obj["sfn"]}.`
                                                    : obj["message"].includes("has been rejected")
                                                    ? `Your ${obj["message"].includes("extend")?'extend a':''} rental request for ${
                                                        obj["make"]
                                                    } has been rejected by ${obj["sfn"]}.`
                                                    : obj["message"]}
                                                >
                                                    {/* Added on 18 Jan 2024 MK */}
                                                    {obj["message"] == "Security Refunded for null"
                                                    ? "Security refunded"
                                                    :
                                                    userId == obj['send_by'] &&
                                                    //this.message(item).includes("Sent") &&
                                                    (obj["message"].includes("would like to") && (obj["message_type"] == 'request'))
                                                    ? `Your ${ obj["message"].includes("extend")?'extend a':''} rental request has been submitted for approval.`
                                                    :(obj["message"].includes("would like to") && (obj["message_type"] == 'request'))
                                                    ? `You have received ${ obj["message"].includes("extend")?'extend':''} a rental request for ${
                                                        obj["make"]
                                                    } from ${obj["sfn"]}.`
                                                    : obj["message"].includes("has been Approved")
                                                    ? `Your ${ obj["message"].includes("extend")?'extend a':''} rental request for ${
                                                        obj["make"]
                                                    } has been approved by ${obj["sfn"]}.`
                                                    : obj["message"].includes("has been rejected")
                                                    ? `Your ${obj["message"].includes("extend")?'extend a':''} rental request for ${
                                                        obj["make"]
                                                    } has been rejected by ${obj["sfn"]}.`
                                                    : obj["message"]}
                                                    {/* Added on 18 Jan 2024 MK */}
                                                    {/* {obj["message"]
                                                        ? obj["message"]
                                                        : ""} */}
                                                </Typography>
                                            </Box>
                                            <Box className="rentyrd-notify-grid-item">
                                                <Typography sx={{ textAlign: "end" }}>
                                                    {moment(obj['created_at']).fromNow()}
                                                </Typography>
                                            </Box>
                                            {obj['notification_status'] > 0 && TotalNotifications > 0 && ActiveNotification != obj.notification_id && (
                                                <Typography className="notify-number">{obj['notification_status']}</Typography>
                                            )}
                                        </Box>
                                    </Box>

                                    {NotificationResult.length != indx + 1 && (
                                        <hr className="gray-line" />
                                    )}
                                </>
                            ))}

                            {NotificationsListCount > 15 && (Page + 1) * 15 < NotificationsListCount && (
                                <Box className="pagination-section">
                                    <Box
                                        className="btn-load-more"
                                        sx={{ color: "#faa61a !important" }}
                                        onClick={HandlePagination}
                                    >
                                        {"See More"}
                                        <img
                                            src={gemArrow}
                                            alt="GoEquipMe"
                                            className="gem-arrow arrow-load-more"
                                        />
                                    </Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography className="empty-rent">
                                <span>You do not have any notification.</span>
                            </Typography>
                        </>
                    )}

                </Box>

                {NotificationType == "chat" && ChatData && (
                    <ChatWindow ChatData={ChatData} NewMsg={NewMsg} MessageAdd={(MessageAdd) => {
                        if (MessageAdd) {
                            console.log("message add: success" , );
                            
                            LoadNotificationList(userId, AuthToken)
                        }
                    }} />
                )}

                {NotificationType == "order" && (
                    <>
                        {OrderDetailPopData.side == 'owner' ? (
                            <OrderDetail OrderObj={OrderDetailPopData} title={'yes'} Success={(data) => {
                                if (data && data == 'success') {
                                    NotificationsCountUpdate(ActiveNotification, 1)
                                }
                            }} />
                        ) : (
                            <OrderDetail OrderObj={OrderDetailPopData} title={'yes'} ExtendedOrders={RenterExOrders} Success={(data) => {
                                if (data) {
                                    //console.log('datas',data);
                                }
                            }} />
                        )}

                    </>
                )}


                {NotificationType == "equip_block_unblock" && DisplayMsg && (
                    <>
                        <Box className="rentyrd-grid-item rentyrd-right">
                            <Box className="rentyrd-left-float">
                                <Box className="equipment-details-heading">
                                    <Typography
                                        sx={{
                                            color: "#fff",
                                            textAlign: "center",
                                            fontSize: "clamp(16px, 3vw , 25px)",
                                            fontFamily: "GothamBold !important",
                                        }}
                                    >
                                        {DisplayMsg.make ? DisplayMsg.make : "NA"}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Box className="rentyrd-card">
                                        <Box className="rentyrd-order">
                                            <Typography className="rentyrd-dates-heading">
                                                {DisplayMsg.message}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}

            </Box>
        </>
    )
}
export default Notification