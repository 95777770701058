import React, { useCallback, useEffect, useState, useRef } from 'react'
import { EquipmentAPIs } from "../../service/api-service";
import { Box, Link, Typography, Backdrop, useMediaQuery, CircularProgress, Grid, Modal, IconButton } from '@mui/material';
import gemArrow from 'assets/images/gem-arrow.png'

import { useDispatch, useSelector } from 'react-redux';
import { setalertMessagetype, setalertMessage } from 'redux/redux-slice';
import { IApiResponseRawData, IStoreValue } from 'utils/types';

import './styles.scss';

import MainLayout from '../../layouts/MainLayout'
import moment from 'moment';
import { useParams } from "react-router-dom";

import EquipmentItem from 'components/equipment-item/EquipmentItem';

import ProfilePic from './../../assets/images/dummy-profile-pic.jpeg';
// import ShareIcon from './../../assets/images/share-ico.png';
import ShareIcon from './../../assets/images/share.png';
import CloseIcon from "@mui/icons-material/Close";

var base_url = window.location.origin;
const base_url_link = base_url + '/Testing-dozers/Landran/Punjab/0/512/603'

const loginstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
}

const ShareRentalYard = () => {

  const matchesDesktop = useMediaQuery('(min-width:769px)')
  const matchesMobileX = useMediaQuery('(max-width:425px)')
  const dispatch = useDispatch()

  const params = useParams();

  const [Total, setTotal] = React.useState(0);
  const [Equips, setEquips] = React.useState([]);
  const [Page, setPage] = React.useState(0);

  const [loading, setloading] = React.useState(false);
  //const [loadmore, setloadmore] = React.useState(false);
  const [OwnerInfo, setOwnerInfo] = React.useState({});
  const [ShareYardPopup, setShareYardPopup] = React.useState(false);
  const [ShareLink, setShareLink] = React.useState('');



  const today = moment().format("YYYY-MM-DD");
  const MyEquip = async (user_id, new_page) => {
    try {
      setloading(true);

      const OfficeTrailerData = await EquipmentAPIs.MyRentalyard(
        {
          page: new_page,
          user_id: user_id,
          list_equip_type:params.type ? params.type : 'rent'
        }
      );
      
      if (OfficeTrailerData['type'] == "RXSUCCESS") {
        let equip = OfficeTrailerData['data'];
        let total = OfficeTrailerData['total'];
        // by GD 11 jan 2024 due to error in share rental yard
        // setOwnerInfo(OfficeTrailerData['UserInfo'])
        // end by
        setOwnerInfo(OfficeTrailerData['user'])
        setShareLink(OfficeTrailerData['share_link'])
        let old_data = Equips;
        let new_data = [...old_data, ...equip];
        setTotal(total);
        setEquips(new_data);
        setloading(false);
        setPage(new_page);
      }
      else {
        dispatch(setalertMessage(OfficeTrailerData['message']));
        dispatch(setalertMessagetype('error'));
        setloading(false);
        return false
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };


  const HandleRentalYardPagination = () => {
    let old_page = Page;
    let new_page = (old_page + 1);
    MyEquip(params.id, new_page)
  }


  const HandleShareYard = () => {
    let share_link = ShareLink;

    navigator.clipboard.writeText(share_link)
    //alert("Copied the text: " + share_link);
    setShareYardPopup(true);
  }


  useEffect(() => {
    // let auth_user = localStorage.getItem('token-info');
    // if (auth_user != 'undefined') {
    //   auth_user = JSON.parse(auth_user);
    //   if (auth_user != null && auth_user) {
    //     MyEquip(params.id, Page)
    //     window.scrollTo(0, 0);
    //   }
    // }

    MyEquip(params.id, Page)
        window.scrollTo(0, 0);



  }, []);

  return (
    <>

      <MainLayout>


        {loading && (
          <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}


        <Box className="rentyrd-main">
          <Box className="rentyrd-inner">

            <Box className='share-rental-yard-top'>
              <Box className='share-rental-yard-topleft'>
                <img
                  src={OwnerInfo['profile_pic'] ? OwnerInfo['profile_pic'] : ProfilePic}
                  alt=""
                />
                <Typography className="share-rental-yard-leftp">
                {OwnerInfo['first_name'] ? OwnerInfo['first_name']+ "'s" : "NA"} Rental Yard
                </Typography>
              </Box>
              <Box className='share-rental-yard-topright' sx={{ cursor: "pointer" }}
                onClick={HandleShareYard}>
                <img src={ShareIcon} alt="" />
                <Typography className="share-rental-yard-rightp">
                  Share rental yard
                </Typography>
              </Box>
            </Box>

           


            <Grid container spacing={4} className="equipment-grid" sx={{ marginTop: '5px', marginBottom: '20px' }}>


              {Total > 0 && Equips.map(equipment => (
                <Grid item xs={matchesDesktop ? 4 : matchesMobileX ? 12 : 12} sm={6} md={6} lg={4} key={equipment.id} sx={{ display: 'flex', justifyContent: 'center' }} className="equipment-grid-item"
                >
                  <EquipmentItem item={equipment} type={'equipDetail'} />
                </Grid>
              ))}

              {/* {loading && (
                <CircularProgress />
              )} */}

            

              {Total == 0 &&
                <Box className='equipments-wrapper no-data' sx={{ textAlign: 'center', marginLeft: '35px !important' }}>
                  No equipment found.
                </Box>
              }


            </Grid>
            <Box className="seemore-box">
            {Total > Equips.length && (
                <Link className="btn-load-more"
                  onClick={HandleRentalYardPagination}
                >
                  {'See More'}
                  <img
                    src={gemArrow}
                    alt='GoEquipMe'
                    className='gem-arrow arrow-load-more'
                  />
                </Link>
              )}
            </Box>
            


          </Box>
        </Box>


        {/* ShareYard Popup */}
        <Modal
          keepMounted
          open={ShareYardPopup}
          onClose={() => setShareYardPopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              padding: "0px",
            }}
          >
            <IconButton
              className="cross-btn"
              onClick={() => setShareYardPopup(false)}
            >
              <CloseIcon />
            </IconButton>


            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box className="login__main-div__form edit-eqp-inner pupup-scroll">
                  <Typography variant="h5" style={{ color: "#faa61a" }}>
                    URL copied to clipboard
                  </Typography>

                  <Box>


                    {/* <FacebookShareButton url={base_url_link}>
                    <FacebookIcon
                      size={32}
                      round
                      style={{ padding: "8px" }}
                    />
                    <FacebookMessengerIcon
                      size={32}
                      round
                      style={{ padding: "8px" }}
                    />
                  </FacebookShareButton>

                  <TwitterShareButton url={base_url_link}>
                    <TwitterIcon size={32} round style={{ padding: "8px" }} />
                  </TwitterShareButton>

                  <TelegramShareButton url={base_url_link}>
                    <TelegramIcon
                      size={32}
                      round
                      style={{ padding: "8px" }}
                    />
                  </TelegramShareButton>

                  <WhatsappShareButton url={base_url_link}>
                    <WhatsappIcon
                      size={32}
                      round
                      style={{ padding: "8px" }}
                    />
                  </WhatsappShareButton>

                  <EmailShareButton url={base_url_link}>
                    <EmailIcon size={32} round style={{ padding: "8px" }} />
                  </EmailShareButton> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>


      </MainLayout>


    </>
  );
}
export default ShareRentalYard